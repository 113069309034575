import '../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DetalleCuotas from './subcomponents/DetalleCuotas';
import DetallePagosPorVenta from './subcomponents/DetallePagosPorVenta';
import PopupImpresion from './popups/PopupImpresion';
import ImpresionComponent from './pdfs/impresionComponent';
import PDFContainer from './pdfs/PDFContainer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateDate, validateInput, validateInputNum } from '../helpers/validators';
import { apiCall,getHeaders } from '../helpers/apiCallConfiguration';
import { redondearA100 } from '../helpers/calculos';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useForm } from 'react-hook-form';

import es from 'date-fns/locale/es';
registerLocale('es', es)

library.add(faPrint); // Agrega los iconos que deseas utilizar

function Presupuestos(props) {
    
    const { register, handleSubmit } = useForm();
    //estados para presupuesto
    let [itemParaPresupuesto, setItemParaPresupuesto] = useState(null);
    let [productosParaPresupuesto, setProductosParaPresupuesto] = useState(null);
    let [cantidadesParaPresupuesto, setCantidadesParaPresupuesto] = useState(null);
    let [planesParaPresupuesto, setPlanesParaPresupuesto] = useState(null);
    let [presupuestoOK, setPresupuestoOK] = useState(false)


    let [productos, setProductos] = useState([]);
    let [planes, setPlanes] = useState([]);
    //campos para venta de baterias
    let [porcentajeBateriaUsada, setPorcentajeBateriaUsada] = useState(0);
    let [esVentaBateria, setEsVentaBateria] = useState(props.element?props.element.esVentaBateria:0);
    let [entregaBateria, setEntregaBateria] = useState(props.action=='Registrar'?0:props.element?props.element.entregaBateria:0);

    //indicador de si el plan tiene Pago Iniciar en su modalidad
    let [tienePagoInicial, setTienePagoInicial] = useState(props.action=='Registrar'?0:props.element?props.element.plan.tienePagoInicial:0);

    //campo exclusivo para planes con Pago Inicial
    let [valorCuotaInicial, setValorCuotaInicial] = useState(props.action=='Registrar'?null:props.element&&props.element.plan.tienePagoInicial==1?props.element.valorCuotaInicial:'');

    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):'');
     let [anticipo, setAnticipo] = useState(props.action=='Registrar'?0:props.element?props.element.anticipo:0);
     let [cuotaVencida, setCuotaVencida] = useState(props.action=='Registrar'?0:props.element?props.element.cuotaVencida:0);
    let [porcentajePorCuotaVencida, setPorcentajePorCuotaVencida] = useState(0);
    let [vtoInicial, setVtoInicial] = useState(props.action=="Registrar"?new Date():props.element?new Date(props.element.vtoInicial+'T00:00:00'):'');
    //campos del form q se autocompletan
     let [detalleVenta, setDetalleVenta] = useState(props.action=='Registrar'?[]:props.element?props.element.detalles:[]);

    //campos de cada item a agregar
    let [idProducto, setIDProducto] = useState(null);
    let [cantidad, setCantidad] = useState(1);

    //estados para presupuestos varios planes
    let [selectedPlanes, setSelectedPlanes] = useState([]);
    let [listaPlanes, setListaPlanes] = useState([])


    const updateProductos=(data)=>{
      console.log(data.data);
      setProductos(data.data);
      setIDProducto(data.data[0].idProducto);
    }

    const updatePlanes=(data)=>{
        console.log(data.data);
        setPlanes(data.data)
        let list = [];
        for(let p of data.data){
            let n = {
            value: p.idPlan,
            label: p.descripcion
            }
            list.push(n);
        }
        setListaPlanes(list);
  
    }

    const updatePorcentajeBateriaUsada=(data)=>{
        console.log(data.data);
        setPorcentajeBateriaUsada(data.data.porcentajeBateriaUsada);
  
    }



    const agregarDetalleVenta = async () => {
        if(idProducto && cantidad){
            let prod = productos.find(p=>p.idProducto == idProducto);
            let precio = prod.precioLista;
            //si es venta bateria y no entrega bateria va el recargo
            if(prod.idRubro==1 & entregaBateria==0){
                let recargoPorNoEntregar = precio * porcentajeBateriaUsada / 100;
                precio+=recargoPorNoEntregar
                console.log('recargo: '+recargoPorNoEntregar)
                console.log('porcentaje: '+porcentajeBateriaUsada)
            }
            console.log(prod)
            if(prod){
                let nuevo = {
                    cantidad: cantidad,
                    idProducto: idProducto,
                    producto:{
                        descripcion: prod.descripcion,
                    },
                    costo: prod.costo,
                    precio: precio,
                    subtotal: precio*cantidad
                }
                console.log('agregar producto '+idProducto+'cantidad '+cantidad);
                
                setDetalleVenta([...detalleVenta, nuevo])
                 }
            
            
        }else{
            Swal.fire('Error', 'Por favor seleccione un producto y una cantidad validas', 'warning');
        }
         
    }
    
    useEffect(()=>{
        if(productos.length>0 && idProducto){
            let prod = productos.find(p=>p.idProducto == idProducto);
            let esVB = 0;
            let entB = 0;
            if(prod.idRubro==1){
                esVB = 1;
                entB = 1;
            }else{
                esVB = 0;
                entB = 0;
            }
            setEsVentaBateria(esVB);
            setEntregaBateria(entB);
            /*
            if(planes.length>0 && idPlan){
                let pl = planes.find(p=>p.idPlan == idPlan);
                calculosVenta(precioBase, detalleVenta, pl, anticipo, esVB, entB, porcentajeBateriaUsada, porcentajePorCuotaVencida, cuotaVencida, bonificacionAcumulada);
            }*/
            
        }
        
    }, [idProducto])


      useEffect(()=>{
        console.log(props.element)
        //setear estados de form
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setAnticipo(props.element?props.element.anticipo:0);
        //setEntregaBateria(props.element?props.element.entregaBateria:0);
        //setEsVentaBateria(props.element&&props.element.esVentaBateria==1?1:0);
        setCuotaVencida(props.element?props.element.cuotaVencida:0);
        setVtoInicial(props.element?new Date(props.element.vtoInicial+'T00:00:00'):new Date());
        setPorcentajePorCuotaVencida(0);
        setDetalleVenta(props.element?props.element.detalles:[])
        /*compruebo si es admin y si esta logueado*/
        /*
        if(!localStorage.getItem("user-info")){
           navigate("/login");
       }else*/
           apiCall(process.env.REACT_APP_API_BASE_URL+"/productos/list/",updateProductos);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/planes/list/",updatePlanes);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/config/list/",updatePorcentajeBateriaUsada);
           //ocultar buscador
           //document.getElementById("search-container").style.visibility= 'hidden';

       
        }, [props.element, props.action]);


        useEffect(()=>{
            
            if(fecha && detalleVenta.length>0  && selectedPlanes.length>0){
                setItemParaPresupuesto({
                    fecha:fecha?fecha:new Date(),
                    anticipo:anticipo,
                    vtoInicial:vtoInicial,
                    esVentaBateria:esVentaBateria,
                    entregaBateria:entregaBateria,
                    porcentajePorCuotaVencida:porcentajePorCuotaVencida,
                    
                })
                let dPresupuesto = [];
                let cPresupuesto = [];
                detalleVenta.forEach(d=>{
                    let prod = productos.find(p=>p.idProducto == d.idProducto);
                    dPresupuesto.push(prod)
                    cPresupuesto.push(d.cantidad)

                })
                setProductosParaPresupuesto(dPresupuesto)
                setCantidadesParaPresupuesto(cPresupuesto)
                //armo planes
                let ppp = [];
                planes.forEach(plan=>{
                    if(selectedPlanes.find(p => p.value === plan.idPlan)){
                        ppp.push(plan);
                    }
                })
                setPlanesParaPresupuesto(ppp);
            }

        }, [fecha, detalleVenta, anticipo, vtoInicial, selectedPlanes, esVentaBateria, entregaBateria, porcentajePorCuotaVencida])

        useEffect(()=>{
            
            if(itemParaPresupuesto&&productosParaPresupuesto.length>0&&selectedPlanes.length>0){
                setPresupuestoOK(true)
            }else{
                setPresupuestoOK(false)
            }
                

        }, [itemParaPresupuesto])



        

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
     <div className="container my-5 margin-mobile-5vw">
        <h1 className="general-subtitle text-center my-4">Obtener presupuesto</h1>
        <div className="col-12 m-auto border rounded border-dark-blue p-4">
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
                <div className="d-flex justify-content-between gap-3">
                    <div className="w-50">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Fecha</label>
                            <DatePicker className="form-control" name="fecha"
                                selected={fecha} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>{setFecha(d);validateDate(d,'fecha','invalid-feedback-datepicker')}}
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                locale="es"
                            />
                            <div className="invalid-feedback-datepicker">
                                Por favor ingrese la fecha.
                            </div>
                        </div>
                    </div>
                    <div className="w-50">
                        {props.action!="Registrar"?
                            <div className="d-flex py-2">
                                <label className="label">Id Venta</label>
                                <input className="form-control" name="idVenta" value={props.element?props.element.idVenta:''} disabled></input>
                            </div>
                        :''}
                    </div>
                </div>
                <div className="p-2 border rounded">
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Plan</label>  
                        <CreatableSelect
                        {...register('tags')}
                        isMulti
                        options={listaPlanes}
                        placeholder="Selecciona los planes"
                        defaultValue={[]}
                        disabled={props.action=="Ver detalle"?true:false}
                        onChange={(selectedOptions) => {
                            // Manejar el cambio de opciones seleccionadas aquí si es necesario
                            setSelectedPlanes(selectedOptions);
                            console.log(selectedOptions);
                        }}
                        />

                        
                    </div> 
                    <div className="d-flex justify-content-between align-items-center gap-3">
                        <div className="form-check form-switch pt-3 w-25">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCuotaVencida" defaultChecked={props.action=='Registrar'&&cuotaVencida==1?true:props.element&&props.element.cuotaVencida==1?true:false} onInput={(e) => setCuotaVencida(c=>c==1?0:1)} disabled={props.action!='Registrar'?true:false}/>
                            <label className="form-check-label" htmlFor="flexSwitchCuotaVencida">
                            Cuota vencida
                            </label>
                        </div>
                        {cuotaVencida==1?
                        <div className="d-flex py-2">
                            <label className="label">Fecha de vencimiento primer cuota:</label>
                            <DatePicker className="form-control"
                                selected={vtoInicial} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>setVtoInicial(d)}
                                locale="es"
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                            />
                        </div>:''}
                        {cuotaVencida==1&&props.action=="Registrar"?
                        <div className="d-flex py-2">
                            <label className="label">Porcentaje adicional:</label>
                            <div className="input-group">
                                <input className="form-control" name="porcentajePorCuotaVencida" defaultValue={porcentajePorCuotaVencida} onInput={(e) =>setPorcentajePorCuotaVencida(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                                <span class="input-group-text">%</span>
                            </div>
                        </div>:''}
                    </div>
                </div>
                <div className="p-2 border rounded">
                    {props.action=="Registrar"?
                    <div className="d-flex border-bottom justify-content-center gap-3">
                        <div className="d-flex py-2">
                            <label className="label">Agregar item: </label>
                            <select className="form-select" aria-label="Default select example" onInput={(e) => setIDProducto(e.target.value)} defaultValue={productos.length>0?productos[0].idProducto:null}>
                                <option value='' disabled={!props.element}>Seleccionar producto</option>
                                {productos?productos.map((item,i)=>
                                    <option value={item.idProducto} key={'option'+i}>{item.descripcion}</option>
                                ):''}
                            </select>
                        </div>
                        <div className="py-2 d-flex always-flex">
                            <button type="button" className="btn btn-outline-warning" onClick={(e)=>{document.getElementById('cantidad').value=cantidad>1?cantidad-1:1;setCantidad(c=>c>1?c-1:1)}}>-</button>
                            <input className="form-control" name="cantidad" id="cantidad" defaultValue={cantidad} onInput={(e) => setCantidad(e.target.value)}></input>
                            <button type="button" className="btn btn-outline-warning" onClick={(e)=>{document.getElementById('cantidad').value=cantidad+1;setCantidad(c=>c+1)}}>+</button>
                        </div>  
                        <div className="py-2">
                            <button type="button" className="btn btn-outline-success" onClick={(e) => agregarDetalleVenta()}>Agregar</button>
                        </div> 
                    </div>
                    :''}
                    {esVentaBateria==1&&props.action=='Registrar'?
                    <div className="d-flex justify-content-between gap-3">
                        <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" defaultChecked={entregaBateria==1?true:false} onInput={(e) => setEntregaBateria(c=>c==1?0:1)} disabled={props.action!='Registrar'?true:false}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                           Entrega Batería Usada
                        </label>
                        </div>
                    </div>
                    :''}
                    {detalleVenta.length>0?
                    <div className="border rounded mt-3 p-2">
                        <div className="d-flex border-bottom">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">ID producto</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Precio Unitario</th>
                                        <th scope="col">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detalleVenta.map((item,i)=>
                                        <tr key={"tr-"+i}>
                                            <th scope="row" key={"th-"+i}>{item.idProducto}</th>
                                            <td key={"d-"+i}>{item.producto.descripcion}</td>
                                            <td key={"c-"+i}>{item.cantidad}</td>
                                            <td key={"co-"+i}>$ {item.precio.toLocaleString()}</td>
                                            <td key={"s-"+i}>$ {item.subtotal.toLocaleString()}</td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    
                    </div>
                    :''}
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <div className="col-12 col-md-6 border rounded p-2">

                        <div className="d-flex py-2">
                            <label className="label">Anticipo</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="anticipo" defaultValue={props.action=='Registrar'?0:props.element?props.element.anticipo:0} onInput={(e) => setAnticipo(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                            </div>
                        </div>
                    </div>
                </div>

                {presupuestoOK?
                <ImpresionComponent item={itemParaPresupuesto} productos={productosParaPresupuesto} planes={planesParaPresupuesto} cantidades={cantidadesParaPresupuesto} operacion={'Presupuesto Varios Planes'}/>
                :''}
            </div>   
        </div>
        </div>
        </div>
        </>
  );
}

export default Presupuestos;