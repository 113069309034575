import React from 'react';
import PDFComponent from './PDFComponent';
import PDFVentaAdmin from './PDFVentaAdmin';
import PDFVentaClienteActualizada from './PDFVentaClienteActualizada';
import PDFVentaClienteParaCompletar from './PDFVentaClienteParaCompletar';
import PDFPresupuesto from './PDFPresupuesto';
import PDFPresupuestoVariosPlanes from './PDFPresupuestoVariosPlanes';
import PDFReporteMensual from './PDFReporteMensual';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrint, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


library.add(faPrint, faSpinner); // Agrega los iconos que deseas utilizar

const PDFContainer = (props) => (

  <>
  {props.pdfType=='presupuestoVariosPlanes'?
    <PDFDownloadLink document={<PDFPresupuestoVariosPlanes item={props.item} productos={props.productos} planes={props.planes} cantidades={props.cantidades}/>} fileName={"presupuesto.pdf"}>
    {({ blob, url, loading, error }) =>
    loading ? 'Generando PDF...' : 'Descargar PDF'
    }
    </PDFDownloadLink> 

  :props.pdfType=='balance-mensual'?
  
  <PDFDownloadLink document={<PDFReporteMensual title={props.title} subtitle={props.subtitle} report={props.report}  totalCobros={props.totalCobros} totalesVentas = {props.totalesVentas} totalesCaja = {props.totalesCaja}/>} fileName={props.title+props.subtitle+".pdf"}>
                {({ blob, url, loading, error }) =>
                loading ? 'Generando PDF...' : 'Descargar PDF'
                }
            </PDFDownloadLink>:

  props.pdfType=='balance-cobros'?
  
  <PDFDownloadLink document={<PDFComponent title={props.title} subtitle={props.subtitle} encabezados={props.encabezados} list={props.list}  footer={props.footer}/>} fileName={props.title+props.subtitle+".pdf"}>
                {({ blob, url, loading, error }) =>
                loading ? 'Generando PDF...' : 'Descargar PDF'
                }
            </PDFDownloadLink>:
  props.pdfType == 'ventaClienteParaCompletar' ?
  <PDFDownloadLink document={<PDFVentaClienteParaCompletar item={props.item} title="Comprobante de venta"/>} fileName={"venta-"+props.item.idVenta+"para-completar-cliente.pdf"}>
                {({ blob, url, loading, error }) =>
                loading ? <FontAwesomeIcon icon={faSpinner} /> : 'Descargar comprobante para cliente (con campos vacios)'
                }
            </PDFDownloadLink>:
  props.pdfType == 'ventaClienteActualizada' ?
  <PDFDownloadLink document={<PDFVentaClienteActualizada item={props.item} title="Comprobante de venta"/>} fileName={"venta-"+props.item.idVenta+"comprobante-actualizado-cliente.pdf"}>
                {({ blob, url, loading, error }) =>
                loading ? <FontAwesomeIcon icon={faSpinner} /> : 'Descargar comprobante actualizado para cliente'
                }
            </PDFDownloadLink>:
  props.pdfType == 'ventaAdmin' ?
  <PDFDownloadLink document={<PDFVentaAdmin item={props.item} title="Comprobante de venta"/>} fileName={"venta"+props.item.idVenta+"-comprobante-admin.pdf"}>
                {({ blob, url, loading, error }) =>
                loading ? <FontAwesomeIcon icon={faSpinner} /> : 'Descargar comprobante admin'
                }
            </PDFDownloadLink>:
  props.pdfType == 'presupuesto' && props.item && props.productos.length>0  && props.plan ?
  <PDFDownloadLink document={<PDFPresupuesto item={props.item} productos={props.productos} cantidades={props.cantidades} plan={props.plan} cliente={props.cliente} title="Presupuesto"/>} fileName={props.cliente?"presupuesto-"+props.cliente.nombre.replace(/\s+/g, '-')+"-"+props.plan.descripcion.replace(/\s+/g, '-')+"-"+props.productos[0].descripcion.replace(/\s+/g, '-')+".pdf":"presupuesto-"+props.plan.descripcion.replace(/\s+/g, '-')+"-"+props.productos[0].descripcion.replace(/\s+/g, '-')+".pdf"}>
                {({ blob, url, loading, error }) =>
                loading ? <FontAwesomeIcon icon={faSpinner} /> : 'Descargar presupuesto'
                }
            </PDFDownloadLink>:
  ''
  }
  </>
);

export default PDFContainer;