import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { getHeaders } from '../../helpers/apiCallConfiguration';
import Swal from 'sweetalert2';


function Popup(props) {

    let [bonificacion, setBonificacion] = useState(props.item?props.item.bonificacionAcumulada:0);
    let [monto, setMonto] = useState(props.item?props.item.bonificacionAcumulada:0)

    const saveChanges=async ()=>{
        //validar
        console.log(props.item);
        let info = {
            item: props.item,
            monto: monto,

          };
        let result = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/clientes/pago-a-vendedor",
            {
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify(info),
            }
            );
        
          
          result = await result.json();
          if(result.status==200){
                Swal.fire('Pago registrado', 'El pago al vendedor ha sido registrado con éxito.', 'success');
                props.setActualizar((val)=>val+1);
                 //ocultar popup
                 document.querySelector('#modalPagoAVendedor .btn-close').click();
          }else{
                Swal.fire('Algo salio mal', result.data.errors[0].msg, 'warning');
            }
          console.log(result);
          
          
    }

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal fade" id="modalPagoAVendedor" tabIndex="-1" aria-labelledby="modalPagoAVendedorLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="modalPagoAVendedorLabel">Pago a vendedor: {props.item?props.item.nombre:''}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="p-3">
                    <div className="d-flex py-2">
                        <label className="label">Bonificación acumulada</label>
                        <input className="form-control" name="bonificacion" value={props.item?props.item.bonificacionAcumulada:0} disabled></input>
                    </div>
                    <div className="d-flex py-2">
                        <label className="label">Monto a pagar</label>
                        <input className="form-control" name="monto" defaultValue={props.item?props.item.bonificacionAcumulada:0} onInput={(e)=>setMonto(e.target.value)}></input>
                    </div>

                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    {props.action!="Ver detalle"?
                    <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
                    :''}
                </div>
            </div>
            
        </div>
        </div>
      </>
  );
}

export default Popup;