import '../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React from 'react';
import {Link} from 'react-router-dom';
import Calendario from './subcomponents/Calendario';


function Agenda() {

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
      <div className="container my-5 margin-mobile-5vw">
        <h1 className="general-subtitle text-center my-4">Agenda</h1>
        <div className="col-12 m-auto border rounded border-dark-blue p-4">
            <div className="col-12 col-sm-6 col-lg-3">
              <a className="btn rounded-pill btn-dark col-12" href="/eventos">Ir a eventos</a>
            </div>
            
            <Calendario/>
        </div>
      </div>  
    </>
  );
}

export default Agenda;