import '../../stylesheets/App.css';
//import '../../stylesheets/PopupRegister.css';
import React from 'react';
import { useNavigate } from "react-router-dom";


function DetalleCuotas(props) {

    return (
        <>
            <div className="accordion mt-3" id="accordionFlushCuotas">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCuotas" aria-expanded="false" aria-controls="flush-collapseCuotas">
                        Ver cuotas
                    </button>
                    </h2>
                    <div id="flush-collapseCuotas" className="accordion-collapse collapse" data-bs-parent="#accordionFlushCuotas">
                    <div className="accordion-body">
                    <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Vencimiento</th>
                            <th scope="col">Monto</th>
                            <th scope="col">Interés</th>
                            <th scope="col">Total a pagar</th>
                            <th scope="col">Pagado</th>
                            <th scope="col">Saldo</th>

                            </tr>
                        </thead>
                        <tbody>
                            {props.venta.cuotas?props.venta.cuotas.map((item,i)=>
                            <tr key={'tr-'+i}>
                            <th scope="row"  key={'d-'+i}>{item.descripcion}</th>
                            <td  key={'e-'+i}>{item.estado}</td>
                            <td  key={'v-'+i}>{new Date(item.vencimiento+'T00:00:00').toLocaleDateString()}</td>
                            <td  key={'m-'+i}>${item.monto.toLocaleString()}</td>
                            <td  key={'i-'+i}>${item.interes.toLocaleString()}</td>
                            <td  key={'t-'+i}>${item.totalAPagar.toLocaleString()}</td>
                            <td  key={'p-'+i}>${item.pagado.toLocaleString()}</td>
                            <td  key={'s-'+i}>${item.saldo.toLocaleString()}</td>
                            </tr>
                            ):''}
                        </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DetalleCuotas;