import React, { useRef, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
//import '@fullcalendar/core/main.css';
//import '@fullcalendar/daygrid/main.css';
import PopupAgenda from '../popups/PopupAgenda';
import { apiCallWithCalendar } from '../../helpers/apiCallConfiguration';

function Calendario() {
  const calendarRef = useRef(null);
  const hiddenButtonRef = useRef(null);
  let [ventas, setVentas] = useState([]);
  let [eventos, setEventos] = useState([]);
  let [refinanciamientos, setRefinanciamientos] = useState([]);
  let [mostrarPopupEvento, setMostrarPopupEvento] = useState(false);
  let [actualizar, setActualizar] = useState(0);
  let [selectedElement, setSelectedElement] = useState(null);
  let [selectedObject, setSelectedObject] = useState(null);

  
  const updateVentas=(data, calendar)=>{
      console.log(data.data);
      data.data.forEach(v=>{
          v.cuotas.sort((a, b) => a.idCuota - b.idCuota);
      })
      setVentas(data.data);
      for(let venta of data.data){
        for(let cuota of venta.cuotas){
          let fechaActual = new Date;
          let fechaVencimiento = new Date(cuota.vencimiento);
          let backgroundColor = '';
          // Compara la fecha de vencimiento con la fecha actual
          if (fechaVencimiento < fechaActual) {
            backgroundColor = 'red'; // Si la cuota está vencida, establece el color de fondo en rojo
          } else {
            backgroundColor = 'green'; // Si no está vencida, establece otro color (aquí puedes definir otros colores según lo necesites)
          }
          if(cuota.estado!='PAGADA')
            calendar.addEvent({ title: cuota.descripcion + " - Venta n: "+ venta.idVenta+" - Producto: "+venta.producto.descripcion +" - Cliente: "+venta.cliente.nombre, start: cuota.vencimiento, id:'venta-'+venta.idVenta+'-cuota-'+cuota.idCuota, backgroundColor: backgroundColor});
        }
    }

  }

  const updateRefinanciamientos=(data, calendar)=>{
    console.log(data.data);
    data.data.forEach(v=>{
        v.cuotas.sort((a, b) => a.idRefCuota - b.idRefCuota);
    })
    setRefinanciamientos(data.data);
    for(let refinanciamiento of data.data){
      for(let cuota of refinanciamiento.cuotas){
        let fechaActual = new Date;
        let fechaVencimiento = new Date(cuota.vencimiento);
        let backgroundColor = '';
        // Compara la fecha de vencimiento con la fecha actual
        if (fechaVencimiento < fechaActual) {
          backgroundColor = 'red'; // Si la cuota está vencida, establece el color de fondo en rojo
        } else {
          backgroundColor = 'green'; // Si no está vencida, establece otro color (aquí puedes definir otros colores según lo necesites)
        }
        if(cuota.estado!='PAGADA')
          calendar.addEvent({ title: cuota.descripcion + " - Refinanciamiento : "+ refinanciamiento.descripcion+" - ID: "+refinanciamiento.idRefinanciamiento, start: cuota.vencimiento, id:'ref-'+refinanciamiento.idRefinanciamiento+'-cuota-'+cuota.idRefCuota, backgroundColor: backgroundColor});
      }
  }

}

  const updateEventos=(data, calendar)=>{
    console.log(data.data);
    setEventos(data.data);
    for(let evento of data.data){
        // Combinar fecha y hora en un solo objeto Date
        const fechaHoraCompleta = new Date(`${evento.fecha}T${evento.hora}`);
        // Formatear la fecha y hora para que sea compatible con FullCalendar
        const fechaHoraFormateada = fechaHoraCompleta.toISOString();
        let backgroundColor = '';
        if(evento.idUsuario==JSON.parse(localStorage.getItem("user-info")).idUsuario){
          backgroundColor='purple';
        }else{
          backgroundColor='blue';
        }
        calendar.addEvent({ title: evento.descripcion + " - Tipo: "+ evento.tipo.descripcion+" - Usuario: "+evento.usuario.nombre, start: fechaHoraFormateada, id:'evento-'+evento.idEvento, backgroundColor:backgroundColor});
  }

}

  useEffect(() => {
    if (calendarRef.current) {
      const calendar = calendarRef.current.getApi();

      // Eliminar todos los eventos actuales del calendario
      calendar.removeAllEvents();

      
      apiCallWithCalendar(process.env.REACT_APP_API_BASE_URL+"/ventas/list",updateVentas, calendar);
      apiCallWithCalendar(process.env.REACT_APP_API_BASE_URL+"/eventos/list",updateEventos, calendar);
      apiCallWithCalendar(process.env.REACT_APP_API_BASE_URL+"/refinanciamientos/list",updateRefinanciamientos, calendar);
      // Agregar más eventos si es necesario
    }
  }, [actualizar]);


  const handleDateClick = (arg) => { // bind with an arrow function
    const calendar = calendarRef.current.getApi();
    calendar.gotoDate(arg.dateStr);
    calendar.changeView('timeGridDay');
    
  }

  const handleEventClick = (info) => {
    // Aquí puedes acceder a la información del evento
    console.log('Evento clickeado:', info.event);
    console.log(info.event.id);
    let parametros = info.event.id.split("-");
    if(parametros[0]=='venta'){
      console.log('es venta'+parametros[1]+'cuota'+parametros[3]);
      setSelectedElement(ventas.find(v=>v.idVenta==parametros[1]));
      console.log(ventas.find(v=>v.idVenta==parametros[1]))
      setSelectedObject('ventas');
      setMostrarPopupEvento(true);
    }else if(parametros[0]=='evento'){
      console.log('es evento'+parametros[1]);
      setSelectedElement(eventos.find(e=>e.idEvento==parametros[1]));
      console.log(eventos.find(e=>e.idEvento==parametros[1]))
      setSelectedObject('eventos');
      setMostrarPopupEvento(true);
    }else{
      console.log('es ref'+parametros[1]);
      setSelectedElement(refinanciamientos.find(r=>r.idRefinanciamiento==parametros[1]));
      console.log(refinanciamientos.find(r=>r.idRefinanciamiento==parametros[1]))
      setSelectedObject('refinanciamientos');
      setMostrarPopupEvento(true);
    }
    hiddenButtonRef.current.click();
    /*estructura del objeto:
    {
    "allDay": true,
    "title": "cuota 2 - Venta n: 26 - Producto: caramelo - Cliente: Rocio",
    "start": "2023-11-30",
    "id": "venta26cuota78"
    }
    */
    //prueba
    /*
    setSelectedElement(ventas[0]);
    setMostrarPopupEvento(true);*/
  }

  return (
    <>
    <button hidden ref={hiddenButtonRef} data-bs-toggle="modal" data-bs-target="#modalPopupAgenda" />
    <div className="container mt-4">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        //themeSystem="bootstrap"
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        headerToolbar={{
            start:'today prev,next',
            center:'title',
            end: 'dayGridMonth timeGridWeek timeGridDay',
            
        }}
        locale='es'
        buttonText={{
            today: 'Hoy',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
          }}
        height='80vh'
        themeSystem='bootstrap5'
        eventTimeFormat={{
            // Este formato elimina la visualización de horas en la vista por día
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            meridiem: false,
          }}
          eventDisplay="block"
          eventClick={handleEventClick}
        //selectable= 'true'
      />
    </div>
    <PopupAgenda object={selectedObject} element={selectedElement} setActualizar={setActualizar}/>
    </>
    
  );
}

export default Calendario;