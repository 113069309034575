import '../../stylesheets/App.css';
import '../../stylesheets/Loader.css';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faSearch); // Agrega los iconos que deseas utilizar


function Search(props) {

    return (
        <>
            <div style={{ position: 'relative' }} className="my-4 col-12 col-md-6">
                <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    color: '#aaa', // Color del ícono
                    }}
                />
                <input
                    className="form-control"
                    type="text"
                    placeholder="Buscar..."
                    style={{
                    paddingLeft: '35px', // Ajusta el padding para que el texto no se superponga al ícono
                    // Otros estilos necesarios
                    }}
                    onInput = {(e)=> props.setKeyword(e.target.value)}
                />
                </div>

        </>
    );
}

export default Search;