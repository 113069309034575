import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { validateDate, validateInput, validateInputNum } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';

function DetalleUsuario(props) {

    let [roles, setRoles] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [nombre, setNombre] = useState(props.action=='Registrar'?'':props.element?props.element.nombre:'');
    let [contrasena, setContrasena] = useState(props.action=='Registrar'?'':props.element?props.element.contrasena:'');
    let [comisionContado, setComisionContado] = useState(props.action=='Registrar'?'':props.element?props.element.comisionContado:'');
    let [comisionFinanciado, setComisionFinanciado] = useState(props.action=='Registrar'?'':props.element?props.element.comisionFinanciado:'');
    let [idRol, setIDRol] = useState(props.action=='Registrar'?'':props.element?props.element.idRol:'');
    

      const updateRoles=(data)=>{
        console.log(data.data);
        setRoles(data.data);

    }


    const saveChanges=async ()=>{
        //validar
        let nombreV = validateInput(nombre, 'nombre')
        let contrasenaV = props.action=="Registrar"?validateInput(contrasena, 'contrasena'):true;
        let rolV = validateInput(idRol, 'rol')
        if(nombreV, contrasenaV, rolV){
          let item = {
            idUsuario: props.element?props.element.idUsuario:null,
            nombre:nombre!=''?nombre:props.element.nombre,
            contrasena:contrasena!=''?contrasena:props.element.contrasena,
            comisionContado:comisionContado!=''?comisionContado:0,
            comisionFinanciado:comisionFinanciado!=''?comisionFinanciado:0,
            idRol:idRol!=''?idRol:props.element.idRol,

          };
        console.log(item);
        let result;
        if(props.action=='Registrar'){
            result = await fetch(
                process.env.REACT_APP_API_BASE_URL + "/usuarios/register",
                {
                  method: "POST",
                  headers: getHeaders(),
                  body: JSON.stringify(item),
                }
              );
        }else{
            
            result = await fetch(
                process.env.REACT_APP_API_BASE_URL + "/usuarios/update",
                {
                  method: "PUT",
                  headers:getHeaders(),
                  body: JSON.stringify(item),
                }
              );
        }
          
          result = await result.json();
          let mensaje = props.action=="Registrar"?"registrado":"modificado";
          if(result.status==200){
                Swal.fire('Usuario '+mensaje, 'El Usuario ha sido '+mensaje+' con éxito.', 'success');
                props.setActualizar((val)=>val+1);
                setComponentKey((prevKey) => prevKey + 1);
                 //ocultar popup
                 document.querySelector('#exampleModal .btn-close').click();
          }else{
                Swal.fire('Algo salio mal', result.data?result.data.errors[0].msg:'Ha habido un error', 'warning');
            }
          console.log(result);
        }
        
          
          
    }

      useEffect(()=>{
        //setear estados de form
        setNombre(props.element?props.element.nombre:'');
        setComisionContado(props.element?props.element.comisionContado:'');
        setContrasena(props.element?props.element.contrasena:'');
        setComisionFinanciado(props.element?props.element.comisionFinanciado:'');
        setIDRol(props.element?props.element.idRol:'');

        /*compruebo si es admin y si esta logueado*/
        /*
        if(!localStorage.getItem("user-info")){
           navigate("/login");
       }else*/
           apiCall(process.env.REACT_APP_API_BASE_URL+"/usuarios/roles/list/",updateRoles);
           //ocultar buscador
           //document.getElementById("search-container").style.visibility= 'hidden';

       
        }, [props.action, props.element]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Usuario</label>
                    <input className="form-control" name="idUsuario" value={props.element?props.element.idUsuario:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Nombre</label>
                    <input className="form-control" name="nombre" defaultValue={props.action=='Registrar'?'':props.element?props.element.nombre:''} onInput={(e) => {setNombre(e.target.value);validateInput(e.target.value, 'nombre')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese un nombre.
                    </div>
                </div>
                {props.action=='Registrar'?
                <div className="d-flex py-2 position-relative">
                    <label className="label">Contraseña</label>
                    <input className="form-control" name="contrasena" defaultValue={props.action=='Registrar'?'':props.element?props.element.contrasena:''} onInput={(e) => {setContrasena(e.target.value);validateInput(e.target.value, 'contrasena')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una contraseña.
                    </div>
                </div>:''}
                <div className="d-flex py-2">
                    <label className="label">Comisión Contado</label>
                    <input className="form-control" name="comisionContado" defaultValue={props.action=='Registrar'?'':props.element?props.element.comisionContado:''} onInput={(e) => setComisionContado(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Comisión Financiado</label>
                    <input className="form-control" name="comisionFinanciado" defaultValue={props.action=='Registrar'?'':props.element?props.element.comisionFinanciado:''} onInput={(e) => setComisionFinanciado(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Rol</label>
                    <select name="rol" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idRol:''} onInput={(e) => {setIDRol(e.target.value);validateInput(e.target.value, 'rol')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar rol</option>
                        {roles?roles.map((item,i)=>
                            <option value={item.idRol} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un rol.
                    </div>
                </div>
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleUsuario;