import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { validateDate, validateInput, validateInputNum, validateInputMoney} from '../../helpers/validators';
import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';

import es from 'date-fns/locale/es';
registerLocale('es', es)

function DetalleGasto(props) {

    const [componentKey, setComponentKey] = useState(0);

    let [categorias, setCategorias] = useState([]);
    //campos del form
    let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');
    let [monto, setMonto] = useState(props.action=='Registrar'?'':props.element?props.element.monto:'');
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
    let [idCategoria, setIdCategoria] = useState(props.action=='Registrar'?'':props.element?props.element.idCategoria:'')


      const updateCategorias=(data)=>{
        console.log(data.data);
        setCategorias(data.data);

    }


    const saveChanges=async ()=>{
        //validar
        let descV = validateInput(descripcion, 'descripcion')
        let montoV = validateInputMoney(monto, 'monto', 'gasto-monto-container')
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        let catV = validateInput(idCategoria, 'categoria')
        if(descV && montoV && fechaV && catV){
          let item = {
              idGasto: props.element?props.element.idGasto:null,
              descripcion:descripcion!=''?descripcion:props.element.descripcion,
              monto:monto!=''?monto:props.element.monto,
              fecha:fecha!=''?fecha:props.element.fecha,
              idCategoria:idCategoria!=''?idCategoria:1,
            };
          console.log(item);
          let result;
          if(props.action=='Registrar'){
              result = await fetch(
                  process.env.REACT_APP_API_BASE_URL + "/gastos/register",
                  {
                    method: "POST",
                    headers: getHeaders(),
                    body: JSON.stringify(item),
                  }
                );
          }else{
              
              result = await fetch(
                  process.env.REACT_APP_API_BASE_URL + "/gastos/update",
                  {
                    method: "PUT",
                    headers: getHeaders(),
                    body: JSON.stringify(item),
                  }
                );
          }
            
            result = await result.json();
            let mensaje = props.action=="Registrar"?"registrado":"modificado";
            if(result.status==200){
                  Swal.fire('Gasto '+mensaje, 'El Gasto ha sido '+mensaje+' con éxito.', 'success');
                  props.setActualizar((val)=>val+1);
                  setComponentKey((prevKey) => prevKey + 1);
                  //ocultar popup
                  document.querySelector('#exampleModal .btn-close').click();
            }else{
                  Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
              }
            console.log(result);
        }else{
         console.log('falta completar campos');
        }
        
         
          
    }

    

      useEffect(()=>{
        apiCall(process.env.REACT_APP_API_BASE_URL+"/gastos/categorias/list/",updateCategorias);
        //setear estados de form
        setDescripcion(props.element?props.element.descripcion:'');
        setMonto(props.element?props.element.monto:'');
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setIdCategoria(props.element?props.element.idCategoria:'');
       

        }, [props.action, props.element]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el Rubro seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Gasto</label>
                    <input className="form-control" name="idGasto" value={props.element?props.element.idGasto:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Fecha</label>
                    <DatePicker className="form-control"
                        name="fecha"
                        selected={fecha} disabled={props.action=="Ver detalle"?true:false}
                        onChange={(d)=>{setFecha(d);validateDate(d,'fecha','invalid-feedback-datepicker')}}
                        locale="es"
                        dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                    />
                    <div className="invalid-feedback-datepicker">
                        Por favor ingrese la fecha.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descripción</label>
                    <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una descripción.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Monto</label>
                    <div className="input-group position-relative" id="gasto-monto-container">
                    <span className="input-group-text">$</span>
                    <input className="form-control" name="monto" defaultValue={props.action=='Registrar'?'':props.element?props.element.monto:''} onInput={(e) => {setMonto(e.target.value);validateInputMoney(e.target.value, 'monto', 'gasto-monto-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un monto.
                    </div>
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Categoría</label>
                    {props.action=="Ver detalle"?
                    <input className="form-control" value={props.element?props.element.categoria.descripcion:''} disabled></input>
                    :<>
                      <select name="categoria" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idCategoria:''} onInput={(e) => {setIdCategoria(e.target.value);validateInput(e.target.value,'categoria')}} disabled={props.action=="Ver detalle"?true:false}>
                          <option value='' disabled={!props.element}>Seleccionar categoria</option>
                          {categorias?categorias.map((item,i)=>
                              <option value={item.idCategoria} key={'option'+i}>{item.descripcion}</option>
                          ):''}
                      </select>
                      <div className="invalid-feedback">
                        Por favor seleccione la categoria.
                      </div>
                    </>
                    
                    }
                    
                </div>
                
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleGasto;