import '../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React from 'react';
import {Link} from 'react-router-dom';


function Error404() {

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="error-container">
          <div className="div-404-flotante">
            <h1 className="error-404">404</h1>
          </div>
          
          <div className="popup-error">
            <div className="orange-div">error</div>
            <div className="white-div">
              <h1>Parece que esta página no existe</h1>
              <Link to={JSON.parse(localStorage.getItem("user-info")).is_admin?"/admin":"/"}><p>Ir a la página principal</p></Link>
            </div>
          </div>
          <img className="error-img" src={process.env.PUBLIC_URL+"/images/stage_characters/Orange_con_bola.png"}/>
        </div>
      </>
  );
}

export default Error404;