import React, {useState,useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    /*flexDirection: 'row',*/
    backgroundColor: '#ffffff',
    padding: 10,
    justifyContent:'flex-start'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  roundedSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius:20,
    paddingLeft:50
  },
  roundedSubsection: {
    marginTop:10,
    marginBottom:10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
    flexWrap: 'no-wrap'
  },
  tableContainer:{
    borderColor: '#000000',
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    borderWidth:1
  },
  sectionFlex: {
    
    padding:5,
    gap:10,
    width: '45%'
  },
  headerContainer: {
    display:'flex',
    flexWrap: 'no-wrap',
    padding:10,
    flexDirection: 'row',
    gap:10,
    borderWidth:1,
    borderColor:'#000000',
    borderRadius:10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  textSubrayado: {
    fontSize: 12,
    textAlign: 'justify',
    textDecoration: 'underline'
  },
  slogan: {
    fontSize: 12,
    fontFamily:'Barlow_Medium',
    textAlign: 'justify',
    fontStyle:'italic'
  },
  text45: {
    fontSize: 12,
    textAlign: 'justify',
    width: '45%'
  },
  image: {
    width:'100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
  },
  lastTableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    padding: 4,
  },
  lastTableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    padding: 4,
  },
  footer: {
    fontSize: 14,
    textAlign: 'right',
    marginTop:'10px'
  },
  section30: {
    width:'30%',
  },
  section70: {
    width:'70%',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    gap:15,
  },
  absText:{
    position:'absolute',
    top:0,
    left:0,
    writingMode: 'vertical-rl',
  }
});




const PDFReporteMensual = ({report, footer, title, subtitle, totalCobros, totalesVentas, totalesCaja}) => {
  //const chartImage = generateChart();
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    /*
    let container = document.getElementById('graph-container');
    htmlToImage.toPng(container).then((data) => setImageSrc(data));*/
  }, []);


  return (
    <Document>
    <Page size="A4" style={styles.page}>
        <View style={styles.headerContainer}>
            <View style={styles.section30}>
                <Image src="/images/logo.jpg" style={styles.image} />
            </View>
            <View style={styles.section70}>
                <Text style={styles.subtitle}>Aurelio Muebles</Text>
                <Text style="styles.slogan">"Financiación Amigable a tu Medida"</Text>
                <Text style={styles.subtitle}>Fecha: {new Date().toLocaleDateString()}</Text>
            </View>
        </View>  
        {/* Resto del contenido */}
        <View style={styles.section}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>
            {subtitle}
            </Text>
        </View>
        <View style={styles.section}>
            <Text style={styles.subtitle}>
            Cobros por semana
            </Text>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Semana</Text>
            <Text style={styles.tableCell}>Cobrado</Text>
            </View>
            {/* Puedes repetir esta estructura para cada fila de la tabla */}
            
            {report&&report.cobrosPorSemana?Object.keys(report.cobrosPorSemana).map(semana =>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{new Date(report.cobrosPorSemana[semana].fechaInicio).toLocaleDateString()+" - "+new Date(report.cobrosPorSemana[semana].fechaFin).toLocaleDateString()}</Text>
            <Text style={styles.tableCell}>$ {report.cobrosPorSemana[semana].cobros.toLocaleString()}</Text>
            </View>)
                                :
                                ''}
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Totales</Text>
            <Text style={styles.tableCell}>$ {totalCobros.toLocaleString()}</Text>
            </View>
        </View>
        <View style={styles.section}>
            <Text style={styles.subtitle}>
            Ventas por semana
            </Text>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Semana</Text>
            <Text style={styles.tableCell}>Venta</Text>
            <Text style={styles.tableCell}>Costo</Text>
            <Text style={styles.tableCell}>Ganancia</Text>
            </View>
            {/* Puedes repetir esta estructura para cada fila de la tabla */}
            
            {report&&report.ventasPorSemana?Object.keys(report.ventasPorSemana).map(semana =>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{new Date(report.ventasPorSemana[semana].fechaInicio).toLocaleDateString()+" - "+new Date(report.ventasPorSemana[semana].fechaFin).toLocaleDateString()}</Text>
            <Text style={styles.tableCell}>$ {report.ventasPorSemana[semana].venta.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {report.ventasPorSemana[semana].costo.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {(report.ventasPorSemana[semana].venta-report.ventasPorSemana[semana].costo).toLocaleString()}</Text>
            </View>)
                                :
                                ''}
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Totales</Text>
            <Text style={styles.tableCell}>$ {totalesVentas.venta.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {totalesVentas.costo.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {totalesVentas.ganancia.toLocaleString()}</Text>
            </View>
        </View>
        <View style={styles.section}>
            <Text style={styles.subtitle}>
            Informe de caja
            </Text>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Semana</Text>
            <Text style={styles.tableCell}>Ingresos</Text>
            <Text style={styles.tableCell}>Inversion</Text>
            <Text style={styles.tableCell}>Sueldos</Text>
            <Text style={styles.tableCell}>Costos Negocio</Text>
            <Text style={styles.tableCell}>Gastos Extra</Text>
            <Text style={styles.tableCell}>Resultado</Text>
            </View>
            {/* Puedes repetir esta estructura para cada fila de la tabla */}
            
            {report&&report.informeDeCaja?Object.keys(report.informeDeCaja).map(semana =>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{new Date(report.informeDeCaja[semana].fechaInicio).toLocaleDateString()+" - "+new Date(report.informeDeCaja[semana].fechaFin).toLocaleDateString()}</Text>
            <Text style={styles.tableCell}>$ {report.informeDeCaja[semana].ingresos.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {report.informeDeCaja[semana].inversion.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {report.informeDeCaja[semana].sueldos.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {report.informeDeCaja[semana].costosNegocio.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {report.informeDeCaja[semana].gastosExtra.toLocaleString()}</Text>
            <Text style={styles.tableCell}>$ {report.informeDeCaja[semana].resultado.toLocaleString()}</Text>
            </View>)
                                :
                                ''}
            <View style={styles.tableRow}>
                <Text style={styles.tableCell}>Totales</Text>
                <Text style={styles.tableCell}>$ {totalesCaja.ingresos.toLocaleString()}</Text>
                <Text style={styles.tableCell}>$ {totalesCaja.inversion.toLocaleString()}</Text>
                <Text style={styles.tableCell}>$ {totalesCaja.sueldos.toLocaleString()}</Text>
                <Text style={styles.tableCell}>$ {totalesCaja.costosNegocio.toLocaleString()}</Text>
                <Text style={styles.tableCell}>$ {totalesCaja.gastosExtra.toLocaleString()}</Text>
                <Text style={styles.tableCell}>$ {totalesCaja.resultado.toLocaleString()}</Text>
            </View>
        </View>
      {/*
      <View style={styles.canvasSection}>
        <Image src={imageSrc}/>
      </View>
        */}
    </Page>
  </Document>

  );
};



export default PDFReporteMensual;