import React, { useEffect, useState}from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';


// Register font
Font.register({ family: 'Barlow_Medium', src: '/fonts/Barlow-Medium.ttf' });
// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    /*flexDirection: 'row',*/
    backgroundColor: '#ffffff',
    padding: 10,
    justifyContent:'flex-start'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  roundedSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius:20,
    paddingLeft:50
  },
  roundedSubsection: {
    marginTop:10,
    marginBottom:10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
    flexWrap: 'no-wrap'
  },
  tableContainer:{
    borderColor: '#000000',
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    borderWidth:1
  },
  sectionFlex: {
    
    padding:5,
    gap:10,
    width: '45%'
  },
  headerContainer: {
    display:'flex',
    flexWrap: 'no-wrap',
    padding:10,
    flexDirection: 'row',
    gap:10,
    borderWidth:1,
    borderColor:'#000000',
    borderRadius:10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  textSubrayado: {
    fontSize: 12,
    textAlign: 'justify',
    textDecoration: 'underline'
  },
  slogan: {
    fontSize: 12,
    fontFamily:'Barlow_Medium',
    textAlign: 'justify',
    fontStyle:'italic'
  },
  text45: {
    fontSize: 12,
    textAlign: 'justify',
    width: '45%'
  },
  image: {
    width:'100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
  },
  lastTableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    padding: 4,
  },
  lastTableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    padding: 4,
  },
  footer: {
    fontSize: 14,
    textAlign: 'right',
    marginTop:'10px'
  },
  section30: {
    width:'30%',
  },
  section70: {
    width:'70%',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    gap:15,
  },
  absText:{
    position:'absolute',
    top:0,
    left:0,
    writingMode: 'vertical-rl',
  }
});

const PDFPresupuesto = (props) =>{

    let [cuotas,setCuotas] = useState([])
    useEffect(()=>{
        console.log('fecha')
        console.log(props.item.fecha)
        if(props.plan && props.item && props.productos.length>0){
            let nuevasCuotas = [];
            let diasEntreCuotas = props.plan.idModalidad == 1 ? 7: props.plan.idModalidad == 2 ? 15 : 30;
            for(let i=1;i<=props.plan.cantidadCuotas;i++){
                const fechaBase = props.item.vtoInicial?props.item.vtoInicial:props.item.fecha;
                let vencimiento = new Date(fechaBase);
                vencimiento.setDate(fechaBase.getDate() + diasEntreCuotas*(i-1));
                let cuota = {
                    descripcion:'cuota '+i,
                    vencimiento:vencimiento,
                    monto:i==1?props.item.valorCuotaInicial:props.item.valorCuota,
                }
                nuevasCuotas.push(cuota)
            }
            console.log(nuevasCuotas)
            setCuotas(nuevasCuotas)
        }
        
       
        }, [props.item, props.plan, props.productos, props.cliente]);
    
    return(
    <Document>
        <Page size="A4" style={styles.page}>
        <View style={styles.headerContainer}>
            <View style={styles.section30}>
                <Image src="/images/logo.jpg" style={styles.image} />
            </View>
            <View style={styles.section70}>
                <Text style={styles.subtitle}>Aurelio Muebles</Text>
                <Text style="styles.slogan">"Financiación Amigable a tu Medida"</Text>
                <Text style={styles.subtitle}>Fecha: {props.item.fecha.toLocaleDateString()}</Text>
            </View>
            
            {/* Resto del contenido */}
        </View>

        <View style={styles.roundedSection}>
            {props.cliente?
            <View style={styles.sectionFlex}>
            <Text style={styles.textSubrayado}>Datos del cliente</Text>
            <Text style={styles.text}>Nombre: {props.cliente.nombre}</Text>
            <Text style={styles.text}>Dirección: {props.cliente.direccion}</Text>
            <Text style={styles.text}>Localidad: {props.cliente.localidadNombre}</Text>
            <Text style={styles.text}>Teléfono: {props.cliente.telefono}</Text>
            
            </View>:<View></View>}
            <View style={styles.sectionFlex}>
            <Text style={styles.textSubrayado}>Datos del presupuesto</Text>
            <Text style={styles.text}>Plan: {props.plan.descripcion}</Text>
            <Text style={styles.text}>Productos:</Text>
            {props.productos.map((item,i)=>
                <Text style={styles.text}>{props.cantidades[i]} {item.descripcion}</Text>)}
            </View>
        </View>
        <View style={styles.section}>
            {/* Puedes repetir esta estructura para cada fila de la tabla */}
            <Text style={styles.subtitle}>Cuotas:</Text>
            <View style={styles.tableContainer}>
                <View style={styles.tableRow}>
                <Text style={styles.tableCell}>#</Text>
                <Text style={styles.tableCell}>Vencimiento</Text>
                <Text style={styles.lastTableCell}>Monto</Text>
                </View>
                {cuotas.map((cuota,i)=>
                <View style={i==cuotas.length-1?styles.lastTableRow:styles.tableRow}>
                <Text style={styles.tableCell}>{cuota.descripcion}</Text>
                <Text style={styles.tableCell}>{cuota.vencimiento.toLocaleDateString()}</Text>
                <Text style={styles.lastTableCell}>${cuota.monto.toLocaleString()}</Text>
                </View>)}
            </View>
            
        </View>

        </Page>
    </Document>
) }

export default PDFPresupuesto;