import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';


function PrincipalNav(props) {

    let navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("user-info");
        navigate("/login");
      };

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="container d-flex mt-5 justify-content-end border-bottom pb-4 position-relative">
        <a href="/">
            <img src="/images/logo.png" className="header-logo position-absolute pointer"/>
        </a>
        <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Productos</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/productos">Productos</a></li>
                    <li><a className="dropdown-item" href="/rubros">Rubros</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="/margenes">Margenes</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Registros</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/clientes">Clientes</a></li>
                    <li><a className="dropdown-item" href="/proveedores">Proveedores</a></li>
                    <li><a className="dropdown-item" href="/mecanicos">Mecánicos</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Ventas</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/ventas">Ventas</a></li>
                    <li><a className="dropdown-item" href="/pagos">Pagos</a></li>
                    <li><a className="dropdown-item" href="/planes">Planes</a></li>
                    <li><a className="dropdown-item" href="/presupuestos">Presupuestos</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Gastos</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/compras">Compras</a></li>
                    <li><a className="dropdown-item" href="/pagos-a-proveedores">Pagos a proveedores</a></li>
                    <li><a className="dropdown-item" href="/gastos">Gastos</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Refinanciamientos</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/refinanciamientos">Refinanciamientos</a></li>
                    <li><a className="dropdown-item" href="/refpagos">Pagos de refinanciamientos</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Tareas</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/mis-tareas">Mis tareas</a></li>
                    <li><a className="dropdown-item" href="/agenda">Ver calendario</a></li>
                    <li><a className="dropdown-item" href="/eventos">Eventos</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Reportes</a>
                    <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="/balance-de-cobros">Balance de cobros</a></li>
                    <li><a className="dropdown-item" href="/balance-mensual">Balance mensual</a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Cuentas</a>
                    <ul className="dropdown-menu">
                    {props.isAdmin?
                    <li><a className="dropdown-item" href="/usuarios">Usuarios</a></li>
                    :''
                    }
                    {/*
                    <li><a className="dropdown-item" href="/respaldos">Respaldos</a></li>*/}
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="" onClick={logout}>Cerrar sesión</a></li>
                    </ul>
                </li>
            </ul>
            </div>
        </div>
        </nav>
        
    </div>
    </>
  );
}

export default PrincipalNav;







