import '../../stylesheets/App.css';
//import '../../stylesheets/PopupRegister.css';
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Table from './Table';
import Search from './Search';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


library.add(faPlus, faMagnifyingGlass); // Agrega los iconos que deseas utilizar

function Dashboard(props) {

    
    let [action, setAction] = useState('detail');
    let [element, setElement] = useState(0);
    let [keyword, setKeyword] = useState('');
    let [actualizar, setActualizar] = useState(0);
    let navigate = useNavigate();

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    const updateStates = (action, item) => {
        setAction(action);
        setElement(item);
   }

   useEffect(()=>{
        /*compruebo si es admin y si esta logueado*/
        if(!localStorage.getItem("user-info")){
            navigate('/login');
        }
    }, []);

    return (
        <>
            <div className="container my-5">

                <h1 className="general-subtitle text-center my-4">{props.object=='pagosaproveedores'?'pagos a proveedores':props.object=='refpagos'?'pagos de refinanciamientos':props.object}</h1>
                <div className="col-12 m-auto border rounded border-dark-blue p-4">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <a href="" className="btn rounded-pill btn-dark col-12 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>updateStates('Registrar', null)}>
                                <FontAwesomeIcon icon={faPlus} /> Nuevo
                            </a>
                        </div>
                        {props.object=='eventos'?
                        <div className="col-12 col-sm-6 col-lg-3">
                            <a href="" className="btn rounded-pill btn-dark col-12 mb-2" onClick={()=>navigate('/mis-tareas')}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} /> Ver mis tareas
                            </a>
                        </div>:''}
                    </div>
                    {props.object!='margenes'?<Search keyword={keyword} setKeyword={setKeyword}/>:''}
                    <Table object={props.object} action={action} setAction={setAction} element={element} setElement={setElement} keyword={keyword} actualizar={actualizar} setActualizar={setActualizar}/>
                </div>
            </div>
            

        </>
    );
}

export default Dashboard;