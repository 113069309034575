export function redondearA100(numero) {
    // Obtener los dos últimos dígitos del número
    let ultimosDigitos = numero % 100;

    // Redondear a la centena más cercana
    let redondeado = numero - ultimosDigitos;
    
    // Ajustar según los dos últimos dígitos
    if (ultimosDigitos >= 50) {
        redondeado += 100;
    }

    return redondeado;
}