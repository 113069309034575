import '../../stylesheets/App.css';
//import '../../stylesheets/PopupRegister.css';
import { useNavigate } from "react-router-dom";
import React, {useState, useEffect} from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSackDollar, faCoffee, faMagnifyingGlass, faPencil, faTrash, faPrint, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from '../popups/Popup';
import PopupPagoAVendedor from '../popups/PopupPagoAVendedor';
import PDFContainer from '../pdfs/PDFContainer';
import Swal from 'sweetalert2';

import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';


library.add(faSackDollar,faCoffee,faMagnifyingGlass, faPencil, faTrash, faPrint, faChevronLeft, faChevronRight); // Agrega los iconos que deseas utilizar


function Table(props) { //props.object trae el nombre de lo que estamos gestionando, por ej "productos"

    let [list, setList] = useState([]);

    let [completeList, setCompleteList] = useState([]);
    let [paginaActual, setPaginaActual] = useState(1);
    let [cantidadDePaginas, setCantidadDePaginas] = useState(0);
    

    let [loadingList, setLoadingList] = useState(true);
    let [encabezados, setEncabezados] = useState([]);
    let [columns, setColumns] = useState([]);
    //para proveedores
    let [keywords, setKeywords] = useState(false);
    let [dateColumns, setDateColumns] = useState([]);
    let [doubleColumns, setDoubleColumns] = useState([]);
    let [customColumns, setCustomColumns] = useState(false);
    let [booleanColumns, setBooleanColumns] = useState([]);
    let [moneyColumns, setMoneyColumns] = useState([]);
    let [porcentajeColumns, setporcentajeColumns] = useState([]);
    let [listaMunicipios, setListaMunicipios] = useState([]);
    let [listaLocalidades, setListaLocalidades] = useState([]);

    //para clientes q son vendedores
    let [itemCliente, setItemCliente] = useState(null);


   // let [actualizar, setActualizar] = useState(0);

    const itemsPorPagina = 20;


      const updateListaMunicipios=(data)=>{
        console.log(data);
        data.municipios.sort((a, b) =>a.nombre.localeCompare(b.nombre));
        setListaMunicipios(data.municipios);
  
      }
  
      const updateListaLocalidades=(data)=>{
        console.log(data);
        data.localidades.sort((a, b) =>a.nombre.localeCompare(b.nombre));
        setListaLocalidades(data.localidades);
  
      }

      
      const updateList=(data)=>{
          console.log(data.data);
          if(props.object=='ventas'){
            let listaAux = data.data;
            listaAux.forEach(v=>{
                v.cuotas.sort((a, b) => a.idCuota - b.idCuota);
            })
          }
          if(props.object=='refinanciamientos'){
            let listaAux = data.data;
            listaAux.forEach(v=>{
                v.cuotas.sort((a, b) => a.idRefCuota - b.idRefCuota);
            })
          }
          //setList(data.data);
          setLoadingList(false);

          setCompleteList(data.data);
          setPaginaActual(1);
          let res = Math.ceil(data.data.length / itemsPorPagina);
          setCantidadDePaginas(res);
          setList(data.data.filter((item, i)=>i<itemsPorPagina));
          //setColumns(Object.keys(list[0]));
          

          switch(props.object){
            case 'productos':
                setColumns(['idProducto', 'descripcion', 'stock'])
                setMoneyColumns(['costo', 'precioContado', 'precioLista'])
                setDoubleColumns(['proveedor.descripcion', 'rubro.descripcion']);
                setEncabezados(['ID', 'Descripción', 'Stock',  'Costo', 'P. Contado', 'P. Lista',  'Proveedor', 'Rubro', 'Acción']);
                break;
            case 'clientes':
                setColumns(['idCliente', 'nombre', 'telefono', 'direccion', 'municipioNombre', 'localidadNombre'])
                setMoneyColumns(['bonificacionAcumulada'])
                setDoubleColumns(['nivel.descripcion', 'clienteParentesco.nombre']);
                setBooleanColumns(['esMoroso', 'esVendedor']);
                setEncabezados(['ID', 'Nombre', 'Teléfono', 'Dirección', 'Municipio', 'Localidad','Bonif. Acumulada', 'Nivel', 'Cliente parentesco', 'Es moroso', 'es vendedor', 'Acción']);
                break;
            case 'proveedores':
                setColumns(['idProveedor', 'descripcion', 'direccion', 'telefono'])
                setCustomColumns(true)
                setKeywords(true)
                setEncabezados(['ID', 'Descripción', 'Dirección', 'Teléfono', 'Municipio','Localidad','Palabras clave','Acción']);
                break;
            case 'mecanicos':
                setColumns(['idMecanico', 'descripcion'])
                setEncabezados(['ID', 'Descripción', 'Acción']);
                break;
            case 'usuarios':
                setColumns(['idUsuario', 'nombre'])
                setporcentajeColumns(['comisionContado', 'comisionFinanciado']);
                setDoubleColumns(['rol.descripcion']);
                setEncabezados(['ID', 'Nombre', 'Com. Contado', 'Com. Financiado', 'Rol', 'Acción']);
                break;
            case 'ventas':
                setColumns(['idVenta'])
                setDateColumns(['fecha'])
                setMoneyColumns(['precioFinal', 'interesAcumulado', 'montoPagado', 'saldo'])
                setDoubleColumns([/*'productos.descripcion', */'cliente.nombre', 'plan.descripcion']);
                setEncabezados(['Fecha', 'ID', 'PFF', 'Interés acumulado', 'Monto Pagado', 'Saldo', /*'Producto', */'Cliente', 'Plan', 'Interés Automático','Acción']);
                break;
            case 'refinanciamientos': 
                setDateColumns(['fecha'])
                setColumns(['idRefinanciamiento', 'descripcion'])
                setporcentajeColumns(['bonificacion'])
                setMoneyColumns(['deudaOriginal', 'totalAPagar', 'primerPago', 'montoPagado', 'saldo']);
                setDoubleColumns(['cliente.nombre', 'plan.descripcion']);
                setEncabezados(['Fecha', 'ID', 'Descripción', 'Bonificación', 'Deuda Original', 'Total a pagar', 'Primer Pago', 'Monto Pagado', 'Saldo', 'Cliente', 'Plan', 'Interés Automático','Acción']);
                break;
            case 'compras':
                setDateColumns(['fecha']);
                setColumns(['idCompra']);
                setMoneyColumns(['total', 'pagado', 'saldo'])
                setDoubleColumns(['proveedor.descripcion']);
                setEncabezados(['Fecha', 'ID', 'Monto Total', 'Monto Pagado', 'Saldo', 'Proveedor', 'Acción']);
                break;
            case 'pagos':
                setDateColumns(['fecha']);
                setColumns(['idPago', 'descripcion', 'idVenta']);
                setMoneyColumns(['monto'])
                setDoubleColumns(['cliente.nombre']);
                setEncabezados(['Fecha', 'ID',  'Descripción', 'ID Venta', 'Monto','Cliente', 'Acción']);
                break;
            case 'refpagos':
                    setDateColumns(['fecha']);
                    setColumns(['idRefPago', 'idRefinanciamiento']);
                    setMoneyColumns(['monto']);
                    setDoubleColumns(['refinanciamiento.descripcion','cliente.nombre']);
                    setEncabezados(['Fecha', 'ID', 'ID Refinanciamiento', 'Monto', 'Descripción','Cliente', 'Acción']);
                    break;
            case 'pagosaproveedores':
                setDateColumns(['fecha']);
                setColumns(['idPagoAProveedor','idCompra']);
                setMoneyColumns(['monto'])
                setDoubleColumns(['proveedor.descripcion']);
                setEncabezados(['Fecha', 'ID', 'ID Compra', 'Monto', 'Proveedor', 'Acción']);
                break;
            case 'gastos':
                setDateColumns(['fecha']);
                setColumns(['idGasto', 'descripcion'])
                setMoneyColumns(['monto'])
                setDoubleColumns(['categoria.descripcion']);
                setEncabezados(['Fecha', 'ID', 'Descripción','Monto', 'Categoría', 'Acción']);
                break;
            case 'margenes':
                setColumns(['idMargen', ])
                setporcentajeColumns(['porcentaje', 'descuentoContado'])
                setMoneyColumns(['valorMinimo', 'valorMaximo'])
                setEncabezados(['ID', 'Porcentaje','Descuento Contado', 'Costo Mínimo', 'Costo Máximo',  'Acción']);
                break;
            case 'planes':
                setColumns(['idPlan', 'descripcion', 'cantidadCuotas', 'diasDeGracia', 'plazo']);
                setDoubleColumns(['modalidad.descripcion']);
                setporcentajeColumns(['porcentaje'])
                setBooleanColumns(['tienePagoInicial'])
                setEncabezados(['ID', 'Descripción', 'Cant. cuotas', 'Días de gracia', 'Plazo (meses)', 'Interés', 'Modalidad', 'Tiene Pago Inicial?', 'Acción']);
                break;
            case 'rubros':
                setColumns(['idRubro', 'descripcion']);
                setEncabezados(['ID', 'Descripción', 'Acción']);
                break;
            case 'eventos':
                setDateColumns(['fecha']);
                setColumns(['idEvento', 'descripcion', 'hora', 'estadoDeLaTarea', 'horasInvertidas']);
                setDoubleColumns(['tipo.descripcion', 'usuario.nombre']);
                setEncabezados(['Fecha', 'ID', 'Descripción','Hora', 'Estado de la tarea', 'Horas invertidas','Tipo', 'Usuario','Acción']);
                break;
                        
          }
            
  
      }

      useEffect(()=>{
          let max = paginaActual * itemsPorPagina;
          let min =max - itemsPorPagina;
          setList(completeList.filter((item, i)=>i<max&&i>=min));
       
      }, [paginaActual]);


      useEffect(()=>{
        apiCall('https://apis.datos.gob.ar/georef/api/localidades?max=5000',updateListaLocalidades);
        apiCall('https://apis.datos.gob.ar/georef/api/municipios?max=5000',updateListaMunicipios);
       
        }, []);

      useEffect(()=>{
        if(props.keyword==''){
            apiCall(process.env.REACT_APP_API_BASE_URL+"/"+props.object+"/list/",updateList);
        }else{
            apiCall(process.env.REACT_APP_API_BASE_URL+"/"+props.object+"/list/"+props.keyword,updateList);
        }
        
        console.log(list);
       
   }, [props.actualizar, props.keyword]);

   const updateStates = (action, item) => {
        props.setAction(action);
        props.setElement(item);
   }

   const openEditPopup = i => {

   }

   const openDeletePopup = async item => {
    let resultSwal = await Swal.fire({
        title: '¿Seguro que desea eliminar este registro?',
        text: 'Esta acción no se puede deshacer.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
      }) 
      if (resultSwal.isConfirmed) {
          // Coloca aquí la lógica para eliminar el producto
        let result = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/"+props.object+"/deactivate",
            {
              method: "PUT",
              headers: getHeaders(),
              body: JSON.stringify(item),
            }
          );
      
        result = await result.json();
        console.log(result);
        if(result.status==200){
            Swal.fire('Registro eliminado', 'El registro ha sido eliminado con éxito.', 'success');
            props.setActualizar((act)=>act+1);
        }else{
            Swal.fire('Registro no eliminado', 'Ha habido un error al intentar eliminar este registro.', 'warning');
        }
          
        }
   }

   const openDeactivateInteresForVentas = async item => {
    let resultSwal = await Swal.fire({
        title: '¿Seguro que desea desactivar el calculo de intereses automáticos para esta venta?',
        text: 'Si acepta, a partir de este momento no se seguiran sumando intereses en esta transacción.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Desactivar intereses automáticos',
        cancelButtonText: 'Cancelar',
      }) 
      if (resultSwal.isConfirmed) {
          // Coloca aquí la lógica para eliminar el producto
        let result = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/ventas/deactivate-interes",
            {
              method: "PUT",
              headers: getHeaders(),
              body: JSON.stringify(item),
            }
          );
      
        result = await result.json();
        console.log(result);
        if(result.status==200){
            Swal.fire('Intereses desactivados', 'Los intereses de esta transacción ya no se sumaran automáticamente.', 'success');
            props.setActualizar((act)=>act+1);
        }else{
            Swal.fire('Intereses no desactivados', 'Ha habido un error al intentar desactivar los intereses en esta transacción. Intente nuevamente.', 'warning');
        }
          
        }
   }

   
   const openActivateInteresForVentas = async item => {
    let resultSwal = await Swal.fire({
        title: '¿Seguro que desea activar el calculo de intereses automáticos para esta venta?',
        text: 'Si acepta, a partir de este momento se sumaran intereses por cada dia de retraso en los pagos de esta transacción.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Activar intereses automáticos',
        cancelButtonText: 'Cancelar',
      }) 
      if (resultSwal.isConfirmed) {
          // Coloca aquí la lógica para eliminar el producto
        let result = await fetch(
            process.env.REACT_APP_API_BASE_URL + "/ventas/activate-interes",
            {
              method: "PUT",
              headers: getHeaders(),
              body: JSON.stringify(item),
            }
          );
      
        result = await result.json();
        console.log(result);
        if(result.status==200){
            Swal.fire('Intereses automáticos activados', 'A partir de este momento los intereses por demoras en los pagos se sumaran automáticamente', 'success');
            props.setActualizar((act)=>act+1);
        }else{
            Swal.fire('Activacion de intereses automáticos no realizada', 'Ha habido un error al intentar automatizar estos intereses. Intente nuevamente', 'warning');
        }
          
        }
   }

    return (
        <>
            <div className="table-responsive">
            <table className="table mt-5">
                <thead>
                    <tr>
                    {encabezados?encabezados.map((item, i)=>
                        <th className="text-center" scope="col" key={"th-"+i}>{item}</th>
                    ):''} 
                    </tr>
                </thead>
                <tbody>
                    {list?list.map((item,i)=>
                    <tr key={'tr-'+i}>
                        {dateColumns?dateColumns.map((col,j)=>
                        <td className="text-center" key={col+'-'+i}>{new Date(item[col]+'T00:00:00').toLocaleDateString()}</td>
                        ):''}
                        {columns?columns.map((col,j)=>
                        <td className="text-center" key={col+'-'+i}>{item[col]}</td>
                        ):''}
                        {porcentajeColumns?porcentajeColumns.map((col,j)=>
                        <td className="text-center" key={col+'-'+i}>{item[col]} %</td>
                        ):''}
                        {moneyColumns?moneyColumns.map((col,j)=>
                        <td className="text-center" key={col+'-'+i}>{item[col]?"$ "+item[col].toLocaleString(undefined, { maximumFractionDigits: 2 }):''}</td>
                        ):''}
                        {customColumns?
                        (<>
                        <td className="text-center" key={'mun-'+i}>{listaMunicipios.find(municipio => municipio.id == item['municipio'])?listaMunicipios.find(municipio => municipio.id == item['municipio']).nombre:""}</td>
                        <td className="text-center" key={'loc-'+i}>{listaLocalidades.find(localidad => localidad.id == item['localidad'])?listaLocalidades.find(localidad => localidad.id == item['localidad']).nombre:""}</td>
                        </>
                                ):''}
                        {doubleColumns?doubleColumns.map((dob,k)=>
                        item[dob.split(".")[0]]?
                        <td className="text-center" key={dob+'-'+i}>{item[dob.split(".")[0]][dob.split(".")[1]]}</td>:
                        <td className="text-center" key={dob+'-'+i}></td>
                        ):''}
                        {booleanColumns?booleanColumns.map((col,k)=>
                        <td className="text-center" key={col+'-'+i}>{item[col]==1?'Si':'No'}</td>
                        ):''}
                        {keywords  ? (
                                <td key={'keywords-' + i}>
                                    {item.keywords.map(obj => obj.palabra).join(', ')}
                                </td>
                            ) : (
                               ''
                            )
                        }
                        {props.object=='ventas'||props.object=='refinanciamientos'?
                        <td className="text-center">
                            <div className="form-check form-switch">
                                <input readOnly className="form-check-input pointer" type="checkbox" role="switch"  name={"flexSwitchCheckDefault"+item.idVenta} id={"flexSwitchCheckDefault"+item.idVenta} checked={item.interesActivado==0?false:true} onClick={(e) => {e.preventDefault();item.interesActivado==1?openDeactivateInteresForVentas(item):openActivateInteresForVentas(item)}}/>
                            </div>
                        </td>
                        :''}
                        <td className="text-center">
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="pointer me-1" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>updateStates('Ver detalle', item)} />
                            {props.object!='ventas'&&props.object!='pagos'&&props.object!='compras'&&props.object!='refinanciamientos'?<FontAwesomeIcon icon={faPencil}  className="mx-2 pointer" data-bs-toggle="modal" data-bs-target="#exampleModal"onClick={()=>updateStates('Editar', item)}/>:''}
                            <FontAwesomeIcon icon={faTrash}  className="pointer me-1" onClick={()=>openDeletePopup(item)}/>
                            {props.object=='clientes'&&item.esVendedor==1?
                            <FontAwesomeIcon icon={faSackDollar}  className="pointer me-1" data-bs-toggle="modal" data-bs-target="#modalPagoAVendedor"   onClick={()=>setItemCliente(item)}/>:''}
                        </td>
                    </tr>
                    ):''}
                    
                </tbody>
            </table>
            </div>
            {cantidadDePaginas>1?
            <div className="paginado-container d-flex flex-wrap always-flex justify-content-center align-items-center gap-4">
                <a onClick={()=>paginaActual>1?setPaginaActual(a=>a-1):''} className={paginaActual>1?'pointer':'inactive'}><FontAwesomeIcon icon={faChevronLeft} /></a>
                {Array.from({ length: cantidadDePaginas }, (_, i) => (
                    <span
                    key={i + 1}
                    onClick={() => setPaginaActual(i + 1)} // Manejador de evento onClick
                    className={paginaActual === i + 1 ? 'pagina-actual pointer' : 'pointer'}
                    >
                    {i + 1}
                    </span>
                ))}
                <a onClick={()=>paginaActual<Math.ceil(completeList.length/itemsPorPagina)?setPaginaActual(a=>a+1):''} className={paginaActual<Math.ceil(completeList.length/itemsPorPagina)?'pointer':'inactive'}><FontAwesomeIcon icon={faChevronRight} /></a>
            </div>
            :''}
            <Popup object={props.object} action={props.action} element={props.element} setActualizar={props.setActualizar}/>
            <PopupPagoAVendedor item={itemCliente} setActualizar={props.setActualizar}/>
        </>
    );
}

export default Table;



