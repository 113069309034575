import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateDate, validateInput } from '../../helpers/validators';
import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';


library.add(faTrash); // Agrega los iconos que deseas utilizar

function DetalleCliente(props) {

    let [clientes, setClientes] = useState([]);
    let [niveles, setNiveles] = useState([]);
    let [referentes, setReferentes] = useState(props.action!='Registrar'&&props.element?props.element.referentes:[]);
    
    let [listaMunicipios, setListaMunicipios] = useState([]);
    let [listaLocalidades, setListaLocalidades] = useState([]);
    
    let [actualizar, setActualizar] = useState(0);
    let [actualizarReferentes, setActualizarReferentes] = useState(0);

    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [nombre, setNombre] = useState(props.action=='Registrar'?'':props.element?props.element.nombre:'');
    let [telefono, setTelefono] = useState(props.action=='Registrar'?'':props.element?props.element.telefono:'');
    let [direccion, setDireccion] = useState(props.action=='Registrar'?'':props.element?props.element.direccion:'');
    let [municipio, setMunicipio] = useState(props.action=='Registrar'?'':props.element?props.element.municipio:'');
    let [localidad, setLocalidad] = useState(props.action=='Registrar'?'':props.element?props.element.localidad:'');
    let [bonificacionAcumulada, setBonificacionAcumulada] = useState(props.action=='Registrar'?0:props.element?props.element.bonificacionAcumulada:0);
    let [idNivel, setIDNivel] = useState(props.action=='Registrar'?1:props.element?props.element.idNivel:1);
    let [idClienteParentesco, setIDClienteParentesco] = useState(props.action=='Registrar'?'':props.element?props.element.idClienteParentesco:'');
    let [esMoroso, setEsMoroso] = useState(props.action=='Registrar'?0:props.element?props.element.esMoroso:0);
    let [esVendedor, setEsVendedor] = useState(props.action=='Registrar'?0:props.element?props.element.esVendedor:0);
    let [cantidadDeRecomendados, setCantidadDeRecomendados] = useState(props.action=='Registrar'?0:props.element?props.element.cantidadDeRecomendados:0);


    //campos para agregar referente
    let [nombreReferente, setNombreReferente] = useState(null);
    let [apellidoReferente, setApellidoReferente] = useState(null);
    let [direccionReferente, setDireccionReferente] = useState(null);
    let [telefonoReferente, setTelefonoReferente] = useState(null);

    const updateNiveles=(data)=>{
        console.log(data.data);
        setNiveles(data.data);

    }

    const updateClientes=(data)=>{
      console.log(data.data);
      setClientes(data.data);

  }

  const updateReferentes=(data)=>{
    console.log(data.data);
    setReferentes(data.data);

}

  const updateListaMunicipios=(data)=>{
    console.log(data);
    data.municipios.sort((a, b) =>a.nombre.localeCompare(b.nombre));
    setListaMunicipios(data.municipios);

  }

  const updateListaLocalidades=(data)=>{
    console.log(data);
    data.localidades.sort((a, b) =>a.nombre.localeCompare(b.nombre));
    setListaLocalidades(data.localidades);

  }

  const agregarReferente = async () => {
    if(nombreReferente && apellidoReferente){
        let nuevo = {
            nombre: nombreReferente,
            apellido: apellidoReferente,
            direccion: direccionReferente,
            telefono: telefonoReferente,
        }
        setReferentes([...referentes, nuevo])
        setNombreReferente(null);
        setApellidoReferente(null);
        setDireccionReferente(null);
        setTelefonoReferente(null);
        document.getElementById('n-r').value = '';
        document.getElementById('a-r').value = '';
        document.getElementById('d-r').value = '';
        document.getElementById('t-r').value = '';

    }else{
        Swal.fire('Algo salio mal', 'Debe completar al menos los campos de nombre y apellido para agregar un referente.', 'warning');
    }
     
}

const deleteReferente = async (item, i) => {
            setReferentes((prevReferentes) => {
                // Crear un nuevo array excluyendo el elemento con el índice i
                const updatedReferentes = prevReferentes.filter((_, index) => index !== i);
                return updatedReferentes;
              });
   }

    const saveChanges=async ()=>{
        //validar
        let nombreV = validateInput(nombre, 'nombre')
        let direccionV = validateInput(direccion, 'direccion')
        let telefonoV = validateInput(telefono, 'telefono')
        let municipioV = validateInput(municipio, 'municipio')
        let localidadV = validateInput(localidad, 'localidad')
        if(nombreV && direccionV && telefonoV && municipioV && localidadV){
            //obtengo nombre de municipio y localidad seleccionadas
            let municipioNombre = listaMunicipios.find(mun=>mun.id==municipio).nombre;
            let localidadNombre = listaLocalidades.find(loc=>loc.id==localidad).nombre;
            
            let item = {
                idCliente: props.element?props.element.idCliente:null,
                nombre:nombre!=''?nombre:props.element.nombre,
                direccion:direccion,
                telefono:telefono!=''?telefono:props.element.telefono,
                municipio:municipio!=''?municipio:props.element.municipio,
                localidad:localidad!=''?localidad:props.element.localidad,
                bonificacionAcumulada:bonificacionAcumulada,
                idNivel:idNivel!=''?idNivel:1,
                idClienteParentesco:idClienteParentesco?idClienteParentesco:props.element?props.element.idClienteParentesco:null,
                esMoroso:esMoroso!=''?esMoroso:0,
                esVendedor:esVendedor!=''?esVendedor:0,
                cantidadDeRecomendados:cantidadDeRecomendados!=''?cantidadDeRecomendados:0,
                referentes:referentes,
                municipioNombre:municipioNombre,
                localidadNombre:localidadNombre
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/clientes/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }else{
                
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/clientes/update",
                    {
                      method: "PUT",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
              
            result = await result.json();
            let mensaje = props.action=="Registrar"?"registrado":"modificado";
            if(result.status==200){
                Swal.fire('Cliente '+mensaje, 'El cliente ha sido '+mensaje+' con éxito.', 'success');
                props.setActualizar((val)=>val+1);
                setComponentKey((prevKey) => prevKey + 1);
                    //ocultar popup
                    document.querySelector('#exampleModal .btn-close').click();
            }else{
                Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
            }
            console.log(result);
            
              
        }else{
            console.log('hay campos sin completar')
        }
        
          
    }

      useEffect(()=>{

        apiCall('https://apis.datos.gob.ar/georef/api/municipios?provincia=06&campos=id,nombre&max=200',updateListaMunicipios);
        if(props.action!='Registrar'){
            apiCall('https://apis.datos.gob.ar/georef/api/localidades?municipio='+props.element.municipio+'&campos=id,nombre&max=200',updateListaLocalidades);
        }
        //setear estados de form
        setNombre(props.element?props.element.nombre:'');
        setBonificacionAcumulada(props.element?props.element.bonificacionAcumulada:0);
        setTelefono(props.element?props.element.telefono:'');
        setDireccion(props.element?props.element.direccion:'');
        setMunicipio(props.element?props.element.municipio:'');
        setLocalidad(props.element?props.element.localidad:'');
        setIDNivel(props.element?props.element.idNivel:1);
        setIDClienteParentesco(props.element?props.element.idClienteParentesco:'');
        setEsMoroso(props.element?props.element.esMoroso:0);
        setEsVendedor(props.element?props.element.esVendedor:0);
        setCantidadDeRecomendados(props.element?props.element.cantidadDeRecomendados:0);

        apiCall(process.env.REACT_APP_API_BASE_URL+"/clientes/list/",updateClientes);
        apiCall(process.env.REACT_APP_API_BASE_URL+"/clientes/niveles/list/",updateNiveles);

       
        }, [props.action, props.element]);

        useEffect(()=>{
            if(municipio){
                apiCall('https://apis.datos.gob.ar/georef/api/localidades?municipio='+municipio+'&campos=id,nombre&max=200',updateListaLocalidades);
                    
            }
          }, [municipio])

          useEffect(()=>{
                if(props.element){
                    setLocalidad(props.element.localidad)
                    document.getElementsByName('localidad')[0].value=props.element.localidad;
                    validateInput(props.element.localidad,'localidad');
                }else{
                    setLocalidad('')
                    document.getElementsByName('localidad')[0].value='';
                    if(props.action!='Ver detalle'){
                        validateInput('','localidad');
                    }
                }

          }, [listaLocalidades, props.element])

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element, actualizar]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Cliente</label>
                    <input className="form-control" name="idCliente" value={props.element?props.element.idCliente:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Nombre</label>
                    <input className="form-control" name="nombre" defaultValue={props.action=='Registrar'?'':props.element?props.element.nombre:''} onInput={(e) => {setNombre(e.target.value);validateInput(e.target.value,'nombre')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese un nombre.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Teléfono</label>
                    <input className="form-control" name="telefono" defaultValue={props.action=='Registrar'?'':props.element?props.element.telefono:''} onInput={(e) => {setTelefono(e.target.value);validateInput(e.target.value,'telefono')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese un teléfono.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Dirección</label>
                    <input className="form-control" name="direccion" defaultValue={props.action=='Registrar'?'':props.element?props.element.direccion:''} onInput={(e) => {setDireccion(e.target.value);validateInput(e.target.value,'direccion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una dirección.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Municipio</label>
                    <select name='municipio' className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.municipio:''} onInput={(e) => {setMunicipio(e.target.value);validateInput(e.target.value,'municipio')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar municipio</option>
                        {listaMunicipios?listaMunicipios.map((item,i)=>
                            <option value={item.id} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor ingrese un municipio.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Localidad</label>
                    <select name='localidad' className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.localidad:''} onInput={(e) => {setLocalidad(e.target.value);validateInput(e.target.value,'localidad')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar localidad</option>
                        {listaLocalidades?listaLocalidades.map((item,i)=>
                            <option value={item.id} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor ingrese una localidad.
                    </div>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Bonificación Acumulada</label>
                    <input className="form-control" name="bonificacionAcumulada" defaultValue={props.action=='Registrar'?0:props.element?props.element.bonificacionAcumulada:0} onInput={(e) => setBonificacionAcumulada(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Nivel</label>
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?1:props.element?props.element.idNivel:1} onInput={(e) => setIDNivel(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar nivel</option>
                        {niveles?niveles.map((item,i)=>
                            <option value={item.idNivel} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Es Moroso?</label>
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?0:props.element?props.element.esMoroso:0} onInput={(e) => setEsMoroso(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Es moroso?</option>
                        <option value='0'>No</option>
                        <option value='1'>Si</option>
                    </select>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Es Vendedor?</label>
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?0:props.element?props.element.esVendedor:0} onInput={(e) => setEsVendedor(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Es Vendedor?</option>
                        <option value='0'>No</option>
                        <option value='1'>Si</option>
                    </select>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Cantidad de Recomendados</label>
                    <input className="form-control" name="cantidadDeRecomendados" defaultValue={props.action=='Registrar'?0:props.element?props.element.cantidadDeRecomendados:0} onInput={(e) => setCantidadDeRecomendados(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Parentesco</label>
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idClienteParentesco:''} onInput={(e) => setIDClienteParentesco(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar cliente parentesco</option>
                        {clientes?clientes.map((item,i)=>
                            <option value={item.idCliente} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                </div>
                
                {referentes.length>0?
                <div className="border rounded mt-3 p-2">
                <div className="d-flex border-bottom">
                    <label className="label">Referentes</label>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Apellido</th>
                                <th scope="col">Dirección</th>
                                <th scope="col">Teléfono</th>
                                <th scope="col">Accion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referentes.map((item,i)=>
                                <tr key={"tr-"+i}>
                                    <td key={"n-"+i}>{item.nombre}</td>
                                    <td key={"a-"+i}>{item.apellido}</td>
                                    <td key={"d-"+i}>{item.direccion}</td>
                                    <td key={"t-"+i}>{item.telefono}</td>
                                    <td key={"a-"+i}>{props.action!='Ver detalle'?<FontAwesomeIcon icon={faTrash}  className="pointer" onClick={()=>deleteReferente(item, i)}/>:''}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            
            </div>:''}
            {props.action!="Ver detalle"?
            <>
                <div className="d-flex py-2">
                        <label className="label">Agregar referente: </label>
                </div>
                <div className="d-flex border rounded p-2 justify-content-center gap-3">
                    <div className="d-flex py-2">
                        <label className="label">Nombre</label>
                        <input id="n-r" className="form-control" name="nombre" onInput={(e) => setNombreReferente(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                    </div>
                    <div className="d-flex py-2">
                        <label className="label">Apellido</label>
                        <input id="a-r" className="form-control" name="bonificacionAcumulada" onInput={(e) => setApellidoReferente(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                    </div>
                    <div className="d-flex py-2">
                        <label className="label">Dirección</label>
                        <input id="d-r" className="form-control" name="bonificacionAcumulada" onInput={(e) => setDireccionReferente(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                    </div>
                    <div className="d-flex py-2">
                        <label className="label">Teléfono</label>
                        <input id="t-r" className="form-control" name="bonificacionAcumulada" onInput={(e) => setTelefonoReferente(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                    </div>
                    <div className="py-2">
                        <button type="button" className="btn btn-outline-success" onClick={(e) => agregarReferente()}>Agregar</button>
                    </div> 
                </div>
            </>
                
                :''}
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleCliente;