import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import DetalleCliente from './DetalleCliente';
import DetalleProducto from './DetalleProducto';
import DetalleProveedor from './DetalleProveedor';
import DetalleCompra from './DetalleCompra';
import DetalleVenta from './DetalleVenta';
import DetalleMecanico from './DetalleMecanico';
import DetalleUsuario from './DetalleUsuario';
import DetallePlan from './DetallePlan';
import DetallePago from './DetallePago';
import DetallePagoAProveedor from './DetallePagoAProveedor';
import DetalleRefinanciamiento from './DetalleRefinanciamiento';
import DetalleRefPago from './DetalleRefPago';
import DetalleRubro from './DetalleRubro';
import DetalleEvento from './DetalleEvento';
import DetalleGasto from './DetalleGasto';
import DetalleMargen from './DetalleMargen';


function Popup(props) {

    

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">{props.action} {props.object=='pagosaproveedores'?'pagos a proveedores':props.object=='refpagos'?'pagos de refinanciamientos':props.object}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {props.object=='productos'? <DetalleProducto action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='clientes'? <DetalleCliente action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='compras'? <DetalleCompra action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='ventas'? <DetalleVenta action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='usuarios'? <DetalleUsuario action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='mecanicos'? <DetalleMecanico action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='planes'? <DetallePlan action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='rubros'? <DetalleRubro action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='eventos'? <DetalleEvento action={props.action} element={props.element} setActualizar={props.setActualizar} object={props.object}/>:
                props.object=='gastos'? <DetalleGasto action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='margenes'? <DetalleMargen action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='pagos'? <DetallePago action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='pagosaproveedores'? <DetallePagoAProveedor action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='refinanciamientos'? <DetalleRefinanciamiento action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='refpagos'? <DetalleRefPago action={props.action} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='proveedores'?<DetalleProveedor action={props.action} element={props.element} setActualizar={props.setActualizar}/>:''}
            
            </div>
        </div>
        </div>
      </>
  );
}

export default Popup;