import '../stylesheets/App.css';
import '../stylesheets/Home.css';
//import '../stylesheets/Error404.css';
import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import PrincipalNav from './subcomponents/PrincipalNav';
import Dashboard from './subcomponents/Dashboard';

function Home() {

  let navigate = useNavigate();

  

  useEffect(()=>{
    /*compruebo si es admin y si esta logueado*/
      if(localStorage.getItem("user-info")){
        navigate("/");
    }else{
      navigate('/login');
    }
}, []);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
    {/*}
    <Dashboard object="productos"/>*/}
    <div className="home-banner" style={{backgroundImage:
      "url(" + process.env.PUBLIC_URL + "/images/banner.jpg)"}}>
        <div className="home-transparency">
          
        </div>
        <div className="d-flex justify-content-center home-btn-container">
          <a className="always-flex button-home d-flex flex-column justify-content-center align-items-center" href="/ventas">
            Ventas
            <img src="images/ventas.png"/>
          </a>
          <a className="always-flex button-home d-flex flex-column justify-content-center align-items-center" href="/presupuestos">
            Presupuestos
            <img src="images/caja.png"/>
          </a>
          <a className="always-flex button-home d-flex flex-column justify-content-center align-items-center" href="/mis-tareas">
            Tareas
            <img src="images/tareas.png"/>
          </a>
        </div>
        
    </div>
    </>
  );
}

export default Home;












