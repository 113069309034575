import logo from '../logo.svg';
import '../stylesheets/App.css';
import {matchPath, Route, Routes} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import Login from './Login';
import Home from './Home';
import PrincipalNav from './subcomponents/PrincipalNav';
import Error404 from './Error404';
import Dashboard from './subcomponents/Dashboard';
import Agenda from './Agenda';
import Respaldos from './Respaldos';
import BalanceDeCobros from './BalanceDeCobros';
import BalanceMensual from './BalanceMensual';
import MisTareas from './MisTareas';
import Presupuestos from './Presupuestos';

function App() {
  const [isLogued, setIsLogued]=useState(false);
  const [isAdmin, setIsAdmin]=useState(false);
  const [actualizar, setActualizar] = useState(false);
  
 

  useEffect(()=>{
    /*compruebo si es admin y si esta logueado*/
      if(localStorage.getItem("user-info") && !isLogued){
        setIsLogued(true);
        if(JSON.parse(localStorage.getItem("user-info")).idRol==1 && !isAdmin){
          setIsAdmin(true)
        }
    }
}, [actualizar]);
  
  return (
    <>
        {isLogued?<PrincipalNav isAdmin={isAdmin}/>:''}
        <Routes>
          <Route path="/login" exact element={<Login setActualizar={setActualizar}/>}/>
          <Route path="/productos" exact element={<Dashboard object="productos"/>}/>
          <Route path="/clientes" exact element={<Dashboard object="clientes" />}/>
          <Route path="/proveedores" exact element={<Dashboard object="proveedores" />}/>
          <Route path="/mecanicos" exact element={<Dashboard object="mecanicos" />}/>
          {isAdmin?<Route path="/usuarios" exact element={<Dashboard object="usuarios" />}/>:''}
          <Route path="/ventas" exact element={<Dashboard object="ventas" />}/>
          <Route path="/compras" exact element={<Dashboard object="compras" />}/>
          <Route path="/pagos" exact element={<Dashboard object="pagos" />}/>
          <Route path="/pagos-a-proveedores" exact element={<Dashboard object="pagosaproveedores" />}/>
          <Route path="/gastos" exact element={<Dashboard object="gastos" />}/>
          <Route path="/margenes" exact element={<Dashboard object="margenes" />}/>
          <Route path="/planes" exact element={<Dashboard object="planes" />}/>
          <Route path="/rubros" exact element={<Dashboard object="rubros" />}/>
          <Route path="/eventos" exact element={<Dashboard object="eventos" />}/>
          <Route path="/refinanciamientos" exact element={<Dashboard object="refinanciamientos" />}/>
          <Route path="/refpagos" exact element={<Dashboard object="refpagos" />}/>
          <Route path="/agenda" exact element={<Agenda/>}/>
          <Route path="/mis-tareas" exact element={<MisTareas/>}/>
          <Route path="/balance-de-cobros" exact element={<BalanceDeCobros/>}/>
          <Route path="/balance-mensual" exact element={<BalanceMensual/>}/>
          <Route path="/respaldos" exact element={<Respaldos/>}/>
          <Route path="/presupuestos" exact element={<Presupuestos action='Registrar'/>}/>
          <Route path="/" exact element={<Home />}/>
          <Route path="*" element={<Error404 />}/>
        </Routes> 
      </>
  );
}

export default App;
