import '../stylesheets/App.css';
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faSearch,faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
//import PDFComponent from './PDFComponent';
import PDFContainer from './pdfs/PDFContainer';
import 'react-datepicker/dist/react-datepicker.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Swal from 'sweetalert2';
import GraficoCobros from './subcomponents/GraficoCobros';
import GraficoVentas from './subcomponents/GraficoVentas';
import GraficoCaja from './subcomponents/GraficoCaja';
import { getHeaders } from '../helpers/apiCallConfiguration';
import es from 'date-fns/locale/es';
registerLocale('es', es)
library.add(faPlus, faSearch, faPrint); // Agrega los iconos que deseas utilizar



function BalanceMensual() {
    let [report, setReport] = useState(null);
    let [totalCobros, setTotalCobros] = useState(0)
    let [totalesVentas, setTotalesVentas] = useState({venta:0, costo:0, ganancia:0})
    let [totalesCaja, setTotalesCaja] = useState({ingresos:0, inversion:0, sueldos:0, costosNegocio:0, gastosExtra:0, resultado:0})

    let [fechaDesde, setFechaDesde] = useState(new Date());
    let [fechaHasta, setFechaHasta] = useState(new Date());

    let [mostrarPDF, setMostrarPDF] = useState(false);

    let [activeTab, setActiveTab] = useState(1);


    const imprimir = () => {
      setMostrarPDF(true);
    }

    const cargarDatos = async () => {
      let item = {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta
      }
      console.log(item);
      let result = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/reportes/balance-mensual",
        {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(item),
        }
      );
      let data = await result.json();

      setReport(data.data);
      //total cobros:
      let tot = 0;
      Object.keys(data.data.cobrosPorSemana).forEach(semana => 
        tot+=data.data.cobrosPorSemana[semana].cobros)
      setTotalCobros(Math.round(tot));

      //totales ventas
      let totales = {
        venta:0,
        costo:0,
        ganancia:0
      }
      Object.keys(data.data.ventasPorSemana).forEach(semana => {
        totales.venta+=data.data.ventasPorSemana[semana].venta;
        totales.costo+=data.data.ventasPorSemana[semana].costo;
      })
      totales.venta = Math.round(totales.venta)
      totales.costo = Math.round(totales.costo)
      totales.ganancia = totales.venta - totales.costo;
      setTotalesVentas(totales);

      //totales caja
      let totalesC = {
        ingresos:0,
        inversion:0,
        sueldos:0,
        gastosExtra:0,
        costosNegocio:0,
        resultado:0
      }
      Object.keys(data.data.informeDeCaja).forEach(semana => {
        totalesC.ingresos+=data.data.informeDeCaja[semana].ingresos;
        totalesC.inversion+=data.data.informeDeCaja[semana].inversion;
        totalesC.sueldos+=data.data.informeDeCaja[semana].sueldos;
        totalesC.costosNegocio+=data.data.informeDeCaja[semana].costosNegocio;
        totalesC.gastosExtra+=data.data.informeDeCaja[semana].gastosExtra;
        totalesC.resultado+=data.data.informeDeCaja[semana].resultado;
      })
      totalesC.ingresos = Math.round(totalesC.ingresos)
      totalesC.inversion = Math.round(totalesC.inversion)
      totalesC.sueldos = Math.round(totalesC.sueldos)
      totalesC.costosNegocio = Math.round(totalesC.costosNegocio)
      totalesC.gastosExtra = Math.round(totalesC.gastosExtra)
      totalesC.resultado = Math.round(totalesC.resultado)
      setTotalesCaja(totalesC);
            
      setMostrarPDF(true);
    }


    return (
        <>
            <div className="container my-5 margin-mobile-5vw">

                <h1 className="general-subtitle text-center my-4">Balance mensual</h1>
                <div className="border rounded p-3 my-3">
                        <h5>Opciones de filtrado</h5>
                        <div className="d-flex flex-wrap">
                            <div className="d-flex py-2">
                                <label className="label">Desde</label>
                                <DatePicker className="form-control"
                                    selected={fechaDesde} 
                                    onChange={(d)=>setFechaDesde(d)}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                />
                            </div>
                            <div className="d-flex py-2">
                                <label className="label">Hasta</label>
                                <DatePicker className="form-control"
                                    selected={fechaHasta} 
                                    onChange={(d)=>setFechaHasta(d)}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <button className="btn rounded-pill btn-dark col-12" onClick={cargarDatos}>
                                <FontAwesomeIcon icon={faSearch} /> Buscar
                            </button>
                        </div>
                        {mostrarPDF?
                        <div className="col-12 col-sm-6 col-lg-3">
                            <button className="btn rounded-pill btn-dark col-12 text-white">
                                <FontAwesomeIcon icon={faPrint} /> <PDFContainer 
                                pdfType='balance-mensual' 
                                title={'Balance mensual'} 
                                subtitle={'Periodo desde '+fechaDesde.toLocaleDateString()+' hasta '+fechaHasta.toLocaleDateString()}  
                                report={report} 
                                totalCobros = {totalCobros}
                                totalesVentas = {totalesVentas}
                                totalesCaja = {totalesCaja}/>
                            </button>
                        </div>
                    :''}
                    </div>
                    </div>
                <ul className="nav nav-tabs">
                  <li className={activeTab==1?"nav-item active":"nav-item"}>
                    <a className="nav-link" aria-current="page" href="#" onClick={()=>setActiveTab(1)}>Tablas</a>
                  </li>
                  <li className={activeTab==2?"nav-item active":"nav-item"}>
                    <a className="nav-link" href="#" onClick={()=>setActiveTab(2)}>Graficos</a>
                  </li>
                </ul>
                {activeTab==1?
                <div className="col-12 m-auto border rounded border-dark-blue p-4">
                    <div className="row">
                      <div className="col-12 col-sm-4">
                        <div className="border rounded p-3 mt-3">
                          <h5>Cobros por semana</h5>
                          <div className="table-responsive">
                          <table className="table mt-3">
                              <thead>
                                  <tr>
                                      <th scope="col">Semana</th>
                                      <th scope="col">Total</th>
                                  </tr>
                              </thead>
                              
                              {report && 
                              <tbody>
                              {Object.keys(report.cobrosPorSemana).map(semana => (
                                <tr key={`semana-${semana}`}>
                                  <td>{new Date(report.cobrosPorSemana[semana].fechaInicio).toLocaleDateString()+" - "+new Date(report.cobrosPorSemana[semana].fechaFin).toLocaleDateString()}</td>
                                  <td>$ {report.cobrosPorSemana[semana].cobros.toLocaleString()}</td>
                                </tr>
                              ))}
                              </tbody>
                              } 
                              <tfoot>
                                    <th scope="col">Total cobrado:</th>
                                    <th scope="col">$ {totalCobros.toLocaleString()}</th>
                              </tfoot> 

                              
                          </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-8">
                          <div className="border rounded p-3 mt-3">
                            <h5>Ventas por semana</h5>
                            <div className="table-responsive">
                            <table className="table mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col">Semana</th>
                                        <th scope="col">Venta</th>
                                        <th scope="col">Costo</th>
                                        <th scope="col">Ganancia</th>
                                    </tr>
                                </thead>
                                
                                {report && 
                                <tbody>
                                {Object.keys(report.ventasPorSemana).map(semana => (
                                  <tr key={`semana-${semana}`}>
                                    <td>{new Date(report.ventasPorSemana[semana].fechaInicio).toLocaleDateString()+" - "+ new Date(report.ventasPorSemana[semana].fechaFin).toLocaleDateString()}</td>
                                    <td>$ {report.ventasPorSemana[semana].venta.toLocaleString()}</td>
                                    <td>$ {report.ventasPorSemana[semana].costo.toLocaleString()}</td>
                                    <td>$ {(report.ventasPorSemana[semana].venta - report.ventasPorSemana[semana].costo).toLocaleString()}</td>
                                  </tr>
                                ))}
                                </tbody>
                                } 
                                <tfoot>
                                      <th scope="col">Totales:</th>
                                      <th scope="col">$ {totalesVentas.venta.toLocaleString()}</th>
                                      <th scope="col">$ {totalesVentas.costo.toLocaleString()}</th>
                                      <th scope="col">$ {totalesVentas.ganancia.toLocaleString()}</th>
                                </tfoot> 

                                
                            </table>
                            </div>
                          </div>
                      </div>
                    </div>
                    

                    <div className="border rounded p-3 mt-3">
                      <h5>Informe de caja</h5>
                      <div className="table-responsive">
                      <table className="table mt-3">
                          <thead>
                              <tr>
                                  <th scope="col">Semana</th>
                                  <th scope="col">Ingresos</th>
                                  <th scope="col">Inversion</th>
                                  <th scope="col">Sueldos</th>
                                  <th scope="col">Costos Negocio</th>
                                  <th scope="col">Gastos Extra</th>
                                  <th scope="col">Resultado</th>
                              </tr>
                          </thead>
                          
                          {report && 
                          <tbody>
                          {Object.keys(report.informeDeCaja).map(semana => (
                            <tr key={`semana-${semana}`}>
                              <td>{new Date(report.informeDeCaja[semana].fechaInicio).toLocaleDateString()+" - "+ new Date(report.informeDeCaja[semana].fechaFin).toLocaleDateString()}</td>
                              <td>$ {report.informeDeCaja[semana].ingresos.toLocaleString()}</td>
                              <td>$ {report.informeDeCaja[semana].inversion.toLocaleString()}</td>
                              <td>$ {report.informeDeCaja[semana].sueldos.toLocaleString()}</td>
                              <td>$ {report.informeDeCaja[semana].costosNegocio.toLocaleString()}</td>
                              <td>$ {report.informeDeCaja[semana].gastosExtra.toLocaleString()}</td>
                              <td>$ {report.informeDeCaja[semana].resultado.toLocaleString()}</td>
                            </tr>
                          ))}
                          </tbody>
                          } 
                          <tfoot>
                                <th scope="col">Totales:</th>
                                <th scope="col">$ {totalesCaja.ingresos.toLocaleString()}</th>
                                <th scope="col">$ {totalesCaja.inversion.toLocaleString()}</th>
                                <th scope="col">$ {totalesCaja.sueldos.toLocaleString()}</th>
                                <th scope="col">$ {totalesCaja.costosNegocio.toLocaleString()}</th>
                                <th scope="col">$ {totalesCaja.gastosExtra.toLocaleString()}</th>
                                <th scope="col">$ {totalesCaja.resultado.toLocaleString()}</th>
                          </tfoot> 

                          
                      </table>
                      </div>
                    </div>
                    
                    
                </div>:
                <div className="col-12 m-auto border rounded border-dark-blue p-4">
                  {mostrarPDF&&
                  <>               
                    <div className="p-3 mt-3">
                      <h5 className="mb-3">Cobros por semana</h5>
                      <div id="graph-container">
                          <GraficoCobros list = {report.cobrosPorSemana} />   
                      </div>
                    </div>
                    <div className="p-3 mt-3">
                      <h5 className="mb-3">Ventas por semana</h5>
                      <div id="graph-container">
                          <GraficoVentas list = {report.ventasPorSemana} />   
                      </div>
                    </div>
                    <div className="p-3 mt-3">
                      <h5 className="mb-3">Informe de caja</h5>
                      <div id="graph-container">
                          <GraficoCaja list = {report.informeDeCaja} />   
                      </div>
                    </div>
                  </>}
                </div>}
            </div>
        </>
    );
}

export default BalanceMensual;