export function validateDate(state, name, messageClass){
    let element = document.getElementsByName(name)[0];
    let message = document.querySelector('.'+messageClass);
    if(state==''||state==null){
      element.classList.add('is-invalid');
      element.classList.add('mb-3');
      element.classList.remove('is-valid');
      message.style.display='block';
      return false;
    }else{
      element.classList.add('is-valid');
      element.classList.remove('is-invalid');
      element.classList.remove('mb-3');
      message.style.display='none';
      return true;
    }
   }
   
   export function validateInput(state, name){
    let element = document.getElementsByName(name)[0];
    if(state==''||state==null){
      element.classList.add('is-invalid');
      element.classList.add('mb-3');
      element.classList.remove('is-valid');
      return false;
    }else{
      element.classList.add('is-valid');
      element.classList.remove('mb-3');
      element.classList.remove('is-invalid');
      return true;
    }
  } 

    export function validateInputNum(state, name){
      let element = document.getElementsByName(name)[0];
      if(state==''||state==null || !/^[0-9]+([.][0-9]+)?$/.test(state)){
        element.classList.add('is-invalid');
        element.classList.add('mb-3');
        element.classList.remove('is-valid');
        return false;
      }else{
        element.classList.add('is-valid');
        element.classList.remove('mb-3');
        element.classList.remove('is-invalid');
        return true;
      }
    } 

    export function validateInputMoney(state, name, containerID){
      let container = document.getElementById(containerID)
      let element = document.getElementsByName(name)[0];
      if(state==''||state==null || !/^[0-9]+([.][0-9]+)?$/.test(state)){
        element.classList.add('is-invalid');
        container.classList.add('mb-3');
        element.classList.remove('is-valid');
        return false;
      }else{
        element.classList.add('is-valid');
        container.classList.remove('mb-3');
        element.classList.remove('is-invalid');
        return true;
      }
    } 