import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';


// Register font
Font.register({ family: 'Barlow_Medium', src: '/fonts/Barlow-Medium.ttf' });
// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    /*flexDirection: 'row',*/
    backgroundColor: '#ffffff',
    padding: 10,
    justifyContent:'flex-start'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  roundedSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius:20,
    paddingLeft:50
  },
  roundedSubsection: {
    marginTop:10,
    marginBottom:10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
    flexWrap: 'no-wrap'
  },
  tableContainer:{
    borderColor: '#000000',
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    borderWidth:1
  },
  sectionFlex: {
    
    padding:5,
    gap:10,
    width: '45%'
  },
  headerContainer: {
    display:'flex',
    flexWrap: 'no-wrap',
    padding:10,
    flexDirection: 'row',
    gap:10,
    borderWidth:1,
    borderColor:'#000000',
    borderRadius:10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  textSubrayado: {
    fontSize: 12,
    textAlign: 'justify',
    textDecoration: 'underline'
  },
  slogan: {
    fontSize: 12,
    fontFamily:'Barlow_Medium',
    textAlign: 'justify',
    fontStyle:'italic'
  },
  text45: {
    fontSize: 12,
    textAlign: 'justify',
    width: '45%'
  },
  image: {
    width:'100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
  },
  lastTableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    padding: 4,
  },
  lastTableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    padding: 4,
  },
  footer: {
    fontSize: 14,
    textAlign: 'right',
    marginTop:'10px'
  },
  section30: {
    width:'30%',
  },
  section70: {
    width:'70%',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    gap:15,
  },
  absText:{
    position:'absolute',
    top:0,
    left:0,
    writingMode: 'vertical-rl',
  }
});

const PDFVentaParaCompletar = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.headerContainer}>
        <View style={styles.section30}>
            <Image src="/images/logo.jpg" style={styles.image} />
        </View>
        <View style={styles.section70}>
            <Text style={styles.subtitle}>Aurelio Muebles</Text>
            <Text style="styles.slogan">"Financiación Amigable a tu Medida"</Text>
            <Text style={styles.subtitle}>Fecha: {new Date(props.item.fecha+'T00:00:00').toLocaleDateString()}</Text>
        </View>
        
        {/* Resto del contenido */}
      </View>

      <View style={styles.roundedSection}>
        <View style={styles.sectionFlex}>
          <Text style={styles.textSubrayado}>Datos del cliente</Text>
          <Text style={styles.text}>Nombre: {props.item.cliente.nombre}</Text>
          <Text style={styles.text}>Dirección: {props.item.cliente.direccion}</Text>
          <Text style={styles.text}>Localidad: {props.item.cliente.localidadNombre}</Text>
          <Text style={styles.text}>Teléfono: {props.item.cliente.telefono}</Text>
          
        </View>
        <View style={styles.sectionFlex}>
          <Text style={styles.textSubrayado}>Datos de la venta</Text>
          <Text style={styles.text}>Plan: {props.item.plan.descripcion}</Text>
          <Text style={styles.text}>Productos:</Text>
          {props.item.detalles.map((item,i)=>
                <Text style={styles.text}>{item.cantidad} {item.producto.descripcion}</Text>)}
        </View>
      </View>
      <View style={styles.section}>
        {/* Puedes repetir esta estructura para cada fila de la tabla */}
        <Text style={styles.subtitle}>Cuotas:</Text>
        <View style={styles.tableContainer}>
            <View style={styles.tableRow}>
            <Text style={styles.tableCell}>#</Text>
            <Text style={styles.tableCell}>Estado</Text>
            <Text style={styles.tableCell}>Vencimiento</Text>
            <Text style={styles.tableCell}>Monto</Text>
            <Text style={styles.tableCell}>Interés</Text>
            <Text style={styles.tableCell}>Total a pagar</Text>
            <Text style={styles.tableCell}>Pagado</Text>
            <Text style={styles.lastTableCell}>Saldo</Text>
            </View>
            {props.item.cuotas.map((cuota,i)=>
            <View style={i==props.item.cuotas.length-1?styles.lastTableRow:styles.tableRow}>
            <Text style={styles.tableCell}>{cuota.descripcion}</Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}>{new Date(cuota.vencimiento+'T00:00:00').toLocaleDateString()}</Text>
            <Text style={styles.tableCell}>${cuota.monto.toLocaleString()}</Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.tableCell}></Text>
            <Text style={styles.lastTableCell}></Text>
            </View>)}
        </View>
        
      </View>
      
    </Page>
    <Page>
      <View style={styles.section}>
          {/* Puedes repetir esta estructura para cada fila de la tabla */}
          <Text style={styles.subtitle}>Pagos realizados:</Text>
          <View style={styles.tableContainer}>
              <View style={styles.tableRow}>
              <Text style={styles.tableCell}>#</Text>
              <Text style={styles.tableCell}>Fecha</Text>
              <Text style={styles.tableCell}>Descripción</Text>
              <Text style={styles.lastTableCell}>Monto</Text>
              </View>
              {props.item.cuotas.map((c,i)=>
              <View style={i==props.item.cuotas.length-1?styles.lastTableRow:styles.tableRow}>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.lastTableCell}></Text>
              </View>)}
          </View>
        </View>
    </Page>
  </Document>
);

export default PDFVentaParaCompletar;