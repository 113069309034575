import '../stylesheets/App.css';
//import '../stylesheets/Login.css';
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Loader from './subcomponents/Loader.js';
import Swal from 'sweetalert2';
import { getHeaders } from '../helpers/apiCallConfiguration.js';

function Login(props) {
    let[loadingLogin, setLoadingLogin] = useState(false);
    const [nombre, setNombre]=useState('');
    const [contrasena, setContrasena]=useState('');
    const [errorMsg, setErrorMsg]=useState('');
    
    let navigate = useNavigate();

    const login = async () =>{
        setLoadingLogin(true);
        let item = {nombre, contrasena};
        let result = await fetch(process.env.REACT_APP_API_BASE_URL+"/usuarios/login", {
            method : 'POST',
            headers : getHeaders(),
            body: JSON.stringify(item)
        })
        result = await result.json();
        console.log(result);
        setLoadingLogin(false);
        if(result.status == 200){
            console.log("usuario correcto")
            Swal.fire('Usuario correcto', 'Hola, '+result.data.nombre+' bienvenido al sistema.', 'success');
            localStorage.setItem("user-info", JSON.stringify(result.data));
            props.setActualizar(true);
            if(result.data.idRol==1){
                navigate("/");
            }else{
                navigate("/");
            }
        }else if(result.status == 201){
            console.log('usuario inactivo');
            setErrorMsg('el usuario ingresado ya no esta activo en este sitio.');
            Swal.fire('Error al ingresar', 'El usuario ingresado ya no esta activo en este sitio.', 'error');
        }else{
            console.log("usuario incorrecto")
            setErrorMsg(result.data.errors[0].msg);
            Swal.fire('Error al ingresar', result.data.errors[0].msg, 'error');
        }
    }
    useEffect(()=>{
        /*compruebo si es admin y si esta logueado*/
        if(localStorage.getItem("user-info") && JSON.parse(localStorage.getItem("user-info")).idRol==1){
            navigate("/");
        }
    }, []);

    return (
        <>
        <div className="container mt-1 mt-md-5">
            <div className="row">
                <div className="col-12 col-md-6 m-auto">
                    <img src="/images/banner.jpg" className="w-100"/>

                    <input type="text" className="form-control mb-3 mt-5" placeholder='Nombre de usuario' id="name" name="name" onChange={(e)=>setNombre(e.target.value)}></input>
                    <input type="password" className="form-control mb-3" placeholder='Contraseña' id="password" name="password" onChange={(e)=>setContrasena(e.target.value)}></input>
                    <div>
                        <span className="error-msg">{errorMsg}</span>
                    </div>
                    <div className="d-flex  justify-content-center">
                        <button type="submit" className="btn btn-outline-primary mx-2" id="login" onClick={login}>Acceder</button>
                    </div>
                    
                </div>
                
            </div>
        </div>
        {loadingLogin?<Loader />:''}
        </>
    );
}

export default Login;