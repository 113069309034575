import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { validateDate, validateInput } from '../../helpers/validators';
import { getHeaders } from '../../helpers/apiCallConfiguration';

function DetalleRubro(props) {

    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');


    const saveChanges=async ()=>{
        //validar
        let descripcionV = validateInput(descripcion, 'descripcion')
        if(descripcionV){
          let item = {
            idRubro: props.element?props.element.idRubro:null,
            descripcion:descripcion!=''?descripcion:props.element.descripcion,
          };
        console.log(item);
        let result;
        if(props.action=='Registrar'){
            result = await fetch(
                process.env.REACT_APP_API_BASE_URL + "/rubros/register",
                {
                  method: "POST",
                  headers: getHeaders(),
                  body: JSON.stringify(item),
                }
              );
        }else{
            
            result = await fetch(
                process.env.REACT_APP_API_BASE_URL + "/rubros/update",
                {
                  method: "PUT",
                  headers: getHeaders(),
                  body: JSON.stringify(item),
                }
              );
        }
          
          result = await result.json();
          let mensaje = props.action=="Registrar"?"registrado":"modificado";
          if(result.status==200){
                Swal.fire('Rubro '+mensaje, 'El Rubro ha sido '+mensaje+' con éxito.', 'success');
                props.setActualizar((val)=>val+1);
                setComponentKey((prevKey) => prevKey + 1);
                 //ocultar popup
                document.querySelector('#exampleModal .btn-close').click();
          }else{
                Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
            }
          console.log(result);
        }
        
          
          
    }

      useEffect(()=>{
        //setear estados de form
        setDescripcion(props.element?props.element.descripcion:'');

       
        }, [props.action, props.element]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el Rubro seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Rubro</label>
                    <input className="form-control" name="idRubro" value={props.element?props.element.idRubro:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descripción</label>
                    <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una descripción.
                    </div>
                </div>
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleRubro;