import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useKey, useEffect} from 'react';
import {Link} from 'react-router-dom';
import DetalleVenta from './DetalleVenta';
import DetalleRefinanciamiento from './DetalleRefinanciamiento';
import DetalleEvento from './DetalleEvento';


function PopupAgenda(props) {


  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal fade" id="modalPopupAgenda" tabIndex="-1" aria-labelledby="modalPopupAgendaLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="modalPopupAgendaLabel">Detalle de evento. Tipo: {props.object}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {
                props.object=='ventas'? <DetalleVenta action={'Ver detalle'} element={props.element} setActualizar={props.setActualizar}/>:
                props.object=='refinanciamientos'? <DetalleRefinanciamiento action={'Ver detalle'} element={props.element} setActualizar={props.setActualizar}/>:''}
                <DetalleEvento action={'Editar'} element={props.element} setActualizar={props.setActualizar} object={props.object}/>
            </div>
        </div>
        </div>
      </>
  );
}

export default PopupAgenda;