import React, {useState, useEffect} from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, Area } from 'recharts'


const GraficoCaja = ({list}) => {

  let [data, setData] = useState([]);

  useEffect(()=>{
    let newList = []
    Object.keys(list).forEach(numeroSemana =>{
      let semana = list[numeroSemana];
      console.log('semana:')
      console.log(semana)
      let indicador = new Date(semana.fechaInicio).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })+" - "+new Date(semana.fechaFin).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })
      let item = {
        semana: indicador,
        ingresos: semana.ingresos,
        inversion: semana.inversion,
        sueldos: semana.sueldos,
        costosNegocio: semana.costosNegocio,
        gastosExtra: semana.gastosExtra,
        resultado: semana.resultado
      }
      newList.push(item)
    })
    setData(newList)
}, [list]);

  return (
    <ResponsiveContainer width="100%" aspect={2}>
        <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="semana" />
            <YAxis />
            <Tooltip />
            <Area
                type="monotone"
                dataKey="ingresos"
                stackId="1"
                stroke="#82ca9d"
                fill="#82ca9d"
            />
            <Area
                type="monotone"
                dataKey="inversion"
                stackId="1"
                stroke="#FF8042"
                fill="#FF8042"
            />
            <Area
                type="monotone"
                dataKey="sueldos"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
            />
            <Area
                type="monotone"
                dataKey="costosNegocio"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
            />
            <Area
                type="monotone"
                dataKey="gastosExtra"
                stackId="1"
                stroke="#FF8042"
                fill="#FF8042"
            />
            <Area
                type="monotone"
                dataKey="resultado"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
            />
        </AreaChart>
    </ResponsiveContainer>
  )
}

export default GraficoCaja