import React, {useState,useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    /*flexDirection: 'row',*/
    backgroundColor: '#ffffff',
    padding: 10,
    justifyContent:'flex-start'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  roundedSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius:20,
    paddingLeft:50
  },
  roundedSubsection: {
    marginTop:10,
    marginBottom:10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
    flexWrap: 'no-wrap'
  },
  tableContainer:{
    borderColor: '#000000',
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    borderWidth:1
  },
  sectionFlex: {
    
    padding:5,
    gap:10,
    width: '45%'
  },
  headerContainer: {
    display:'flex',
    flexWrap: 'no-wrap',
    padding:10,
    flexDirection: 'row',
    gap:10,
    borderWidth:1,
    borderColor:'#000000',
    borderRadius:10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  textSubrayado: {
    fontSize: 12,
    textAlign: 'justify',
    textDecoration: 'underline'
  },
  slogan: {
    fontSize: 12,
    fontFamily:'Barlow_Medium',
    textAlign: 'justify',
    fontStyle:'italic'
  },
  text45: {
    fontSize: 12,
    textAlign: 'justify',
    width: '45%'
  },
  image: {
    width:'100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
  },
  lastTableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    padding: 4,
  },
  lastTableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    padding: 4,
  },
  footer: {
    fontSize: 14,
    textAlign: 'right',
    marginTop:'10px'
  },
  section30: {
    width:'30%',
  },
  section70: {
    width:'70%',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    gap:15,
  },
  absText:{
    position:'absolute',
    top:0,
    left:0,
    writingMode: 'vertical-rl',
  }
});




const PDFComponent = (props) => {
  //const chartImage = generateChart();
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    /*
    let container = document.getElementById('graph-container');
    htmlToImage.toPng(container).then((data) => setImageSrc(data));*/
  }, []);


  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.headerContainer}>
        <View style={styles.section30}>
            <Image src="/images/logo.jpg" style={styles.image} />
        </View>
        <View style={styles.section70}>
            <Text style={styles.subtitle}>Aurelio Muebles</Text>
            <Text style="styles.slogan">"Financiación Amigable a tu Medida"</Text>
            <Text style={styles.subtitle}>Fecha: {new Date().toLocaleDateString()}</Text>
        </View>
        
        {/* Resto del contenido */}
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.subtitle}>
          {props.subtitle}
        </Text>
      </View>
      <View style={styles.section}>
        <View style={styles.tableRow}>
        {props.encabezados?props.encabezados.map((item,i)=>
          <Text style={styles.tableCell}>{item}</Text>)
                            :
                            ''}
        </View>
        {/* Puedes repetir esta estructura para cada fila de la tabla */}
        
        {props.list?props.list.map((item,i)=>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>{new Date(item.fechaPago+'T00:00:00').toLocaleDateString()}</Text>
          <Text style={styles.tableCell}>{item.cliente}</Text>
          <Text style={styles.tableCell}>{item.idVenta}</Text>
          <Text style={styles.tableCell}>$ {item.importeCuota.toLocaleString()}</Text>
          <Text style={styles.tableCell}>$ {item.interes.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Text>
          <Text style={styles.tableCell}>$ {item.importeAbonado.toLocaleString()}</Text>
        </View>)
                            :
                            ''}
        <Text style={styles.footer}>
          {props.footer}
        </Text>
      </View>
      {/*
      <View style={styles.canvasSection}>
        <Image src={imageSrc}/>
      </View>
        */}
    </Page>
  </Document>

  );
};



export default PDFComponent;