import React, { useEffect, useState}from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { redondearA100 } from '../../helpers/calculos';
import { PieChart } from 'recharts';

// Register font
Font.register({ family: 'Barlow_Medium', src: '/fonts/Barlow-Medium.ttf' });
// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    /*flexDirection: 'row',*/
    backgroundColor: '#ffffff',
    padding: 10,
    justifyContent:'flex-start'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  presupuestos:{
    borderColor: '#000',
    borderRadius:20,
    padding:50,
  },
  roundedSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius:20,
    paddingLeft:50
  },
  roundedSubsection: {
    marginTop:10,
    marginBottom:10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
    flexWrap: 'no-wrap'
  },
  tableContainer:{
    borderColor: '#000000',
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    borderWidth:1
  },
  sectionFlex: {
    
    padding:5,
    gap:10,
    width: '45%'
  },
  headerContainer: {
    display:'flex',
    flexWrap: 'no-wrap',
    padding:10,
    flexDirection: 'row',
    gap:10,
    borderWidth:1,
    borderColor:'#000000',
    borderRadius:10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    marginTop:10,
    textAlign: 'center',
  },
  subtitleUnderline: {
    fontSize: 14,
    marginBottom: 10,
    marginTop:10,
    textAlign: 'center',
    textDecoration: 'underline',
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  centerText: {
    fontSize: 12,
    textAlign: 'center',
  },
  textSubrayado: {
    fontSize: 12,
    textAlign: 'justify',
    textDecoration: 'underline'
  },
  slogan: {
    fontSize: 12,
    fontFamily:'Barlow_Medium',
    textAlign: 'justify',
    fontStyle:'italic'
  },
  text45: {
    fontSize: 12,
    textAlign: 'justify',
    width: '45%'
  },
  image: {
    width:'100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
  },
  lastTableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    padding: 4,
  },
  lastTableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    padding: 4,
  },
  footer: {
    fontSize: 14,
    textAlign: 'right',
    marginTop:'10px'
  },
  section30: {
    width:'30%',
  },
  section70: {
    width:'70%',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    gap:15,
  },
  absText:{
    position:'absolute',
    top:0,
    left:0,
    writingMode: 'vertical-rl',
  }
});

const PDFPresupuestoVariosPlanes = ({item, planes, productos, cantidades}) =>{

    let [presupuestosSemanales,setPresupuestosSemanales] = useState([])
    let [presupuestosQuincenales,setPresupuestosQuincenales] = useState([])
    let [presupuestosMensuales,setPresupuestosMensuales] = useState([])
    let [presupuestosContado,setPresupuestosContado] = useState([])
    
    
    useEffect(()=>{
        console.log(planes)
        let ps = []
        let pq = []
        let pm = []
        let pc = []
        if(planes.length>0 && item && productos.length>0 && cantidades.length>0){
            
            planes.forEach(plan=>{
                //calculo subtotal
                let subtotal = 0;
                productos.forEach((producto, i)=>{
                    let precio = producto.precioLista;
                    if(plan.idModalidad==4){
                        precio = producto.precioContado
                    }
                    let valor =precio * cantidades[i];
                    subtotal+=valor;
                })
                //considero anticipo y cuota vencida
                if(item.porcentajePorCuotaVencida!=0){
                    console.log("calculo incluyendo ipcv")
                    let iPCV = (subtotal-item.anticipo)*item.porcentajePorCuotaVencida/100;
                    subtotal = (subtotal-item.anticipo) + iPCV

                }else{
                    subtotal=subtotal - item.anticipo;
                }
                subtotal = subtotal + subtotal*plan.porcentaje/100;
                subtotal = Math.round(subtotal);
                let valorCuota = 0;
                let valorCuotaInicial = 0;
                if(plan.tienePagoInicial){
                    valorCuotaInicial =subtotal/plan.plazo;
                    if(plan.idModalidad==1){
                        valorCuota = valorCuotaInicial/4;
                    }else if(plan.idModalidad==2){
                        valorCuota = valorCuotaInicial/2;
                    }else{
                        valorCuota = valorCuotaInicial;
                    }
                    valorCuota = redondearA100(valorCuota);
                    valorCuotaInicial = redondearA100(valorCuotaInicial)
                }else{
                    valorCuota = redondearA100(subtotal/plan.cantidadCuotas);
                }
                let presupuesto = {
                    tipo:plan.idModalidad,
                    cantidadCuotas: plan.cantidadCuotas,
                    valorCuotaInicial: valorCuotaInicial,
                    valorCuota:valorCuota,
                    tienePagoInicial : plan.tienePagoInicial
                }

                switch(plan.idModalidad){
                    case 1:
                        ps.push(presupuesto)
                        break;
                    case 2:
                        pq.push(presupuesto)
                        break;
                    case 3:
                        pm.push(presupuesto)
                        break;
                    case 4:
                        pc.push(presupuesto)
                        break;
                }
            })
            
        }
        
        setPresupuestosContado(pc);
        setPresupuestosMensuales(pm);
        setPresupuestosQuincenales(pq);
        setPresupuestosSemanales(ps);
        
       
        }, [item, planes, productos, cantidades]);
    
    return(
    <Document>
        <Page size="A4" style={styles.page}>
        <View style={styles.headerContainer}>
            <View style={styles.section30}>
                <Image src="/images/logo.jpg" style={styles.image} />
            </View>
            <View style={styles.section70}>
                <Text style={styles.subtitle}>Aurelio Muebles</Text>
                <Text style="styles.slogan">"Financiación Amigable a tu Medida"</Text>
                <Text style={styles.subtitle}>Fecha: {item.fecha.toLocaleDateString()}</Text>
            </View>
            
            {/* Resto del contenido */}
        </View>

        <View style={styles.roundedSection}>
            <View style={styles.sectionFlex}>
            <Text style={styles.textSubrayado}>Datos del presupuesto</Text>
            <Text style={styles.text}>Productos:</Text>
            {productos.map((item,i)=>
                <Text style={styles.text}>{cantidades[i]} {item.descripcion}</Text>)}
            </View>
            <View style={styles.sectionFlex}>
            {item.anticipo !=0 &&
            <Text style={styles.text}>Anticipo: ${item.anticipo}</Text>
            }
            {item.porcentajePorCuotaVencida !=0 && item.vtoInicial &&
            <Text style={styles.text}>Vencimiento Primer Cuota: {item.vtoInicial.toLocaleDateString()}</Text>
            }
            </View>
            

            
        </View>
        <View style={styles.presupuestos}>

            {presupuestosContado.length>0 && <Text style={styles.subtitleUnderline}>Plan Contado:</Text>}
        
            {presupuestosContado.map((item,i)=>
            <Text style={styles.centerText}>1 pago de ${item.valorCuota}</Text>)}
        
            {presupuestosSemanales.length>0 && <Text style={styles.subtitleUnderline}>Planes Semanales:</Text>}
            {presupuestosSemanales.map((item,i)=>{
                if(item.tienePagoInicial==1){
                    return(
                        <Text style={styles.centerText}>1 pago inicial de ${item.valorCuotaInicial} y {item.cantidadCuotas-1} semanas de ${item.valorCuota}</Text>
                    )
                }else{
                    return(
                     <Text style={styles.centerText}>{item.cantidadCuotas} semanas de ${item.valorCuota}</Text>
                    )
                    }
                
            })}
            {presupuestosQuincenales.length>0 && <Text style={styles.subtitleUnderline}>Planes Quincenales:</Text>}
           
            {presupuestosQuincenales.map((item,i)=>{
                if(item.tienePagoInicial==1){
                    return(
                        <Text style={styles.centerText}>1 pago inicial de ${item.valorCuotaInicial} y {item.cantidadCuotas-1} quincenas de ${item.valorCuota}</Text>
                    )
                }else{
                    return(
                     <Text style={styles.centerText}>{item.cantidadCuotas} quincenas de ${item.valorCuota}</Text>
                    )
                    }
                
            })}
            {presupuestosMensuales.length>0 && <Text style={styles.subtitleUnderline}>Planes Mensuales:</Text>}
    
            {presupuestosMensuales.map((item,i)=>
                <Text style={styles.centerText}>{item.cantidadCuotas} meses de ${item.valorCuota}</Text>)}
            
        </View>

        </Page>
    </Document>
) }

export default PDFPresupuestoVariosPlanes;