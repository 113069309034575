import React, {useState, useEffect} from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, PieChart, Pie, Cell } from 'recharts'


const GraficoCobros = ({list}) => {

  let [data, setData] = useState([]);


  useEffect(()=>{
    let newList = []
    Object.keys(list).forEach(numeroSemana =>{
      let semana = list[numeroSemana];
      console.log('semana:')
      console.log(semana)
      let indicador = new Date(semana.fechaInicio).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })+" - "+new Date(semana.fechaFin).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })
      let item = {
        semana: indicador,
        cobrado: semana.cobros
      }
      newList.push(item)
    })
    setData(newList)
}, [list]);

  return (
    <ResponsiveContainer width="100%" aspect={2}>
      
        <BarChart 
            data={data}
            width={500}
            height={300}
            margin={{
                top:5,
                right:30,
                left:20,
                bottom:5
            }}
        >
        <CartesianGrid strokeDasharray="4 1 2" />    
        <XAxis dataKey="semana"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="cobrado" fill="#6b48ff"/>
          </BarChart>
{/*}
<PieChart width={1000} height={400}>
      <Pie
        dataKey="cobrado"
        isAnimationActive={false}
        data={data}
        cx={200}
        cy={200}
        outerRadius={80}
        fill="#8884d8"
        label
      />
      <Tooltip />
        </PieChart>*/}


    </ResponsiveContainer>
  )
}

export default GraficoCobros