import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DetalleCuotas from '../subcomponents/DetalleCuotas';
import DetallePagosPorVenta from '../subcomponents/DetallePagosPorVenta';
import { validateDate, validateInput, validateInputNum } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';
import CreatableSelect from 'react-select/creatable';
import { redondearA100 } from '../../helpers/calculos';

import es from 'date-fns/locale/es';
registerLocale('es', es)

function DetalleRefinanciamiento(props) {

    let [ventas, setVentas] = useState([]);
    let [clientes, setClientes] = useState([]);
    let [planes, setPlanes] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campo de form para detalle ref 
    let [detalles, setDetalles] = useState(props.action=="Registrar"?[]:props.element?props.element.detalles:[])
    //campos del form
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):'');
    let [bonificacion, setBonificacion] = useState(props.action=='Registrar'?0:props.element?props.element.bonificacion:0);
    let [totalAPagar, setTotalAPagar] = useState(props.action=='Registrar'?0:props.element?props.element.totalAPagar:0);
    let [montoPagado, setMontoPagado] = useState(props.action=='Registrar'?0:props.element?props.element.montoPagado:0);
    let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');
    let [saldo, setSaldo] = useState(props.action=='Registrar'?'':props.element?props.element.saldo:'');
    //let [idVenta, setIDVenta] = useState(props.action=='Registrar'?'':props.element?props.element.idVenta:'');
    let [idCliente, setIDCliente] = useState(props.action=='Registrar'?'':props.element?props.element.idCliente:'');
    let [idPlan, setIDPlan] = useState(props.action=='Registrar'?'':props.element?props.element.idPlan:'');
    let [interesActivado, setInteresActivado] = useState(props.action=='Registrar'?1:props.element?props.element.interesActivado:'');
     let [vtoInicial, setVtoInicial] = useState(props.action=="Registrar"?new Date():props.element?new Date(props.element.vtoInicial+'T00:00:00'):'');
    //campos del form q se autocompletan
    
    let [deudaOriginal, setDeudaOriginal] = useState(props.action=='Registrar'?0:props.element?props.element.deudaOriginal:0);
    let [interes, setInteres] = useState(props.action=='Registrar'?0:props.element?props.element.plan.porcentaje:0);
    let [valorCuota, setValorCuota] = useState(props.action=='Registrar'?'':props.element?props.element.valorCuota:'');
    let [cantidadCuotas, setCantidadCuotas] = useState(props.action=='Registrar'?'':props.element?props.element.plan.cantidadCuotas:'');
    
     //indicador de si el plan tiene Pago Iniciar en su modalidad
     let [tienePagoInicial, setTienePagoInicial] = useState(props.action=='Registrar'?0:props.element?props.element.plan.tienePagoInicial:0);
     let [valorCuotaInicial, setValorCuotaInicial] = useState(props.action=='Registrar'?null:props.element&&props.element.plan.tienePagoInicial==1?props.element.valorCuotaInicial:'');
     let [primerPago, setPrimerPago] = useState(props.action=='Registrar'?0:props.element?props.element.primerPago:0);


      const updateClientes=(data)=>{
        console.log(data.data);
        setClientes(data.data);

    }

    const updateVentas=(data)=>{
      console.log(data.data);
      setVentas(data.data);

    }

    const updatePlanes=(data)=>{
        console.log(data.data);
        setPlanes(data.data);
  
    }

    const calculos = (det,  idP, bonif) => {
        if(det.length>0){
            let ventasSelec = []
            let deudaO = 0
            console.log('detalle venta:')
            console.log(det)
            det.forEach(d=>{
                console.log('value:'+d.value)
                console.log(ventas)
                let venta = ventas.find(v=>v.idVenta == d.value);
                console.log(venta)
                deudaO+=venta.saldo;
                ventasSelec.push(venta)
            })
            
            if(ventasSelec.length>0){
                setDeudaOriginal(deudaO);
                if(idP){
                    let plan = planes.find(p=>p.idPlan == idPlan);
                    setInteres(plan.porcentaje);
                    setCantidadCuotas(plan.cantidadCuotas);
                    let subtotal = deudaO-bonif*deudaO/100;
                    let pfinal = subtotal + subtotal*plan.porcentaje/100
                    setTotalAPagar(Math.round(pfinal));
    
                    //setValorCuota(pfinal/cantidadCuotas);
                    //logica que considera si hay pago inicial
                    let c;
                    let cf;
                    let cRound;
                    let cfRound;
                    if(plan.tienePagoInicial==1){
                        setTienePagoInicial(1);
                        cf = pfinal/plan.plazo
                        if(plan.idModalidad==1){
                            c = cf/4;
                        }else if(plan.idModalidad==2){
                            c = cf/4;
                        }else{
                            c = c;
                        }
                        cRound = redondearA100(c);
                        cfRound = redondearA100(cf);
                        setValorCuota(cRound);
                        setValorCuotaInicial(cfRound);
                        
                    }else{
                        setTienePagoInicial(0)
                        c=pfinal/plan.cantidadCuotas;
                        cRound=redondearA100(c);
                        setValorCuota(cRound);
                    }
                    /*
                    if(primerPago){
                        setSaldo(pfinal-primerPago-montoPagado)
                    }else{
                        setSaldo(pfinal-montoPagado)
                    }*/
    
    
                }else{
                    setTienePagoInicial(0);
                }
            }

         }
        

    }


    const saveChanges=async ()=>{
        //validar
        
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        //let ventaV = validateInputNum(idVenta, 'venta')
        let clienteV = validateInputNum(idCliente, 'cliente')
        let planV = validateInputNum(idPlan, 'plan')
        let descripcionV = validateInput(descripcion, 'descripcion')
        let detalleRef = []
        detalles.forEach(d=>{
            let venta = ventas.find(v=>v.idVenta == d.value)
            let detalle = {
                idVenta : d.value,
                monto: venta.saldo,
            }
            detalleRef.push(detalle)
        })
        if(fechaV && detalles.length>0 && clienteV && planV && descripcionV ){
            let item = {
                fecha:fecha!=''?fecha:props.element.fecha,
                deudaOriginal:deudaOriginal!=''?deudaOriginal:props.element.deudaOriginal,
                bonificacion:bonificacion!=''?bonificacion:props.element?props.element.bonificacion:0,
                totalAPagar:totalAPagar!=''?totalAPagar:props.element?props.element.totalAPagar:0,
                montoPagado:montoPagado!=''?montoPagado:props.element?props.element.montoPagado:0,
                primerPago:primerPago!=''?primerPago:0,
                saldo:saldo!=''?saldo:props.element.saldo,
                //idVenta:idVenta!=''?idVenta:props.element.idVenta,
                idCliente:idCliente!=''?idCliente:props.element.idCliente,
                idPlan:idPlan!=''?idPlan:props.element.idPlan,
                interesActivado: interesActivado,
                vtoInicial: vtoInicial?vtoInicial:fecha,
                descripcion:descripcion,
                valorCuota: valorCuota,
                valorCuotaInicial: valorCuotaInicial,
                detalleRefinanciamiento: detalleRef
              };
            console.log(item);
            
            let result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/refinanciamientos/register",
                    {
                      method: "POST",
                      headers:getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
              
              if(result.status==200){
                    Swal.fire('Refinanciamiento registrado', 'El refinanciamiento ha sido registrado con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                     document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
              
        }else{
            Swal.fire('Algo salio mal', 'Debe completar todos los campos y seleccionar al menos una venta para refinanciar', 'warning');
        }
        
        
          
    }

      useEffect(()=>{
        //setear estados de form
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setDeudaOriginal(props.element?props.element.deudaOriginal:'');
        setBonificacion(props.element?props.element.bonificacion:0);
        setTotalAPagar(props.element?props.element.totalAPagar:0);
        setMontoPagado(props.element?props.element.montoPagado:0);
        setPrimerPago(props.element?props.element.primerPago:0);
        setDescripcion(props.element?props.element.descripcion:'');
        setSaldo(props.element?props.element.saldo:'');
        //setIDVenta(props.element?props.element.idVenta:'');
        setIDCliente(props.element?props.element.idCliente:'');
        setIDPlan(props.element?props.element.idPlan:'');
        setInteres(props.element?props.element.plan.porcentaje:'')
        setInteresActivado(props.element?props.element.interesActivado:1);
        setVtoInicial(props.element?new Date(props.element.vtoInicial+'T00:00:00'):new Date());
        /*compruebo si es admin y si esta logueado*/
        /*
        if(!localStorage.getItem("user-info")){
           navigate("/login");
       }else*/
           apiCall(process.env.REACT_APP_API_BASE_URL+"/clientes/list/",updateClientes);
           //apiCall(process.env.REACT_APP_API_BASE_URL+"/ventas/list/",updateVentas);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/planes/list/",updatePlanes);
           //ocultar buscador
           //document.getElementById("search-container").style.visibility= 'hidden';

       
        }, [componentKey]);

        useEffect(()=>{
            if(idCliente){
                apiCall(process.env.REACT_APP_API_BASE_URL+"/ventas/list-por-cliente/"+idCliente,updateVentas);
            }

          
        }, [idCliente])

        useEffect(()=>{
            
            if(ventas.length>0){
                /*
                setIDVenta(ventas[0].idVenta);
                if(document.getElementById('ventas-select'))
                document.getElementById('ventas-select').value = ventas[0].idVenta;*/
            }else{
                //setIDVenta('')

                if(props.action=='Registrar')
                setPrimerPago(0);
                setValorCuotaInicial(0)
                setCantidadCuotas(0);
                setValorCuota(0)
            }
            

          
        }, [ventas])

        
        useEffect(()=>{
            if(totalAPagar!=''){
                setMontoPagado(primerPago);
                setSaldo(totalAPagar-primerPago)
            }

          
        }, [primerPago, totalAPagar, idPlan])

        useEffect(()=>{
            if(props.action=='Registrar'){
                setPrimerPago(0)
                if(document.getElementById('primer-pago'))
                document.getElementById('primer-pago').value = 0;
            }
            
          
        }, [idPlan])

        useEffect(()=>{
            if(detalles.length>0){
                calculos(detalles, idPlan, bonificacion);
            }else{
                setDeudaOriginal(0);
            }
            
          
        }, [detalles, idPlan, bonificacion])


        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
            if(props.element){
                setFecha(new Date(props.element.fecha));
                setVtoInicial(new Date(props.element.vtoInicial));
            }
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
                <div className="d-flex justify-content-between gap-3">
                    <div className="">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Fecha</label>
                            <DatePicker className="form-control" name="fecha"
                                selected={fecha} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>{setFecha(d);validateDate(d,'fecha','invalid-feedback-datepicker')}}
                                locale="es"
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                            />
                            <div className="invalid-feedback-datepicker">
                                Por favor ingrese la fecha.
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="d-flex py-2">
                            <label className="label">Vencimiento primer cuota</label>
                            <DatePicker className="form-control"
                                selected={vtoInicial} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>setVtoInicial(d)}
                                locale="es"
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                            />
                        </div>
                    </div>
                    <div className="">
                        {props.action!="Registrar"?
                            <div className="d-flex py-2">
                                <label className="label">Id Refinanciamiento</label>
                                <input className="form-control" name="idFinanciamiento" value={props.element?props.element.idRefinanciamiento:''} disabled></input>
                            </div>
                        :''}
                    </div>
                </div>
                <div className="p-2 border rounded">
                    <div className="d-flex justify-content-between gap-3">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Cliente</label>
                            {props.action=="Ver detalle"?
                             <input className="form-control" name="cliente" value={props.element?props.element.cliente.nombre:''} disabled></input>
                            :<>
                            <select name="cliente" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idCliente:''} onInput={(e) => {setIDCliente(e.target.value);validateInput(e.target.value, 'cliente')}} disabled={props.action=="Ver detalle"?true:false}>
                                <option value='' disabled={!props.element}>Seleccionar cliente</option>
                                {clientes?clientes.map((item,i)=>
                                    <option value={item.idCliente} key={'option'+i}>{item.nombre}</option>
                                ):''}
                            </select>
                            <div className="invalid-feedback">
                                Por favor seleccione un cliente.
                            </div>
                            </>
                            
                            }
                            
                        </div>
                        <div className="d-flex py-2 position-relative">
                            
                            <label className="label">Ventas</label>
                            <CreatableSelect
                                isMulti
                                isCreatable={false}
                                options={ventas?ventas.map(v=>{return{
                                    value:v.idVenta,
                                    label:"ID: "+v.idVenta
                                }}):[]}
                                placeholder="Selecciona o agrega ventas"
                                defaultValue={props.action!='Registrar'&&props.element?props.element.detalles.map(d=>{return{
                                    value:d.idVenta,
                                    label:"ID: "+d.idVenta
                                }}):[]}
                                disabled={props.action=="Ver detalle"?true:false}
                                onChange={(selectedOptions) => {
                                    // Manejar el cambio de opciones seleccionadas aquí si es necesario
                                    setDetalles(selectedOptions);
                                }}
                                onCreateOption={(inputValue) => {
                                }}
                                />
                        </div>
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Plan</label>
                            {props.action=="Ver detalle"?
                            <input className="form-control" name="plan" value={props.element?props.element.plan.descripcion:''} disabled></input>
                            :
                            <>
                            <select name="plan" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idPlan:''} onInput={(e) =>{setIDPlan(e.target.value);validateInput(e.target.value, 'plan')}} disabled={props.action=="Ver detalle"?true:false}>
                                <option value='' disabled={!props.element}>Seleccionar plan</option>
                                {planes?planes.map((item,i)=>
                                    <option value={item.idPlan} key={'option'+i}>{item.descripcion}</option>
                                ):''}
                            </select>
                            <div className="invalid-feedback">
                                Por favor ingrese un plan.
                            </div>
                            </>
                            
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="p-2 border rounded my-2 position-relative">
                    <div className="d-flex">
                            <label className="label">Descripción:</label>
                            <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=='Registrar'?false:true}></input>
                            <div className="invalid-feedback">
                            Por favor ingrese una descripción.
                            </div>
                    </div>
                    
                </div>
                
                <div className="d-flex justify-content-between gap-3 mt-2">
                    <div className="w-50 border rounded p-2">
                        <div className="d-flex py-2">
                            <label className="label">Deuda original</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="deudaOriginal" disabled value={props.action=='Registrar'?deudaOriginal:props.element?props.element.deudaOriginal:0} ></input>
                            </div>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">Bonificación:</label>
                            <div className="input-group">
                                 <input className="form-control" name="bonificacion" defaultValue={props.action=='Registrar'?0:props.element?props.element.bonificacion:0} onInput={(e) => setBonificacion(e.target.value)} disabled={props.action=='Registrar'?false:true}></input>
                                 <span className="input-group-text">%</span>
                            </div>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">Interés por plan</label>
                            <div className="input-group">
                            <input className="form-control" name="interes" disabled value={props.action=='Registrar'?interes:props.element?props.element.plan.porcentaje:''} ></input>
                            <span class="input-group-text">%</span>
                            </div>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">PFF</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="totalAPagar" value={props.action=='Registrar'?totalAPagar:props.element?props.element.totalAPagar:''} disabled></input>
                            </div>
                        </div>
                        {props.action!="Ver detalle"&&tienePagoInicial==1?
                        <div className="d-flex py-2">
                            <label className="label">Pago Inicial Esperado</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="valorCuotaInicial" value={props.action=='Registrar'?valorCuotaInicial:props.element&&props.element.plan.tienePagoInicial==1?props.element.valorCuotaInicial:null} disabled></input>
                            </div>
                        </div>:''
                        }
                        <div className="d-flex py-2">
                            <label className="label">Cantidad de cuotas</label>
                            <input className="form-control" name="cantidadCuotas" disabled value={props.action=='Registrar'?cantidadCuotas:props.element?props.element.plan.cantidadCuotas:''} ></input>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">Valor de cada cuota</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="valorCuota" disabled value={props.action=='Registrar'?valorCuota:props.element?props.element.valorCuota:''} ></input>
                            </div>
                        </div>
                    </div>
                    <div className="w-50 border rounded p-2">
                        <div className="d-flex py-2">
                            <label className="label">Primer pago</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input id="primer-pago" className="form-control" name="primerPago" defaultValue={props.action=='Registrar'?0:props.element?props.element.primerPago:0} onInput={(e) =>setPrimerPago(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                            </div>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">Monto Pagado</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="montoPagado" value={props.action=='Registrar'?montoPagado:props.element?props.element.montoPagado:''}  disabled></input>
                            </div>
                        </div>
                        {props.action!='Registrar'?
                        <div className="d-flex py-2">
                            <label className="label">Interés acumulado</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="interesAcumulado" value={props.element?props.element.interesAcumulado:''}  disabled></input>
                            </div>
                        </div>
                        :''}
                        <div className="d-flex py-2">
                            <label className="label">Saldo</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="saldo" value={props.action=='Registrar'?saldo:props.element?props.element.saldo:''} disabled></input>
                            </div>
                        </div>
                        <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={interesActivado==1?true:false} onInput={(e) => setInteresActivado(e.target.value==true?1:0)} disabled={props.action!='Registrar'?true:false}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                           Aplicar intereses automáticamente
                        </label>
                        </div>
                    </div>
                </div>
                
                
                {props.action=="Ver detalle"?
                <DetalleCuotas venta = {props.element}/>:''}
                {props.action=="Ver detalle"?
                <DetallePagosPorVenta venta = {props.element} setActualizar={props.setActualizar}/>:''}
                
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleRefinanciamiento;