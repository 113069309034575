import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { validateDate, validateInput, validateInputNum, validateInputMoney } from '../../helpers/validators';
import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';

function DetalleProducto(props) {

    let [proveedores, setProveedores] = useState([]);
    let [rubros, setRubros] = useState([]);
    let [margenes, setMargenes] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');
    let [precioContado, setPrecioContado] = useState(props.action=='Registrar'?'':props.element?props.element.precioContado:'');
    let [precioLista, setPrecioLista] = useState(props.action=='Registrar'?'':props.element?props.element.precioLista:'');
    let [costo, setCosto] = useState(props.action=='Registrar'?'':props.element?props.element.costo:'');
    let [stock, setStock] = useState(props.action=='Registrar'?'':props.element?props.element.stock:'');
    let [comisionMecanico, setComisionMecanico] = useState(props.action=='Registrar'?null:props.element?props.element.comisionMecanico:null);
    let [idProveedor, setIDProveedor] = useState(props.action=='Registrar'?'':props.element?props.element.proveedor.idProveedor:'');
    let [idRubro, setIdRubro] = useState(props.action=='Registrar'?'':props.element?props.element.proveedor.idRubro:'');

    //campos para calculo automatico de plista y pcontado
    let [porcentaje, setPorcentaje] = useState(null);
    let [descuentoContado, setDescuentoContado] = useState(null);
    let [personalizarPorcentajes, setPersonalizarPorcentajes] = useState(0);

      const updateProveedores=(data)=>{
        console.log(data.data);
        setProveedores(data.data);

    }

    const updateRubros=(data)=>{
        console.log(data.data);
        setRubros(data.data);

    }

    const updateMargenes=(data)=>{
        console.log(data.data);
        setMargenes(data.data);

    }

    const saveChanges=async ()=>{
        //validar
        let descripcionV = validateInput(descripcion, 'descripcion')
        let porcentajeV = validateInputMoney(porcentaje, 'porcentaje', 'producto-porcentaje-container')
        let descuentoContadoV = validateInputMoney(descuentoContado, 'descuentoContado', 'producto-descuento-container')
        let stockV = validateInputNum(stock, 'stock')
        let costoV = validateInputMoney(costo, 'costo', 'producto-costo-container')
        let proveedorV = validateInput(idProveedor, 'proveedor')
        let rubroV = validateInput(idRubro, 'rubro')
        if(descripcionV && porcentajeV && descuentoContadoV && stockV && costoV && proveedorV &&rubroV){
            let item = {
                idProducto: props.element?props.element.idProducto:null,
                descripcion:descripcion!=''?descripcion:props.element.descripcion,
                precioContado:precioContado!=''?precioContado:props.element.precioContado,
                precioLista:precioLista!=''?precioLista:props.element.precioLista,
                stock:stock!=''?stock:props.element.stock,
                costo:costo!=''?costo:props.element.costo,
                idProveedor:idProveedor!=''?idProveedor:props.element.idProveedor,
                idRubro:idRubro!=''?idRubro:props.element.idRubro,
                comisionMecanico:comisionMecanico!=null?comisionMecanico:props.element&&props.element.comisionMecanico?props.element.comisionMecanico:null
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/productos/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }else{
                
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/productos/update",
                    {
                      method: "PUT",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
              
              result = await result.json();
              let mensaje = props.action=="Registrar"?"registrado":"modificado";
              if(result.status==200){
                    Swal.fire('Producto '+mensaje, 'El producto ha sido '+mensaje+' con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                    document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
        }
        
          
          
    }

      useEffect(()=>{

        apiCall(process.env.REACT_APP_API_BASE_URL+"/proveedores/list/",updateProveedores);
        apiCall(process.env.REACT_APP_API_BASE_URL+"/rubros/list/",updateRubros);
        apiCall(process.env.REACT_APP_API_BASE_URL+"/margenes/list/",updateMargenes);
        //setear estados de form
        setDescripcion(props.element?props.element.descripcion:'');
        setPrecioContado(props.element?props.element.precioContado:'');
        setPrecioLista(props.element?props.element.precioLista:'');
        setCosto(props.element?props.element.costo:'');
        setStock(props.element?props.element.stock:'');
        setIDProveedor(props.element?props.element.proveedor.idProveedor:'');
        setIdRubro(props.element?props.element.idRubro:'');
        setComisionMecanico(props.element&&props.element.comisionMecanico?props.element.comisionMecanico:null);
        if(props.action=="Registrar"){
            setPersonalizarPorcentajes(0);
            setPorcentaje(null);
            setDescuentoContado(null);
        }

           


       
        }, [props.element, props.action]);

        useEffect(()=>{
            if(costo!='' && margenes.length>0){
                let porc = 0;
                let dto = 0;
                for(let margen of margenes){
                    let min = margen.valorMinimo? margen.valorMinimo : 0;
                    let max = margen.valorMaximo? margen.valorMaximo : 999999999999;
                    if((min<=costo)&&(costo<=max)){
                        porc = margen.porcentaje;
                        dto = margen.descuentoContado;
                    }
                }
                setPorcentaje(porc);
                setDescuentoContado(dto);
                if(props.action!="Ver detalle"){
                    validateInputMoney(porc,'porcentaje', 'producto-porcentaje-container')
                    validateInputMoney(dto,'descuentoContado', 'producto-descuento-container')
                }
                let plista = Math.round(parseInt(costo) + parseInt(costo)*porc/100);
                let pcontado = Math.round( plista - plista*dto/100 );
                setPrecioLista(plista)
                setPrecioContado(pcontado)
                
                
            }else{
                setPorcentaje(null);
                setDescuentoContado(null);
            }

            
        }, [costo, personalizarPorcentajes]);

        useEffect(()=>{
            if(porcentaje && descuentoContado){

                let plista = Math.round(parseInt(costo) + parseInt(costo)*porcentaje/100);
                let pcontado = Math.round( plista - plista*descuentoContado/100 );
                setPrecioLista(plista)
                setPrecioContado(pcontado)
            }
           
        }, [porcentaje, descuentoContado]);


        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Producto</label>
                    <input className="form-control" name="idProducto" value={props.element?props.element.idProducto:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descripción</label>
                    <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una descripción.
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Costo</label>
                        <div className="input-group ml-80-desktop position-relative" id="producto-costo-container">
                            <span className="input-group-text">$</span>
                            <input className="form-control" name="costo" defaultValue={props.action=='Registrar'?'':props.element?props.element.costo:''} onInput={(e) => {setCosto(e.target.value);validateInputMoney(e.target.value, 'costo', 'producto-costo-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                            <div className="invalid-feedback input-money-feedback">
                            Por favor ingrese el costo.
                            </div>
                        </div>
                        
                    </div>
                    {props.action!="Ver detalle"?
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckPorcentajes" defaultChecked={personalizarPorcentajes==1?true:false} onInput={(e) => {setPersonalizarPorcentajes(ant=>ant==1?0:1);console.log(e.target.value)}} disabled={props.action=='Ver detalle'?true:false}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckPorcentajes">
                        Personalizar porcentajes
                        </label>
                    </div>:''
                    }
                    
                </div>
                {
                    personalizarPorcentajes==1&&props.action!="Ver detalle"?
                    <div className="d-flex gap-3">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Porcentaje</label>
                            <div className="input-group position-relative ml-50-desktop" id="producto-porcentaje-container">
                            <input className="form-control" name="porcentaje" defaultValue={props.action=='Registrar'?null:porcentaje} onInput={(e) => {setPorcentaje(e.target.value);validateInputMoney(e.target.value, 'porcentaje', 'producto-porcentaje-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                            <span className="input-group-text">%</span>
                            <div className="invalid-feedback input-money-feedback">
                            Por favor ingrese un porcentaje.
                            </div>
                            </div>
                        </div>
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Descuento contado</label>
                            <div className="input-group position-relative" id="producto-descuento-container">
                            <input className="form-control" name="descuentoContado" defaultValue={props.action=='Registrar'?'':descuentoContado} onInput={(e) => {setDescuentoContado(e.target.value);validateInputMoney(e.target.value, 'descuentoContado', 'producto-descuento-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                            <span className="input-group-text">%</span>
                            <div className="invalid-feedback input-money-feedback">
                            Por favor ingrese un descuento.
                            </div>
                            </div>
                        </div>
                    </div>:props.action!="Ver detalle"?
                    <div className="d-flex gap-3">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Porcentaje</label>
                            <div className="input-group position-relative ml-50-desktop" id="producto-porcentaje-container">
                            <input className="form-control" name="porcentaje" value={porcentaje}  disabled></input>
                            <span className="input-group-text">%</span>
                            <div className="invalid-feedback input-money-feedback">
                            Por favor ingrese un porcentaje.
                            </div>
                            </div>
                        </div>
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Descuento contado</label>
                            <div className="input-group position-relative" id="producto-descuento-container">
                            <input className="form-control" name="descuentoContado" value={descuentoContado}  disabled></input>
                            <span className="input-group-text">%</span>
                            <div className="invalid-feedback">
                            Por favor ingrese un descuento.
                            </div>
                            </div>
                        </div>
                    </div>:''
                }
                    
                
                {props.action=="Ver detalle"?
                <>
                <div className="d-flex py-2">
                    <label className="label">Precio Lista</label>
                    <div className="input-group">
                        <span class="input-group-text">$</span>
                        <input className="form-control" name="precioLista" value={props.element?props.element.precioLista:0} disabled></input>
                    </div>
                </div>
                <div className="d-flex py-2">
                    <label className="label">Precio Contado</label>
                    <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input className="form-control" name="precioContado" value={props.element?props.element.precioContado:0} disabled></input>
                    </div>
                    
                </div>
                </>:
                <>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Precio Lista</label>
                    <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input className="form-control" name="precioLista" value={precioLista} disabled></input>
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Precio Contado</label>
                    <div className="input-group">
                        <span class="input-group-text">$</span>
                        <input className="form-control" name="precioContado" value={precioContado} disabled></input>
                    </div>
                </div>
                </>
                }
                
                <div className="d-flex py-2 position-relative">
                    <label className="label">Stock</label>
                    <input className="form-control" name="stock" defaultValue={props.action=='Registrar'?'':props.element?props.element.stock:''} onInput={(e) => {setStock(e.target.value);validateInputNum(e.target.value, 'stock')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese el stock actual.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Proveedor</label>
                    <select name="proveedor" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.proveedor.idProveedor:''} onInput={(e) => {setIDProveedor(e.target.value);validateInput(e.target.value, 'proveedor')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar proveedor</option>
                        {proveedores?proveedores.map((item,i)=>
                            <option value={item.idProveedor} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un proveedor.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Rubro</label>
                    <select name="rubro" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idRubro:''} onInput={(e) => {setIdRubro(e.target.value);validateInput(e.target.value, 'rubro')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar rubro</option>
                        {rubros?rubros.map((item,i)=>
                            <option value={item.idRubro} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un rubro.
                    </div>
                </div>
                {idRubro==1?
                <div className="d-flex py-2">
                    <label className="label">Comisión Mecánico</label>
                    <input className="form-control" name="comisionMecanico" defaultValue={props.action=='Registrar'?null:props.element&&props.element.comisionMecanico?props.element.comisionMecanico:''} onInput={(e) => setComisionMecanico(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                </div>:''}
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleProducto;