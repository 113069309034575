import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { validateDate, validateInput, validateInputNum, validateInputMoney } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';

import es from 'date-fns/locale/es';
registerLocale('es', es)

function DetallePago(props) {

    let [clientes, setClientes] = useState([]);
    let [ventas, setVentas] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):'');
    let [monto, setMonto] = useState(props.action=='Registrar'?'':props.element?props.element.monto:'');
    let [idCliente, setIDCliente] = useState(props.settedCliente?props.settedCliente:props.action=='Registrar'?'':props.element?props.element.idCliente:'');
    let [idVenta, setIDVenta] = useState(props.settedVenta?props.settedVenta:props.action=='Registrar'?'':props.element?props.element.idVenta:'');
    

      const updateVentas=(data)=>{
        console.log(data.data);
        setVentas(data.data);

    }

    const updateClientes=(data)=>{
      console.log(data.data);
      setClientes(data.data);

  }

    const saveChanges=async ()=>{
        //validar
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        let montoV = validateInputMoney(monto, 'monto', 'pago-monto-container')
        let ventaV = validateInput(idVenta, 'venta')
        let clienteV = validateInput(idCliente, 'cliente')
        if(fechaV &&  montoV && ventaV && clienteV){
            let item = {
                idPago: props.element?props.element.idPago:null,
                fecha:fecha!=''?fecha:props.element.fecha,
                monto:monto!=''?monto:props.element.monto,
                idVenta:idVenta!=''?idVenta:props.element.idVenta,
                idCliente:idCliente!=''?idCliente:props.element.idCliente
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/pagos/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
              
              result = await result.json();
              let mensaje = props.action=="Registrar"?"registrado":"modificado";
              if(result.status==200){
                    Swal.fire('Pago '+mensaje, 'El pago ha sido '+mensaje+' con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                     document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
        }
        
          
          
    }

      useEffect(()=>{
        //setear estados de form
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setMonto(props.element?props.element.monto:'');
        setIDCliente(props.settedCliente?props.settedCliente:props.element?props.element.idCliente:'');
        setIDVenta(props.settedVenta?props.settedVenta:props.element?props.element.idVenta:'');

        apiCall(process.env.REACT_APP_API_BASE_URL+"/clientes/list/",updateClientes);

       
        }, [props.action, props.element]);

        useEffect(()=>{
            if(idCliente)
               apiCall(process.env.REACT_APP_API_BASE_URL+"/ventas/list-por-cliente/"+idCliente,updateVentas);
           
            }, [idCliente]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Pago</label>
                    <input className="form-control" name="idPago" value={props.element?props.element.idPago:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Fecha</label>
                    <DatePicker className="form-control" name="fecha"
                        selected={props.action=='Registrar'?fecha:props.element?new Date(props.element.fecha+'T00:00:00'):''} disabled={props.action=="Ver detalle"?true:false}
                        onChange={(d)=>{setFecha(d);validateDate(d, 'fecha', 'invalid-feedback-datepicker')}}
                        locale="es"
                        dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                    />
                    <div className="invalid-feedback-datepicker">
                      Por favor ingrese una fecha.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Monto</label>
                    <div className="input-group position-relative" id="pago-monto-container">
                    <span className="input-group-text">$</span>
                    <input className="form-control" name="monto" defaultValue={props.action=='Registrar'?'':props.element?props.element.monto:''} onInput={(e) => {setMonto(e.target.value);validateInputMoney(e.target.value, 'monto', 'pago-monto-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un monto.
                    </div>
                    </div>
                </div>
                {props.settedCliente?
                    <input hidden name="cliente" className="form-control" value={props.settedCliente} disabled></input>:
                props.action=='Ver detalle'?
                <div className="d-flex py-2">
                    <label className="label">Cliente</label>
                    <input className="form-control" value={props.element.cliente.nombre} disabled></input>
                </div>:
                <div className="d-flex py-2 position-relative">
                    <label className="label">Cliente</label>
                    <select name="cliente" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idCliente:''} onInput={(e) => {setIDCliente(e.target.value);validateInput(e.target.value,'cliente')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar cliente</option>
                        {clientes?clientes.map((item,i)=>
                            <option value={item.idCliente} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un cliente.
                    </div>
                </div>
                }
                {props.settedVenta?
                    <input hidden name="venta" className="form-control" value={props.settedVenta} disabled></input>
                :
                props.action=='Ver detalle'?
                <div className="d-flex py-2">
                    <label className="label">Venta</label>
                    <input className="form-control" value={props.element.idVenta} disabled></input>
                </div>:
                <div className="d-flex py-2 position-relative">
                    <label className="label">Venta</label>
                    <select name="venta" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idVenta:''} onInput={(e) => {setIDVenta(e.target.value); validateInput(e.target.value, 'venta')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar venta</option>
                        {ventas?ventas.map((item,i)=>
                            <option value={item.idVenta} key={'option'+i}>{item.idVenta}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione una venta.
                    </div>
                </div>
                }
                
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetallePago;