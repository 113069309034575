import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    /*flexDirection: 'row',*/
    backgroundColor: '#ffffff',
    padding: 10,
    justifyContent:'flex-start'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
  },
  roundedSection: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
  },
  roundedSubsection: {
    marginTop:10,
    marginBottom:10,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
  },
  sectionFlex: {
    flexGrow: 1,
    padding:5,
    flexDirection: 'row',
    gap:10
  },
  sectionFlexColumn: {
    flexGrow: 1,
    padding:5,
    flexDirection: 'column',
    gap:10
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    textAlign: 'justify',
  },
  text45: {
    fontSize: 12,
    textAlign: 'justify',
    width: '45%'
  },
  image: {
    width:'100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    height: 24,
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    padding: 4,
  },
  footer: {
    fontSize: 14,
    textAlign: 'right',
    marginTop:'10px'
  }
});

const PDFComponentForVenta = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src="/images/banner.jpg" style={styles.image} />
        {/* Resto del contenido */}
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>{props.title} {props.item.idVenta}</Text>
        <Text style={styles.subtitle}>{new Date(props.item.fecha+'T00:00:00').toLocaleDateString()}</Text>
      </View>
      <View style={styles.roundedSection}>
        <View style={styles.sectionFlex}>
          <Text style={styles.text45}>Cliente: {props.item.cliente.nombre}</Text>
          <View style={styles.sectionFlexColumn}>
          <Text style={styles.text45}>Productos:</Text>
          {props.item.detalles.map((item,i)=>
                <Text style={styles.text}>{item.cantidad} {item.producto.descripcion}</Text>)}
          </View>   
        </View>
        <View style={styles.sectionFlex}>
          <Text style={styles.text45}>Plan: {props.item.plan.descripcion}</Text>
          <Text style={styles.text45}>Cantidad de cuotas: {props.item.plan.cantidadCuotas}</Text>
        </View>
        <View style={styles.sectionFlex}>
          <Text style={styles.text45}>Precio base: ${props.item.precioBase.toLocaleString()}</Text>
          <Text style={styles.text45}>Interés por plan: {props.item.plan.porcentaje}%</Text>
        </View>
        {props.item.cuotaVencida==1?
        <View style={styles.sectionFlex}>
          
            <Text style={styles.text45}>Opcion cuota vencida activada. Interés por cuota vencida: ${props.item.interesPorCuotaVencida.toLocaleString()}</Text>
        </View>:''
            }
        {props.item.anticipo?
        <View style={styles.roundedSubsection}>
            <View style={styles.sectionFlex}>
                <Text style={styles.text45}>Venta realizada con anticipo y resto financiado</Text>
            </View>
            <View style={styles.sectionFlex}>
              <Text style={styles.text45}>Anticipo: ${props.item.anticipo.toLocaleString()}</Text>
            </View>
        </View>
        :''
            }
        
        {props.item.valorCuotaInicial?
        <View style={styles.roundedSubsection}>
          <View style={styles.sectionFlex}>
            <Text style={styles.text}>Opcion con pago inicial activada</Text>
          </View>
          
          <View style={styles.sectionFlex}>
            <Text style={styles.text45}>Valor primer cuota: ${props.item.valorCuotaInicial.toLocaleString()}</Text>
            <Text style={styles.text45}>Valor cuotas restantes: ${props.item.valorCuota.toLocaleString()}</Text>
          </View>
          </View>
          :
          <View style={styles.sectionFlex}>
            <Text style={styles.text45}>Valor cuota: ${props.item.valorCuota.toLocaleString()}</Text>
          </View>
        }
        <View style={styles.sectionFlex}>
          <Text style={styles.text45}>Precio Final Financiado: ${props.item.precioFinal.toLocaleString()}</Text>
          <Text style={styles.text45}>Interés acumulado: ${props.item.interesAcumulado.toLocaleString()}</Text>
        </View>
        <View style={styles.sectionFlex}>
          <Text style={styles.text45}>Monto pagado: ${props.item.montoPagado.toLocaleString()}</Text>
          <Text style={styles.text45}>Saldo: ${props.item.saldo.toLocaleString()}</Text>
        </View>
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        {/* Puedes repetir esta estructura para cada fila de la tabla */}
        <Text style={styles.subtitle}>Cuotas:</Text>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>#</Text>
          <Text style={styles.tableCell}>Estado</Text>
          <Text style={styles.tableCell}>Vencimiento</Text>
          <Text style={styles.tableCell}>Monto</Text>
          <Text style={styles.tableCell}>Interés</Text>
          <Text style={styles.tableCell}>Total a pagar</Text>
          <Text style={styles.tableCell}>Pagado</Text>
          <Text style={styles.tableCell}>Saldo</Text>
        </View>
        {props.item.cuotas.map((cuota,i)=>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>{cuota.descripcion}</Text>
          <Text style={styles.tableCell}>{cuota.estado}</Text>
          <Text style={styles.tableCell}>{new Date(cuota.vencimiento+'T00:00:00').toLocaleDateString()}</Text>
          <Text style={styles.tableCell}>${cuota.monto.toLocaleString()}</Text>
          <Text style={styles.tableCell}>${cuota.interes.toLocaleString()}</Text>
          <Text style={styles.tableCell}>${cuota.totalAPagar.toLocaleString()}</Text>
          <Text style={styles.tableCell}>${cuota.pagado.toLocaleString()}</Text>
          <Text style={styles.tableCell}>${cuota.saldo.toLocaleString()}</Text>
        </View>)}
      </View>
      <View style={styles.section}>
        {/* Puedes repetir esta estructura para cada fila de la tabla */}
        <Text style={styles.subtitle}>Pagos realizados:</Text>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>#</Text>
          <Text style={styles.tableCell}>Fecha</Text>
          <Text style={styles.tableCell}>Descripción</Text>
          <Text style={styles.tableCell}>Monto</Text>
        </View>
        {props.item.pagos.map((pago,i)=>
        <View style={styles.tableRow}>
          <Text style={styles.tableCell}>{pago.idPago}</Text>
          <Text style={styles.tableCell}>{new Date(pago.fecha+'T00:00:00').toLocaleDateString()}</Text>
          <Text style={styles.tableCell}>{pago.descripcion}</Text>
          <Text style={styles.tableCell}>${pago.monto.toLocaleString()}</Text>
        </View>)}
      </View>
    </Page>
  </Document>
);

export default PDFComponentForVenta;