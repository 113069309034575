import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useForm } from 'react-hook-form';
import { validateDate, validateInput, validateInputNum } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';

function DetalleProveedor(props) {

  const { register, handleSubmit } = useForm();

    const [componentKey, setComponentKey] = useState(0);

    let [listaMunicipios, setListaMunicipios] = useState([]);
    let [listaLocalidades, setListaLocalidades] = useState([]);
    let [listaKeywords, setListaKeywords] = useState([]);
    //campos del form
     let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');
     let [direccion, setDireccion] = useState(props.action=='Registrar'?'':props.element?props.element.direccion:'');
     let [telefono, setTelefono] = useState(props.action=='Registrar'?'':props.element?props.element.telefono:'');
     let [municipio, setMunicipio] = useState(props.action=='Registrar'?'':props.element?props.element.municipio:'');
     let [localidad, setLocalidad] = useState(props.action=='Registrar'?'':props.element?props.element.localidad:'');
     let [keywords, setKeywords] = useState(props.action=='Registrar'?[]:props.element?props.element.keywords:[]);


    const updateListaMunicipios=(data)=>{
      console.log(data);
      data.municipios.sort((a, b) =>a.nombre.localeCompare(b.nombre));
      setListaMunicipios(data.municipios);

    }

    const updateListaLocalidades=(data)=>{
      console.log(data);
      data.localidades.sort((a, b) =>a.nombre.localeCompare(b.nombre));
      setListaLocalidades(data.localidades);
      document.getElementById('localidad').value = localidad;
    }

    const updateListaKeywords=(data)=>{
      console.log(data.data);
      let list = [];
      for(let k of data.data){
        let n = {
          value: k.idKeyword,
          label: k.palabra
        }
        list.push(n);
      }
      setListaKeywords(list);

    }

    useEffect(()=>{
          console.log(props.element)
          apiCall('https://apis.datos.gob.ar/georef/api/municipios?provincia=06&campos=id,nombre&max=200',updateListaMunicipios);
          if(props.action!='Registrar'){
            apiCall('https://apis.datos.gob.ar/georef/api/localidades?municipio='+props.element.municipio+'&campos=id,nombre&max=200',updateListaLocalidades);
        }
          apiCall(process.env.REACT_APP_API_BASE_URL+"/keywords/list",updateListaKeywords);
          /*
          const selectPicker = document.getElementById('mySelect');
          if (selectPicker) {
            $(selectPicker).selectpicker();
          }*/
          //setear estados de form
          setDescripcion(props.element?props.element.descripcion:'');
          setDireccion(props.element?props.element.direccion:'');
          setTelefono(props.element?props.element.telefono:'');
          setMunicipio(props.element?props.element.municipio:'');
          setLocalidad(props.element?props.element.localidad:'');
          props.element && console.log('id localidad: '+props.element.localidad);
          //seteo keywords con la estructura que necesita el select
          if(props.action!='Registrar'&&props.element){
            let list = [];
            for(let k of props.element.keywords){
              let n = {
                value: k.idKeyword,
                label: k.palabra
              }
              list.push(n);
            }
            setKeywords(list);
          }else{
              
            setKeywords([]);
          }


          
    }, [componentKey, props.element, props.action])


    useEffect(()=>{
      if(municipio){
          apiCall('https://apis.datos.gob.ar/georef/api/localidades?municipio='+municipio+'&campos=id,nombre&max=200',updateListaLocalidades);
      }
    }, [municipio])

    useEffect(()=>{
      if(listaLocalidades.length>0 && props.element){
          setLocalidad(props.element.localidad)
      }
    }, [listaLocalidades])

    const saveChanges=async ()=>{
        //validar
        let descripcionV = validateInput(descripcion, 'descripcion')
        let direccionV = validateInput(direccion, 'direccion')
        let telefonoV = validateInput(telefono, 'telefono')
        let municipioV = validateInput(municipio, 'municipio')
        let localidadV = validateInput(localidad, 'localidad')
        if(descripcionV && direccionV && telefonoV && municipioV && localidadV){
          let municipioNombre = listaMunicipios.find(mun=>mun.id==municipio).nombre;
          let localidadNombre = listaLocalidades.find(loc=>loc.id==localidad).nombre;
          let item = {
              idProveedor: props.element?props.element.idProveedor:null,
              descripcion:descripcion!=''?descripcion:props.element.descripcion,
              direccion:direccion!=''?direccion:props.element.direccion,
              telefono:telefono!=''?telefono:props.element.telefono,
              municipio:municipio!=''?municipio:props.element.municipio,
              localidad:localidad!=''?localidad:props.element.localidad,
              municipioNombre: municipioNombre,
              localidadNombre: localidadNombre,
              keywords:keywords!=[]?keywords:props.element?props.element.keywords:[],
            };
          console.log(item);
          let result;
          if(props.action=='Registrar'){
              result = await fetch(
                  process.env.REACT_APP_API_BASE_URL + "/proveedores/register",
                  {
                    method: "POST",
                    headers: getHeaders(),
                    body: JSON.stringify(item),
                  }
                );
          }else{
              
              result = await fetch(
                  process.env.REACT_APP_API_BASE_URL + "/proveedores/update",
                  {
                    method: "PUT",
                    headers: getHeaders(),
                    body: JSON.stringify(item),
                  }
                );
          }
            
            result = await result.json();
            let mensaje = props.action=="Registrar"?"registrado":"modificado";
            if(result.status==200){
                  Swal.fire('Proveedor '+mensaje, 'El Proveedor ha sido '+mensaje+' con éxito.', 'success');
                  props.setActualizar((val)=>val+1);
                  setComponentKey((prevKey) => prevKey + 1);
                   //ocultar popup
                   document.querySelector('#exampleModal .btn-close').click();
                   //actualizo keywords
                   apiCall(process.env.REACT_APP_API_BASE_URL+"/keywords/list",updateListaKeywords);
            }else{
                  Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
              }
            console.log(result);
        }
        
          
          
    }


        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);

            if(props.action!='Registrar'){
              apiCall('https://apis.datos.gob.ar/georef/api/localidades?municipio='+props.element.municipio+'&campos=id,nombre&max=200',updateListaLocalidades);
          }

          //seteo keywords con la estructura que necesita el select
          if(props.action!='Registrar'&&props.element){
            let list = [];
            for(let k of props.element.keywords){
              let n = {
                value: k.idKeyword,
                label: k.palabra
              }
              list.push(n);
            }
            setKeywords(list);
          }else{
              
            setKeywords([]);
          }
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Proveedor</label>
                    <input className="form-control" name="idProveedor" value={props.element?props.element.idProveedor:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descripción</label>
                    <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una descripción.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Dirección</label>
                    <input className="form-control" name="direccion" defaultValue={props.action=='Registrar'?'':props.element?props.element.direccion:''} onInput={(e) => {setDireccion(e.target.value);validateInput(e.target.value, 'direccion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una dirección.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Teléfono</label>
                    <input className="form-control" name="telefono" defaultValue={props.action=='Registrar'?'':props.element?props.element.telefono:''} onInput={(e) => {setTelefono(e.target.value);validateInput(e.target.value, 'telefono')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese un teléfono.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Municipio</label>
                    <select name="municipio" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.municipio:''} onInput={(e) => {setMunicipio(e.target.value);validateInput(e.target.value, 'municipio')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar municipio</option>
                        {listaMunicipios?listaMunicipios.map((item,i)=>
                            <option value={item.id} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un municipio.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Localidad</label>
                    <select name="localidad" id="localidad" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.localidad:''} onInput={(e) => {setLocalidad(e.target.value);validateInput(e.target.value, 'localidad')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar localidad</option>
                        {listaLocalidades?listaLocalidades.map((item,i)=>
                            <option value={item.id} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione una localidad.
                    </div>
                </div>

                <CreatableSelect
                  {...register('tags')}
                  isMulti
                  options={listaKeywords}
                  placeholder="Selecciona o agrega tags"
                  isCreatable
                  defaultValue={props.action!='Registrar'&&props.element?keywords:[]}
                  disabled={props.action=="Ver detalle"?true:false}
                  onChange={(selectedOptions) => {
                    // Manejar el cambio de opciones seleccionadas aquí si es necesario
                    setKeywords(selectedOptions);
                    console.log(selectedOptions);
                  }}
                />
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
      </>
  );
}

export default DetalleProveedor;