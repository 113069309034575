import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { validateDate, validateInput, validateInputNum, validateInputMoney } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';

import es from 'date-fns/locale/es';
registerLocale('es', es)

function DetallePagoAProveedor(props) {

    let [proveedores, setProveedores] = useState([]);
    let [compras, setCompras] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):'');
    let [monto, setMonto] = useState(props.action=='Registrar'?'':props.element?props.element.monto:'');
    let [idProveedor, setIDProveedor] = useState(props.action=='Registrar'&&props.settedProveedor?props.settedProveedor:props.action=='Registrar'?'':props.element?props.element.idProveedor:'');
    let [idCompra, setIDCompra] = useState(props.action=='Registrar'&&props.settedProveedor?props.settedProveedor:props.action=='Registrar'?'':props.element?props.element.idCompra:'');


      const updateCompras=(data)=>{
        console.log(data.data);
        setCompras(data.data);

    }

    const updateProveedores=(data)=>{
      console.log(data.data);
      setProveedores(data.data);

  }

    const saveChanges=async ()=>{
        //validar
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        let montoV = validateInputMoney(monto, 'monto', 'pago-monto-container')
        let compraV = validateInput(idCompra, 'compra')
        let proveedorV = validateInput(idProveedor, 'proveedor')
        if(fechaV, montoV, compraV, proveedorV){
            let item = {
                idPago: props.element?props.element.idPago:null,
                fecha:fecha!=''?fecha:props.element.fecha,
                monto:monto!=''?monto:props.element.monto,
                idCompra:idCompra!=''?idCompra:props.element.idCompra,
                idProveedor:idProveedor!=''?idProveedor:props.element.idProveedor
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/pagosaproveedores/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
              
              result = await result.json();
              let mensaje = props.action=="Registrar"?"registrado":"modificado";
              if(result.status==200){
                    Swal.fire('Pago '+mensaje, 'El pago ha sido '+mensaje+' con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                     document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
              
        }
        
          
    }

      useEffect(()=>{
        //setear estados de form
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setMonto(props.element?props.element.monto:'');
        setIDProveedor(props.element?props.element.idProveedor:props.settedProveedor?props.settedProveedor:'');
        setIDCompra(props.element?props.element.idCompra:props.settedCompra?props.settedCompra:'');

        apiCall(process.env.REACT_APP_API_BASE_URL+"/proveedores/list/",updateProveedores);

       
        }, []);

        useEffect(()=>{
            if(idProveedor)
               apiCall(process.env.REACT_APP_API_BASE_URL+"/compras/list-por-proveedor/"+idProveedor,updateCompras);
           
            }, [idProveedor]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Pago</label>
                    <input className="form-control" name="idPago" value={props.element?props.element.idPago:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Fecha</label>
                    <DatePicker className="form-control" name="fecha"
                        selected={props.action=='Registrar'?fecha:props.element?new Date(props.element.fecha+'T00:00:00'):''} disabled={props.action=="Ver detalle"?true:false}
                        onChange={(d)=>{setFecha(d);validateDate(d,'fecha','invalid-feedback-datepicker')}}
                        locale='es'
                        dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                    />
                    <div className="invalid-feedback-datepicker">
                      Por favor ingrese una fecha.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Monto</label>
                    <div className="input-group position-relative" id="pago-monto-container">
                    <span className="input-group-text">$</span>
                    <input className="form-control" name="monto" defaultValue={props.action=='Registrar'?'':props.element?props.element.monto:''} onInput={(e) => {setMonto(e.target.value);validateInputMoney(e.target.value,'monto', 'pago-monto-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un monto.
                    </div>
                    </div>
                </div>
                {props.settedProveedor?
                <input hidden name="proveedor" className="form-control" value={props.settedProveedor} disabled></input>
                :props.action=='Ver detalle'?
                <div className="d-flex py-2">
                    <label className="label">Proveedor</label>
                    <input className="form-control" value={props.element.proveedor.descripcion} disabled></input>
                </div>:
                <div className="d-flex py-2 position-relative">
                    <label className="label">Proveedor</label>
                    <select name="proveedor" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idProveedor:''} onInput={(e) => {setIDProveedor(e.target.value);validateInput(e.target.value,'proveedor')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar proveedor</option>
                        {proveedores?proveedores.map((item,i)=>
                            <option value={item.idProveedor} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un proveedor.
                    </div>
                </div>
                }
                {props.settedCompra?
                <input hidden name="compra" className="form-control" value={props.settedCompra} disabled></input>
                :
                props.action=='Ver detalle'?
                <div className="d-flex py-2">
                    <label className="label">Compra</label>
                    <input className="form-control" value={props.element.idCompra} disabled></input>
                </div>:
                <div className="d-flex py-2 position-relative">
                <label className="label">Compra</label>
                <select name="compra" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idCompra:''} onInput={(e) => {setIDCompra(e.target.value);validateInput(e.target.value,'compra')}} disabled={props.action=="Ver detalle"?true:false}>
                    <option value='' disabled={!props.element}>Seleccionar compra</option>
                    {compras?compras.map((item,i)=>
                        <option value={item.idCompra} key={'option'+i}>{item.idCompra}</option>
                    ):''}
                </select>
                <div className="invalid-feedback">
                      Por favor seleccione una compra.
                    </div>
            </div>
                }
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetallePagoAProveedor;