import '../../stylesheets/App.css';
//import '../../stylesheets/PopupRegister.css';
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import DetallePagoAProveedor from '../popups/DetallePagoAProveedor';


function DetallePagosPorCompra(props) {

    let [cargarNuevoPago, setCargarNuevoPago] = useState(false);
    return (
        <>
            <div className="accordion mt-3" id="accordionFlushPagos">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePagos" aria-expanded="false" aria-controls="flush-collapsePagos">
                        Ver pagos
                    </button>
                    </h2>
                    <div id="flush-collapsePagos" className="accordion-collapse collapse" data-bs-parent="#accordionFlushPagos">
                    <div className="accordion-body">
                        <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Monto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.compra.pagos?props.compra.pagos.map((item,i)=>
                                <tr key={'tr-'+i}>
                                <th scope="row"  key={'d-'+i}>{item.idPagoAProveedor}</th>
                                <td  key={'f-'+i}>{new Date(item.fecha+'T00:00:00').toLocaleDateString()}</td>
                                <td  key={'m-'+i}>$ {item.monto.toLocaleString()}</td>
                                </tr>
                                ):''}
                            </tbody>
                        </table>
                        </div>
                        <button type="button" className="btn btn-outline-primary" onClick={()=>setCargarNuevoPago(true)}>Registrar nuevo pago</button>
                        {cargarNuevoPago?
                        <div className="border rounded mt-3 p-2">
                            <DetallePagoAProveedor action="Registrar" settedProveedor={props.compra.idProveedor} settedCompra={props.compra.idCompra} setActualizar={props.setActualizar}/>
                        </div>:''}
                    </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DetallePagosPorCompra;