export function apiCall(url,consecuencia){
    let userInfo;
    if(localStorage.getItem("user-info")){
        userInfo = JSON.parse(localStorage.getItem("user-info"))
        const headers = new Headers({
            'Content-Type': 'application/json',
            'user-id': userInfo.idUsuario,
            'contrasena': userInfo.contrasena,
          });
        fetch(url, {headers})
        .then(response=>response.json())
        .then(data=> consecuencia(data))
        .catch(error=>console.log(error))
    }else{
        console.log('credenciales incorrectas')
    }
   
};

export function apiCallWithCalendar(url,consecuencia, calendar){
  let userInfo;
  if(localStorage.getItem("user-info")){
      userInfo = JSON.parse(localStorage.getItem("user-info"))
      const headers = new Headers({
          'Content-Type': 'application/json',
          'user-id': userInfo.idUsuario,
          'contrasena': userInfo.contrasena,
        });
      fetch(url, {headers})
      .then(response=>response.json())
      .then(data=> consecuencia(data, calendar))
      .catch(error=>console.log(error))
  }else{
      console.log('credenciales incorrectas')
  }
 
};

export function getHeaders(){
    let userInfo;
    let headers;
    if(localStorage.getItem("user-info")){
        userInfo = JSON.parse(localStorage.getItem("user-info"))
        headers = new Headers({
            'Content-Type': 'application/json',
            Accept: "application/json",
            'user-id': userInfo.idUsuario,
            'contrasena': userInfo.contrasena,
          })
          return headers;
    }else{
        headers = new Headers({
          'Content-Type': 'application/json',
          Accept: "application/json",
        })
    }
    return headers;

}