import '../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSackDollar, faCoffee, faMagnifyingGlass, faPencil, faTrash, faPrint, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiCall } from '../helpers/apiCallConfiguration';
import Popup from './popups/Popup';
import es from 'date-fns/locale/es';
registerLocale('es', es)
library.add(faSackDollar,faCoffee,faMagnifyingGlass, faPencil, faTrash, faPrint, faChevronLeft, faChevronRight); // Agrega los iconos que deseas utilizar



function MisTareas(props) {

    let [user, setUser] = useState(null);
    let [fecha, setFecha] = useState(new Date())

    let [tipos, setTipos] = useState([]);
    let [action, setAction] = useState('')
    let [element, setElement] = useState('')
    let [object, setObject] = useState('eventos')
    let [actualizar, setActualizar] = useState(0);


      const updateTipos=(data)=>{
        console.log(data.data);
        setTipos(data.data);

    }

    const updateStates = (action, item) => {
        setAction(action);
        setElement(item);
        setObject('eventos')
   }

    useEffect(()=>{
        let idUser = 0;
        let nombre = ''
        if(localStorage.getItem("user-info")){
            idUser = JSON.parse(localStorage.getItem("user-info")).idUsuario
            nombre = JSON.parse(localStorage.getItem("user-info")).nombre
            setUser({
                idUser:idUser, 
                nombre:nombre
            });
        }
        if(idUser!=0){
            apiCall(process.env.REACT_APP_API_BASE_URL+"/eventos/tipos/list-por-usuario/"+idUser,updateTipos);
        }
        
        }, [actualizar]);


  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
         <div className="container my-5 margin-mobile-5vw">
            <h1 className="general-subtitle text-center my-4">Tareas de usuario {user?user.nombre:''}</h1>
            <div className="col-12 m-auto border rounded border-dark-blue p-4">
                <div className="d-flex py-2">
                    <label className="label">Fecha</label>
                    <DatePicker className="form-control"
                        selected={fecha}
                        onChange={(d)=>setFecha(d)}
                        locale="es"
                        dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                    />
                </div>
                <div className="accordion accordion-flush mt-3" id="accordionMisTareas">
                    {tipos?tipos.map((t)=>(
                        <div className="accordion-item" key={t.idTipo}>
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse"+t.idTipo} aria-expanded="false" aria-controls={"flush-collapse"+t.idTipo}>
                                {t.descripcion}
                            </button>
                            </h2>
                            <div id={"flush-collapse"+t.idTipo} className="accordion-collapse collapse" data-bs-parent="#accordionMisTareas">
                            <div className="accordion-body">
                                <div className="table-responsive">
                                <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Hora</th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Estado de la tarea</th>
                                    <th scope="col">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {t.eventos?
                                    t.eventos.filter((item) => new Date(item.fecha+'T00:00:00').toLocaleDateString() == fecha.toLocaleDateString())
                                    .map((item,i)=>
                                    {

                                        return <tr key={'tr-'+i}>
                                        <th scope="row"  key={'d-'+i}>{item.idEvento}</th>
                                        <td  key={'f-'+i}>{new Date(item.fecha+'T00:00:00').toLocaleDateString()}</td>
                                        <td  key={'h-'+i}>{item.hora?item.hora:''}</td>
                                        <td  key={'des-'+i}>{item.descripcion?item.descripcion:''}</td>
                                        <td  key={'e-'+i}>{item.estadoDeLaTarea}</td>
                                        <td key={'a-'+i}><FontAwesomeIcon icon={faPencil}  className="mx-2 pointer" data-bs-toggle="modal" data-bs-target="#exampleModal"onClick={()=>updateStates('Editar', item)}/></td>
                                        </tr>
                                    }
                                    
                                    ):''}
                                </tbody>
                                </table>
                                </div>
                            </div>
                            </div>
                        </div>
                    )):''}
                </div>  
            </div>     
         </div>
         <Popup object={object} action={action} element={element} setActualizar={setActualizar}/>
                    
      </>
  );
}

export default MisTareas;