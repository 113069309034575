import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { validateDate, validateInput } from '../../helpers/validators';
import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';

import es from 'date-fns/locale/es';
registerLocale('es', es)

function DetalleEvento(props) {

    let [tipos, setTipos] = useState([]);
    let [usuarios, setUsuarios] = useState([]);
    let [compras, setCompras] = useState([]);
    let [ventas, setVentas] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
    let [hora, setHora] = useState(props.action=='Registrar'?'12:00':props.element?props.element.hora:'12:00');
    let [idTipo, setIdTipo] = useState(props.action=='Registrar'?'':props.element?props.element.idTipo:'');
    let [idUsuario, setIdUsuario] = useState(props.action=='Registrar'?'':props.element?props.element.idUsuario:'');
    let [estadoDeLaTarea, setEstadoDeLaTarea] = useState(props.action=='Registrar'?'Pendiente':props.element?props.element.estadoDeLaTarea:'Pendiente');
    let [horasInvertidas, setHorasInvertidas] = useState(props.action=='Registrar'?0:props.element?props.element.horasInvertidas:0);
    let [idCompra, setIdCompra] = useState(props.action=='Registrar'?null:props.element?props.element.idCompra:null);
    let [idVenta, setIdVenta] = useState(props.action=='Registrar'?null:props.element?props.element.idVenta:null);

      const updateTipos=(data)=>{
        console.log(data.data);
        setTipos(data.data);

    }

    const updateUsuarios=(data)=>{
        console.log(data.data);
        setUsuarios(data.data);

    }

    const updateCompras=(data)=>{
        console.log(data.data);
        setCompras(data.data);

    }

    const updateVentas=(data)=>{
        console.log(data.data);
        setVentas(data.data);

    }


    const saveChanges=async ()=>{
        //validar
        let descripcionV = validateInput(descripcion, 'descripcion')
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        let horaV = validateDate(hora, 'hora', 'invalid-feedback-timepicker')
        let tipoV = validateInput(idTipo, 'tipo')
        if(descripcionV && fechaV && horaV && tipoV){
            let item = {
                idEvento: props.element?props.element.idEvento:null,
                descripcion:descripcion!=''?descripcion:props.element.descripcion,
                fecha:fecha!=''?fecha:props.element.fecha,
                hora:hora!=''?hora:props.element.hora,
                idTipo:idTipo!=''?idTipo:props.element.idTipo,
                idUsuario:idUsuario,
                idCompra:idCompra,
                idVenta:idVenta,
                estadoDeLaTarea:estadoDeLaTarea!='Pendiente'?estadoDeLaTarea:props.element&&props.element.estadoDeLaTarea?props.element.estadoDeLaTarea:'Pendiente',
                horasInvertidas:horasInvertidas!=0?horasInvertidas:props.element&&props.element.horasInvertidas?props.element.horasInvertidas:0,
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/eventos/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }else{
                
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/eventos/update",
                    {
                      method: "PUT",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
              
              result = await result.json();
              let mensaje = props.action=="Registrar"?"registrado":"modificado";
              if(result.status==200){
                    Swal.fire('Evento '+mensaje, 'El Evento ha sido '+mensaje+' con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                     if(document.querySelector('#exampleModal .btn-close')){
                        document.querySelector('#exampleModal .btn-close').click();
                     }else if(document.querySelector('#modalPopupAgenda .btn-close')){
                        document.querySelector('#modalPopupAgenda .btn-close').click();
                     }
                   
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
        }
        
          
          
    }

      useEffect(()=>{

        apiCall(process.env.REACT_APP_API_BASE_URL+"/usuarios/list/",updateUsuarios);
        apiCall(process.env.REACT_APP_API_BASE_URL+"/eventos/tipos/list/",updateTipos);
        apiCall(process.env.REACT_APP_API_BASE_URL+"/ventas/list/",updateVentas);
        apiCall(process.env.REACT_APP_API_BASE_URL+"/compras/list/",updateCompras);
           
        //setear estados de form
        setDescripcion(props.element?props.element.descripcion:'');
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setHora(props.element?props.element.hora:'12:00');
        setIdTipo(props.element?props.element.idTipo:'');
        setIdUsuario(props.element?props.element.idUsuario:'');
        setIdCompra(props.element?props.element.idCompra:null);
        setIdVenta(props.element?props.element.idVenta:null);
        setEstadoDeLaTarea(props.element?props.element.estadoDeLaTarea:'Pendiente');
        setHorasInvertidas(props.element?props.element.horasInvertidas:0);

       
        }, [props.action, props.element]);


        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el Plan seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
            if(props.element){
                setFecha(new Date(props.element.fecha+'T00:00:00'));
            }
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey} style={props.object!="eventos"?{display:'none'}:{}}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Evento</label>
                    <input className="form-control" name="idEvento" value={props.element?props.element.idEvento:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descripción</label>
                    <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una descripción.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                            <label className="label">Fecha</label>
                            <DatePicker className="form-control"
                                name="fecha"
                                selected={fecha} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>{setFecha(d);validateDate(d, 'fecha', 'invalid-feedback-datepicker' )}}
                                locale="es"
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                            />
                            <div className="invalid-feedback-datepicker">
                            Por favor ingrese una fecha.
                            </div>
                        </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Hora</label>
                    <TimePicker className="form-control"
                        name="hora"
                        onChange={(h)=>{setHora(h);validateDate(h,'hora','invalid-feedback-timepicker')}} // Función para manejar el cambio de la hora seleccionada
                        value={hora} // Valor de la hora seleccionada
                        clearIcon={null} // Opción para eliminar el icono de limpieza
                        disabled={props.action=="Ver detalle"?true:false}
                    />
                    <div className="invalid-feedback-timepicker">
                            Por favor ingrese una hora.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Tipo de evento</label>
                    {props.action=="Ver detalle"?
                    <input  className="form-control" value={props.element?props.element.tipo.descripcion:''} disabled></input>
                    :<>
                    <select name="tipo" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idTipo:''} onInput={(e) =>{ setIdTipo(e.target.value);validateInput(e.target.value, 'tipo')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar tipo de evento</option>
                        {tipos?tipos.map((item,i)=>
                            <option value={item.idTipo} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                        Por favor seleccione un tipo de evento.
                    </div>
                    </>
                    
                    }
                    
                </div>
                <div className="d-flex py-2">
                    <label className="label">Usuario asignado</label>
                    {props.action=="Ver detalle"?
                    <input className="form-control" name="usuario" value={props.element?props.element.usuario.nombre:''} disabled></input>
                    :
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idUsuario:''} onInput={(e) => setIdUsuario(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar usuario asignado</option>
                        {usuarios?usuarios.map((item,i)=>
                            <option value={item.idUsuario} key={'option'+i}>{item.nombre}</option>
                        ):''}
                    </select>
                    }
                    
                </div>
                <div className="d-flex py-2">
                    <label className="label">Compra asociada (opcional)</label>
                    {props.action=="Ver detalle"?
                    <input className="form-control" name="compra" value={props.element?props.element.idCompra:''} disabled></input>
                    :
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idCompra:''} onInput={(e) => setIdCompra(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value=''>Seleccionar compra asociada</option>
                        {compras?compras.map((item,i)=>
                            <option value={item.idCompra} key={'option'+i}>{item.idCompra}</option>
                        ):''}
                    </select>
                    }
                    
                </div>
                <div className="d-flex py-2">
                    <label className="label">Venta asociada (opcional)</label>
                    {props.action=="Ver detalle"?
                    <input className="form-control" name="venta" value={props.element?props.element.idVenta:''} disabled></input>
                    :
                    <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idVenta:''} onInput={(e) => setIdVenta(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value=''>Seleccionar venta asociada</option>
                        {ventas?ventas.map((item,i)=>
                            <option value={item.idVenta} key={'option'+i}>{item.idVenta}</option>
                        ):''}
                    </select>
                    
                    }
                    
                </div>
                <div className="border rounded p-3 mt-3">
                    <h5>Información sobre la realización de la tarea</h5>
                    <div className="d-flex py-2">
                        <label className="label">Estado de la tarea</label>
                        {props.action=="Ver detalle"?
                        <input className="form-control" name="estado" value={props.element?props.element.estadoDeLaTarea:''} disabled></input>
                        :
                        <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'Pendiente':props.element?props.element.estadoDeLaTarea:'Pendiente'} onInput={(e) => setEstadoDeLaTarea(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                             <option value='Pendiente'>Pendiente</option>
                             <option value='En proceso'>En proceso</option>
                             <option value='Finalizada'>Finalizada</option>
                             <option value='En conflicto'>En conflicto</option>
                        </select>
                        }
                        
                    </div>
                    <div className="d-flex py-2">
                        <label className="label">Horas invertidas</label>
                        <input className="form-control" name="horasInvertidas" defaultValue={props.action=='Registrar'?0:props.element?props.element.horasInvertidas:0} onInput={(e) => setHorasInvertidas(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                    </div>
                </div>
            </div>   
        </div>
        <div className="modal-footer" style={props.object!="eventos"?{display:'none'}:{}}>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleEvento;