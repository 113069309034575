import React, {useState, useEffect} from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart, Area } from 'recharts'


const GraficoVentas = ({list}) => {

  let [data, setData] = useState([]);

  useEffect(()=>{
    let newList = []
    Object.keys(list).forEach(numeroSemana =>{
      let semana = list[numeroSemana];
      console.log('semana:')
      console.log(semana)
      let indicador = new Date(semana.fechaInicio).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })+" - "+new Date(semana.fechaFin).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric' })
      let item = {
        semana: indicador,
        venta: semana.venta,
        costo: semana.costo,
        ganancia: semana.venta - semana.costo
      }
      newList.push(item)
    })
    setData(newList)
}, [list]);

  return (
    <ResponsiveContainer width="100%" aspect={2}>
        {/*
        <BarChart 
            data={data}
            width={500}
            height={300}
            margin={{
                top:5,
                right:30,
                left:20,
                bottom:5
            }}
        >
        <CartesianGrid strokeDasharray="4 1 2" />    
        <XAxis dataKey="semana"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="venta" fill="#6b48ff"/>
        <Bar dataKey="costo" fill="#1ee3cf"/>
        <Bar dataKey="ganancia" fill="#d4afb9"/>
        </BarChart>*/}
        <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="semana" />
            <YAxis />
            <Tooltip />
            <Area
                type="monotone"
                dataKey="costo"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
            />
            <Area
                type="monotone"
                dataKey="venta"
                stackId="1"
                stroke="#82ca9d"
                fill="#82ca9d"
            />
            <Area
                type="monotone"
                dataKey="ganancia"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
            />
        </AreaChart>
    </ResponsiveContainer>
  )
}

export default GraficoVentas