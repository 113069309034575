import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DetallePagosPorCompra from '../subcomponents/DetallePagosPorCompra';

import { validateDate, validateInput,validateInputNum, validateInputMoney } from '../../helpers/validators';
import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';

import es from 'date-fns/locale/es';
registerLocale('es', es)

function DetalleCompra(props) {

    let [proveedores, setProveedores] = useState([]);
    let [productos, setProductos] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?props.element.fecha:'');
    let [idProveedor, setIDProveedor] = useState(props.action=='Registrar'?'':props.element?props.element.idProveedor:'');
    let [total, setTotal] = useState(props.action=='Registrar'?0:props.element?props.element.total:0);
    let [pagado, setPagado] = useState(props.action=='Registrar'?'':props.element?props.element.pagado:'');
    let [saldo, setSaldo] = useState(props.action=='Registrar'?0:props.element?props.element.saldo:0);
     let [detalleCompra, setDetalleCompra] = useState(props.action=='Registrar'?[]:props.element?props.element.detalles:[]);

    //campos de cada item a agregar
    let [idProducto, setIDProducto] = useState(null);
    let [cantidad, setCantidad] = useState(1);


    const updateProveedores=(data)=>{
      console.log(data.data);
      setProveedores(data.data);


    }

    const updateProductos=(data)=>{
        console.log(data.data);
        setProductos(data.data);
        //actualizo idproducto al primero de la lista
        data.data.length>0&&setIDProducto(data.data[0].idProducto);
  
    }

    const agregarDetalleCompra = async () => {
        if(idProducto && cantidad){
            let prod = productos.find(p=>p.idProducto == idProducto);
            console.log(prod)
            if(prod){
                let nuevo = {
                    cantidad: cantidad,
                    idProducto: idProducto,
                    producto:{
                        descripcion: prod.descripcion,
                    },
                    costoUnitario: prod.costo,
                    subtotal: prod.costo*cantidad
                }
                console.log('agregar producto '+idProducto+'cantidad '+cantidad);
                setDetalleCompra([...detalleCompra, nuevo])
                setTotal(t=>t+nuevo.subtotal);
                setSaldo(t=>t+nuevo.subtotal);
            }
            
            
        }else{
            Swal.fire('Error', 'Por favor seleccione un producto y una cantidad validas', 'warning');
        }
         
    }

    const validateListado = () => {
        return detalleCompra.length>0
    }

    const saveChanges=async ()=>{
        //validar
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        let proveedorV = validateInput(idProveedor, 'proveedor')
        let pagadoV = validateInputMoney(pagado, 'pagado', 'compra-pagado-container')
        let detalleV = validateListado();
        if(fechaV && proveedorV && total && pagadoV && detalleV){
            let item = {
                fecha:fecha!=''?fecha:props.element.fecha,
                idProveedor:idProveedor!=''?idProveedor:props.element.idProveedor,
                total:total!=''?total:props.element.total,
                pagado:pagado!=''?pagado:props.element.pagado,
                saldo:saldo!=''?saldo:props.element?props.element.saldo:0,
                estado:1,
                detalleCompra: detalleCompra!=''?detalleCompra:props.element.detalleCompra,
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/compras/register",
                    {
                      method: "POST",
                      headers:getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
    
              result = await result.json();
              //falta registrar detalle compras
              
              if(result.status==200){
                    Swal.fire('Compra registrada', 'La compra ha sido registrada con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                     document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
        }else{
            console.log('faltan datos');
            if(detalleV==false)
            Swal.fire('Error', 'Debe agregar al menos un producto a la compra para poder guardarla', 'warning');
        }
        
          
          
    }

      useEffect(()=>{
        //setear estados de form
        setFecha(props.element?props.element.fecha:new Date());
        setIDProveedor(props.element?props.element.idProveedor:'');
        setTotal(props.element?props.element.total:0);
        setPagado(props.element?props.element.pagado:'');
        setSaldo(props.element?props.element.saldo:0);
        setDetalleCompra(props.element?props.element.detalleCompra:[]);

        /*compruebo si es admin y si esta logueado*/
        /*
        if(!localStorage.getItem("user-info")){
           navigate("/login");
       }else*/
           apiCall(process.env.REACT_APP_API_BASE_URL+"/proveedores/list/",updateProveedores);
           //ocultar buscador
           //document.getElementById("search-container").style.visibility= 'hidden';

       
        }, [props.action, props.element]);

        useEffect(()=>{
            if(idProveedor)
               apiCall(process.env.REACT_APP_API_BASE_URL+"/productos/list-por-proveedor/"+idProveedor,updateProductos);
           
            }, [idProveedor]);

        useEffect(()=>{
            if(productos.length>0)
                setIDProducto(productos[0].idProducto);
            
            }, [productos]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
            if(props.element){
                setFecha(new Date(props.element.fecha));
                setDetalleCompra(props.element.detalles);
            }
            
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
                <div className="d-flex gap-3 border rounded p-2 mb-3">
                    {props.action!="Registrar"?
                        <div className="d-flex py-2">
                            <label className="label">Id Compra</label>
                            <input className="form-control" name="idCompra" value={props.element?props.element.idCompra:''} disabled></input>
                        </div>
                    :''}
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Fecha</label>
                        <DatePicker name="fecha"
                                    className="form-control"
                                    selected={fecha} disabled={props.action=="Ver detalle"?true:false}
                                    onChange={(d)=>{setFecha(d);validateDate(d, 'fecha', 'invalid-feedback-datepicker')}}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                /> 
                         <div className="invalid-feedback-datepicker">
                            Por favor ingrese una fecha.
                            </div>
                    </div>
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Proveedor</label>
                        <select name="proveedor" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idProveedor:''} onInput={(e) => {setIDProveedor(e.target.value);validateInput(e.target.value, 'proveedor')}} disabled={props.action=="Ver detalle"||detalleCompra.length>0?true:false}>
                            <option value='' disabled={!props.element}>Seleccionar proveedor</option>
                            {proveedores?proveedores.map((item,i)=>
                                <option value={item.idProveedor} key={'option'+i}>{item.descripcion}</option>
                            ):''}
                        </select>
                        <div className="invalid-feedback">
                        Por favor seleccione un proveedor.
                        </div>
                    </div>
                </div>
                {props.action=="Registrar"?
                <div className="d-flex border-bottom justify-content-center gap-3">
                    <div className="d-flex py-2">
                        <label className="label">Agregar item: </label>
                        <select className="form-select" aria-label="Default select example" onInput={(e) => setIDProducto(e.target.value)} defaultValue={productos.length>0?productos[0].idProducto:null}>
                            <option value='' disabled={!props.element}>Seleccionar producto</option>
                            {productos?productos.map((item,i)=>
                                <option value={item.idProducto} key={'option'+i}>{item.descripcion}</option>
                            ):''}
                        </select>
                    </div>
                    <div className="py-2 d-flex always-flex">
                        <button type="button" className="btn btn-outline-warning" onClick={(e)=>{document.getElementById('cantidad').value=cantidad>1?cantidad-1:1;setCantidad(c=>c>1?c-1:1)}}>-</button>
                        <input className="form-control" name="cantidad" id="cantidad" defaultValue={cantidad} onInput={(e) => setCantidad(e.target.value)}></input>
                        <button type="button" className="btn btn-outline-warning" onClick={(e)=>{document.getElementById('cantidad').value=cantidad+1;setCantidad(c=>c+1)}}>+</button>
                    </div>  
                    <div className="py-2">
                        <button type="button" className="btn btn-outline-success" onClick={(e) => agregarDetalleCompra()}>Agregar</button>
                    </div> 
                </div>
                :''}
                {detalleCompra.length>0?
                <div className="border rounded mt-3 p-2">
                    <div className="d-flex border-bottom">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">ID producto</th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Costo unitario</th>
                                    <th scope="col">Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detalleCompra.map((item,i)=>
                                    <tr key={"tr-"+i}>
                                        <th scope="row" key={"th-"+i}>{item.idProducto}</th>
                                        <td key={"d-"+i}>{item.producto.descripcion}</td>
                                        <td key={"c-"+i}>{item.cantidad}</td>
                                        <td key={"co-"+i}>$ {item.costoUnitario.toLocaleString()}</td>
                                        <td key={"s-"+i}>$ {item.subtotal.toLocaleString()}</td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                </div>
                :''}
                <div className='w-50 mt-3 ml-50'>
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Total</label>
                        <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input className="form-control" name="total" disabled value={props.action=='Registrar'?total:props.element?props.element.total:''} ></input>
                        </div>
                    </div>
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Pagado</label>
                        <div class="input-group position-relative" id="compra-pagado-container">
                            <span class="input-group-text">$</span>
                            <input className="form-control" name="pagado" defaultValue={props.action=='Registrar'?'':props.element?props.element.pagado:''} onInput={(e) =>{ setPagado(e.target.value); setSaldo(total?total - e.target.value:'');validateInputMoney(e.target.value, 'pagado', 'compra-pagado-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                            <div className="invalid-feedback input-money-feedback">
                            Por favor ingrese un valor.
                            </div>
                        </div>
                                
                     </div>
                    <div className="d-flex py-2 position-relative">
                        <label className="label">Saldo</label>
                        <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input className="form-control" name="saldo" disabled value={props.action=='Registrar'?saldo:props.element?props.element.saldo:''} ></input>
                        </div>
                    </div>
                </div>
                {props.action=="Ver detalle"?
                <DetallePagosPorCompra compra = {props.element} setActualizar={props.setActualizar}/>:''}
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleCompra;