import '../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React from 'react';
import {Link} from 'react-router-dom';


function Respaldos() {

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
         <h1>Respaldos</h1>
      </>
  );
}

export default Respaldos;