import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useKey, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PDFContainer from './PDFContainer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faPrint); // Agrega los iconos que deseas utilizar


function ImpresionComponent(props) {


   return (
    <>
        <div className="d-flex justify-content-between my-4 border rounded p-3">
            {props.operacion == 'Presupuesto Varios Planes'?
            <a href="" className="btn rounded-pill btn-dark d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faPrint} />
            <PDFContainer pdfType='presupuestoVariosPlanes' item={props.item} planes={props.planes}
            productos={props.productos} cantidades={props.cantidades} />
            </a>
            :props.operacion=='venta'&&props.item?
            <>
            <a href="" className="btn rounded-pill btn-dark d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faPrint} />
            <PDFContainer pdfType='ventaClienteParaCompletar' item={props.item} />
            </a>
            <a href="" className="btn rounded-pill btn-dark d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faPrint} />
            <PDFContainer pdfType='ventaClienteActualizada' item={props.item} />
            </a>
            <a href="" className="btn rounded-pill btn-dark d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faPrint} />
            <PDFContainer pdfType='ventaAdmin' item={props.item} />
            </a> 
            </>:props.operacion=='presupuesto'&&props.item&&props.productos&&props.plan?
            <>
            <a href="" className="btn rounded-pill btn-dark d-flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faPrint} />
            <PDFContainer pdfType='presupuesto' item={props.item} cliente={props.cliente} plan={props.plan} productos={props.productos} cantidades={props.cantidades}/>
            </a>  
            </>:''}
        </div>
                
                
      </>
  );
}

export default ImpresionComponent;