 import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DetalleCuotas from '../subcomponents/DetalleCuotas';
import DetallePagosPorVenta from '../subcomponents/DetallePagosPorVenta';
import PopupImpresion from './PopupImpresion';
import ImpresionComponent from '../pdfs/impresionComponent';
import PDFContainer from '../pdfs/PDFContainer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateDate, validateInput, validateInputNum } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';
import { redondearA100 } from '../../helpers/calculos';

import es from 'date-fns/locale/es';
registerLocale('es', es)

library.add(faPrint); // Agrega los iconos que deseas utilizar

function DetalleVenta(props) {

    //estados para presupuesto
    let [itemParaPresupuesto, setItemParaPresupuesto] = useState(null);
    let [productosParaPresupuesto, setProductosParaPresupuesto] = useState(null);
    let [cantidadesParaPresupuesto, setCantidadesParaPresupuesto] = useState(null);
    let [planParaPresupuesto, setPlanParaPresupuesto] = useState(null);
    let [clienteParaPresupuesto, setClienteParaPresupuesto] = useState(null);
    let [presupuestoOK, setPresupuestoOK] = useState(false)


    let [productos, setProductos] = useState([]);
    let [clientes, setClientes] = useState([]);
    let [planes, setPlanes] = useState([]);
    //campos para venta de baterias
    let [mecanicos, setMecanicos] = useState([]);
    let [porcentajeBateriaUsada, setPorcentajeBateriaUsada] = useState(0);
    let [esVentaBateria, setEsVentaBateria] = useState(props.element?props.element.esVentaBateria:0);
    let [entregaBateria, setEntregaBateria] = useState(props.action=='Registrar'?0:props.element?props.element.entregaBateria:0);

    //indicador de si el plan tiene Pago Iniciar en su modalidad
    let [tienePagoInicial, setTienePagoInicial] = useState(props.action=='Registrar'?0:props.element?props.element.plan.tienePagoInicial:0);

    //campo exclusivo para planes con Pago Inicial
    let [valorCuotaInicial, setValorCuotaInicial] = useState(props.action=='Registrar'?null:props.element&&props.element.plan.tienePagoInicial==1?props.element.valorCuotaInicial:'');

    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [fecha, setFecha] = useState(props.action=='Registrar'?new Date():props.element?new Date(props.element.fecha+'T00:00:00'):'');
    let [precioFinal, setPrecioFinal] = useState(props.action=='Registrar'?'':props.element?props.element.precioFinal:'');
    let [anticipo, setAnticipo] = useState(props.action=='Registrar'?0:props.element?props.element.anticipo:0);
    let [primerPago, setPrimerPago] = useState(props.action=='Registrar'?0:props.element?props.element.primerPago:0);
    let [montoPagado, setMontoPagado] = useState(props.action=='Registrar'?0:props.element?props.element.montoPagado:'');
    let [saldo, setSaldo] = useState(props.action=='Registrar'?0:props.element?props.element.saldo:0);
    /*let [idProducto, setIDProducto] = useState(props.action=='Registrar'?'':props.element?props.element.idProducto:'');*/
    let [idCliente, setIDCliente] = useState(props.action=='Registrar'?'':props.element?props.element.idCliente:'');
    let [idPlan, setIDPlan] = useState(props.action=='Registrar'?'':props.element?props.element.idPlan:'');
    let [idMecanico, setIDMecanico] = useState(props.action=='Registrar'?'':props.element?props.element.idMecanico:'');
    let [interesActivado, setInteresActivado] = useState(props.action=='Registrar'?1:props.element?props.element.interesActivado:'');
    let [cuotaVencida, setCuotaVencida] = useState(props.action=='Registrar'?0:props.element?props.element.cuotaVencida:0);
    let [interesPorCuotaVencida, setInteresPorCuotaVencida] = useState(props.action=='Registrar'?1:props.element?props.element.interesPorCuotaVencida:'');
    let [porcentajePorCuotaVencida, setPorcentajePorCuotaVencida] = useState(0);
    let [vtoInicial, setVtoInicial] = useState(props.action=="Registrar"?new Date():props.element?new Date(props.element.vtoInicial+'T00:00:00'):'');
    //campos del form q se autocompletan
    let [precioBase, setPrecioBase] = useState(props.action=='Registrar'?0:props.element&&props.element.precioBase?props.element.precioBase:0);
    let [interes, setInteres] = useState(props.action=='Registrar'?'':props.element?props.element.plan.porcentaje:'');
    let [valorCuota, setValorCuota] = useState(props.action=='Registrar'?'':props.element?props.element.valorCuota:'');
    let [cantidadCuotas, setCantidadCuotas] = useState(props.action=='Registrar'?'':props.element?props.element.plan.cantidadCuotas:'');
    let [bonificacionAcumulada, setBonificacionAcumulada] = useState(0);
    let [detalleVenta, setDetalleVenta] = useState(props.action=='Registrar'?[]:props.element?props.element.detalles:[]);

    //campos de cada item a agregar
    let [idProducto, setIDProducto] = useState(null);
    let [cantidad, setCantidad] = useState(1);

      const updateClientes=(data)=>{
        console.log(data.data);
        setClientes(data.data);

    }

    const updateProductos=(data)=>{
      console.log(data.data);
      setProductos(data.data);
      setIDProducto(data.data[0].idProducto);
    }

    const updatePlanes=(data)=>{
        console.log(data.data);
        setPlanes(data.data);
  
    }

    const updateMecanicos=(data)=>{
        console.log(data.data);
        setMecanicos(data.data);
  
    }

    const updatePorcentajeBateriaUsada=(data)=>{
        console.log(data.data);
        setPorcentajeBateriaUsada(data.data.porcentajeBateriaUsada);
  
    }


    //funcion general que hace los calculos cada vez que se la llama
    //funcion que corre cuando cambia el producto seleccionado
    const calculosVenta = (precioBaseL, detalleVentaL, planL, anticipoL, esVentaBateriaL, entregaBateriaL, porcentajeBateriaUsadaL, porcentajePorCuotaVencidaL, cuotaVencidaL, bonificacionAcumuladaL) =>{
        if(detalleVentaL.length>0 && planL && props.action == 'Registrar'){

            let pfinal;
            let subtotal;
            if(cuotaVencidaL==1){
                console.log("calculo incluyendo ipcv")
                let iPCV = (precioBaseL-anticipoL)*porcentajePorCuotaVencidaL/100;
                subtotal = (precioBaseL-anticipoL) + iPCV
                setInteresPorCuotaVencida(iPCV);
                

            }else{
                subtotal = precioBaseL - anticipoL;  
            }
            pfinal = Math.round(subtotal+subtotal*planL.porcentaje/100 - bonificacionAcumuladaL);

            
            //logica que considera si hay pago inicial
            let c;
            let cf;
            let cRound;
            let cfRound;
            if(planL.tienePagoInicial==1){
                cf = pfinal/planL.plazo
                if(planL.idModalidad==1){
                    c = cf/4;
                }else if(planL.idModalidad==2){
                    c = cf/2;
                }else{
                    c = cf;
                }
                cRound = redondearA100(c);
                cfRound = redondearA100(cf);
                setValorCuota(cRound);
                setValorCuotaInicial(cfRound);
                
            }else{
                c=pfinal/planL.cantidadCuotas;
                cRound= redondearA100(c);
                setValorCuota(cRound);
            }
            setPrecioFinal(pfinal);
            if(primerPago){
                setSaldo(pfinal-primerPago)
            }
            
                
        }
        
    }

    const agregarDetalleVenta = async () => {
        if(idProducto && cantidad){
            let prod = productos.find(p=>p.idProducto == idProducto);
            let plan = productos.find(p=>p.idPlan == idPlan);
            let precio = prod.precioLista;
            //si es contado va precio contado
            if(plan&&plan.idModalidad==4){
                    precio = prod.precioContado;
            }
            //si es venta bateria y no entrega bateria va el recargo
            if(prod.idRubro==1 & entregaBateria==0){
                let recargoPorNoEntregar = precio * porcentajeBateriaUsada / 100;
                precio+=recargoPorNoEntregar
                console.log('recargo: '+recargoPorNoEntregar)
                console.log('porcentaje: '+porcentajeBateriaUsada)
            }
            console.log(prod)
            if(prod){
                let nuevo = {
                    cantidad: cantidad,
                    idProducto: idProducto,
                    producto:{
                        descripcion: prod.descripcion,
                    },
                    costo: prod.costo,
                    precio: precio,
                    subtotal: precio*cantidad
                }
                console.log('agregar producto '+idProducto+'cantidad '+cantidad);
                
                setDetalleVenta([...detalleVenta, nuevo])
                setPrecioBase(t=>parseFloat(t)+parseFloat(nuevo.subtotal));
            }
            
            
        }else{
            Swal.fire('Error', 'Por favor seleccione un producto y una cantidad validas', 'warning');
        }
         
    }
    
    useEffect(()=>{
        if(productos.length>0 && idProducto){
            let prod = productos.find(p=>p.idProducto == idProducto);
            let esVB = 0;
            let entB = 0;
            if(prod.idRubro==1){
                esVB = 1;
                entB = 1;
            }else{
                esVB = 0;
                entB = 0;
            }
            setEsVentaBateria(esVB);
            setEntregaBateria(entB);
            /*
            if(planes.length>0 && idPlan){
                let pl = planes.find(p=>p.idPlan == idPlan);
                calculosVenta(precioBase, detalleVenta, pl, anticipo, esVB, entB, porcentajeBateriaUsada, porcentajePorCuotaVencida, cuotaVencida, bonificacionAcumulada);
            }*/
            
        }
        
    }, [idProducto])

    useEffect(()=>{
        if(planes.length>0 && idPlan){
            
            let pl = planes.find(p=>p.idPlan == idPlan);
            //modifico estado de interes
            setInteres(pl.porcentaje);
            setCantidadCuotas(pl.cantidadCuotas);
            setTienePagoInicial(pl.tienePagoInicial);
            //seteo valorcuotainicial
            setValorCuotaInicial(null)

            if(detalleVenta.length>0){
                let pBase = 0;
                detalleVenta.forEach(d=>{
                    let prod = productos.find(p=>p.idProducto == d.idProducto);
                    if(pl&&pl.idModalidad==4){
                        d.precio = prod.precioContado;
                    }
                    d.subtotal = d.precio * d.cantidad;
                    pBase+=d.subtotal;
                })
                setPrecioBase(pBase)
                calculosVenta(pBase,detalleVenta, pl, anticipo, esVentaBateria, entregaBateria, porcentajeBateriaUsada, porcentajePorCuotaVencida, cuotaVencida, bonificacionAcumulada);
            }
            
        }
        
    }, [idPlan, detalleVenta])

    useEffect(()=>{
        if(productos.length>0 && planes.length>0 && detalleVenta.length>0 && idPlan){
            let pl = planes.find(p=>p.idPlan == idPlan);
            calculosVenta(precioBase,detalleVenta, pl, anticipo, esVentaBateria, entregaBateria, porcentajeBateriaUsada, porcentajePorCuotaVencida, cuotaVencida, bonificacionAcumulada);
        }
        
    }, [porcentajePorCuotaVencida, anticipo, cuotaVencida, entregaBateria])

    useEffect(()=>{
        if(precioFinal!=''){
            setMontoPagado(primerPago);
            setSaldo(precioFinal-primerPago)
        }
        
    }, [primerPago])

    useEffect(()=>{
        if(idCliente){
            let cliente = clientes.find(cliente => cliente.idCliente == idCliente);
            console.log(cliente);
            if(cliente){
                setBonificacionAcumulada(cliente.bonificacionAcumulada);
                if(productos.length>0 && planes.length>0 && detalleVenta.length>0 && idPlan){
                    let pl = planes.find(p=>p.idPlan == idPlan);
                    if(props.action!='Ver detalle')
                    calculosVenta(precioBase,detalleVenta, pl, anticipo, esVentaBateria, entregaBateria, porcentajeBateriaUsada, porcentajePorCuotaVencida, cuotaVencida, cliente.bonificacionAcumulada);
                }
            }
            
        }
        
      
    }, [idCliente])



    const saveChanges=async ()=>{
        //validar
        let fechaV = validateDate(fecha, 'fecha', 'invalid-feedback-datepicker')
        let clienteV = validateInputNum(idCliente, 'cliente')
        let planV = validateInputNum(idPlan, 'plan')
        if(fechaV && detalleVenta.length>0 && clienteV && planV){
            let item = {
                fecha:fecha!=''?fecha:props.element.fecha,
                precioBase:precioBase!=''?precioBase:0,
                precioFinal:precioFinal!=''?precioFinal:props.element.precioFinal,
                anticipo:anticipo!=''?anticipo:props.element?props.element.anticipo:0,
                primerPago:primerPago!=''?primerPago:props.element?props.element.primerPago:0,
                montoPagado:montoPagado!=''?montoPagado:props.element?props.element.montoPagado:0,
                saldo:saldo!=''?saldo:props.element?props.element.saldo:0,
                idCliente:idCliente!=''?idCliente:props.element.idCliente,
                idPlan:idPlan!=''?idPlan:props.element.idPlan,
                interesActivado: interesActivado,
                cuotaVencida : cuotaVencida,
                interesPorCuotaVencida: interesPorCuotaVencida,
                vtoInicial: vtoInicial!=''?vtoInicial:fecha,
                idMecanico:esVentaBateria==1?idMecanico:null,
                entregaBateria:esVentaBateria==1?entregaBateria:null,
                esVentaBateria:esVentaBateria,
                valorCuota:valorCuota,
                valorCuotaInicial:valorCuotaInicial,
                detalleVenta:detalleVenta
              };
            console.log(item);
            
            let result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/ventas/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
              let resultJson = await result.json();
              let nuevaVenta = resultJson.data;
              console.log(nuevaVenta);
              if(result.status==200){
                    Swal.fire('Venta registrada', 'La venta ha sido registrada con éxito.', 'success');
                    
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                     document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
        }else{
            Swal.fire('Algo salio mal', 'Para cargar la venta, asegurese de completar todos los campos y agregar al menos un producto', 'warning');
        }
        
          
          
    }

      useEffect(()=>{
        console.log(props.element)
        //setear estados de form
        setFecha(props.element?new Date(props.element.fecha+'T00:00:00'):new Date());
        setPrecioFinal(props.element?props.element.precioFinal:'');
        setAnticipo(props.element?props.element.anticipo:0);
        setPrimerPago(props.element?props.element.primerPago:0);
        setMontoPagado(props.element?props.element.montoPagado:0);
        setSaldo(props.element?props.element.saldo:0);
        setIDCliente(props.element?props.element.idCliente:'');
        setIDPlan(props.element?props.element.idPlan:'');
        setIDMecanico(props.element?props.element.idMecanico:'');
        //setEntregaBateria(props.element?props.element.entregaBateria:0);
        //setEsVentaBateria(props.element&&props.element.esVentaBateria==1?1:0);
        setPrecioBase(props.element&&props.element.precioBase?props.element.precioBase:0);
        setInteres(props.element?props.element.plan.porcentaje:'')
        setTienePagoInicial(props.element?props.element.plan.tienePagoInicial:0)
        setValorCuotaInicial(props.element&&props.element.plan.tienePagoInicial==1?props.element.valorCuotaInicial:null)
        setValorCuota(props.element?props.element.valorCuota:'')
        setInteresActivado(props.element?props.element.interesActivado:1);
        setCuotaVencida(props.element?props.element.cuotaVencida:0);
        setInteresPorCuotaVencida(props.element?props.element.interesPorCuotaVencida:0);
        setVtoInicial(props.element?new Date(props.element.vtoInicial+'T00:00:00'):new Date());
        setPorcentajePorCuotaVencida(0);
        setDetalleVenta(props.element?props.element.detalles:[])
        setCantidadCuotas(props.element?props.element.plan.cantidadCuotas:'');
        console.log(props.element?props.element.cuotaVencida:'no hay registro');
        /*compruebo si es admin y si esta logueado*/
        /*
        if(!localStorage.getItem("user-info")){
           navigate("/login");
       }else*/
           apiCall(process.env.REACT_APP_API_BASE_URL+"/clientes/list/",updateClientes);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/productos/list/",updateProductos);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/planes/list/",updatePlanes);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/mecanicos/list/",updateMecanicos);
           apiCall(process.env.REACT_APP_API_BASE_URL+"/config/list/",updatePorcentajeBateriaUsada);
           //ocultar buscador
           //document.getElementById("search-container").style.visibility= 'hidden';

       
        }, [props.element, props.action]);


        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el producto seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
            if(props.element){
                console.log(props.element.fecha)
                setCuotaVencida(props.element.cuotaVencida)
                setFecha(new Date(props.element.fecha+'T00:00:00'));
                setVtoInicial(new Date(props.element.vtoInicial+'T00:00:00'));
                setInteresPorCuotaVencida(props.element.interesPorCuotaVencida);
                setIDPlan(props.element.idPlan)
            }else{
                setPresupuestoOK(false)
            }
            
           
            }, [props.action, props.element]);

        useEffect(()=>{
            
            if(fecha && detalleVenta.length>0  && idPlan){
                setItemParaPresupuesto({
                    idVenta:0,
                    fecha:fecha?fecha:new Date(),
                    valorCuotaInicial:valorCuotaInicial?valorCuotaInicial:valorCuota,
                    valorCuota:valorCuota,
                    anticipo:anticipo,
                    vtoInicial:vtoInicial
                })
                let dPresupuesto = [];
                let cPresupuesto = [];
                detalleVenta.forEach(d=>{
                    let prod = productos.find(p=>p.idProducto == d.idProducto);
                    dPresupuesto.push(prod)
                    cPresupuesto.push(d.cantidad)

                })
                setProductosParaPresupuesto(dPresupuesto)
                setCantidadesParaPresupuesto(cPresupuesto)
                let cli = clientes.find(c=>c.idCliente == idCliente);
                let pl = planes.find(p=>p.idPlan == idPlan);
                if(cli)
                setClienteParaPresupuesto(cli)
                setPlanParaPresupuesto(pl)
            }

        }, [fecha, valorCuotaInicial, valorCuota, idPlan, idCliente, detalleVenta, anticipo, vtoInicial])

        useEffect(()=>{
            
            if(itemParaPresupuesto&&productosParaPresupuesto.length>0&&planParaPresupuesto/*&&clienteParaPresupuesto*/){
                setPresupuestoOK(true)
            }else{
                setPresupuestoOK(false)
            }
                

        }, [itemParaPresupuesto])



        

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
                <div className="d-flex justify-content-between gap-3">
                    <div className="w-50">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Fecha</label>
                            <DatePicker className="form-control" name="fecha"
                                selected={fecha} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>{setFecha(d);validateDate(d,'fecha','invalid-feedback-datepicker')}}
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                locale="es"
                            />
                            <div className="invalid-feedback-datepicker">
                                Por favor ingrese la fecha.
                            </div>
                        </div>
                    </div>
                    <div className="w-50">
                        {props.action!="Registrar"?
                            <div className="d-flex py-2">
                                <label className="label">Id Venta</label>
                                <input className="form-control" name="idVenta" value={props.element?props.element.idVenta:''} disabled></input>
                            </div>
                        :''}
                    </div>
                </div>
                <div className="p-2 border rounded">
                    <div className="d-flex justify-content-between gap-3">
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Cliente</label>
                            {props.action=='Ver detalle'?
                            <input className="form-control" name="cliente" value={props.element?props.element.cliente.nombre:''} disabled></input>
                            :
                            <>
                            <select name="cliente" id="s-cliente" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idCliente:''} onInput={(e) => {setIDCliente(e.target.value);validateInput(e.target.value,'cliente')}} disabled={props.action=="Ver detalle"?true:false}>
                                <option value='' disabled={!props.element}>Seleccionar cliente</option>
                                {clientes?clientes.map((item,i)=>
                                    <option value={item.idCliente} key={'option'+i}>{item.nombre}</option>
                                ):''}
                            </select>
                            <div className="invalid-feedback">
                                Por favor seleccione un cliente.
                            </div>
                            </>
                            
                            }
                            
                        </div>
                        <div className="d-flex py-2 position-relative">
                            <label className="label">Plan</label>
                            {props.action=="Ver detalle"?
                            <input className="form-control" name="plan" value={props.element?props.element.plan.descripcion:''} disabled></input>
                            :
                            <>
                            <select name="plan" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idPlan:''} onInput={(e) => {setIDPlan(e.target.value);validateInput(e.target.value,'plan')}} disabled={props.action=="Ver detalle"?true:false}>
                                <option value='' disabled={!props.element}>Seleccionar plan</option>
                                {planes?planes.map((item,i)=>
                                    <option value={item.idPlan} key={'option'+i}>{item.descripcion}</option>
                                ):''}
                            </select>
                            <div className="invalid-feedback">
                                Por favor seleccione un plan.
                            </div>
                            </>
                            
                            }
                            
                        </div> 
                    </div>
                    <div className="d-flex justify-content-between align-items-center gap-3">
                        <div className="form-check form-switch pt-3 w-25">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCuotaVencida" defaultChecked={props.action=='Registrar'&&cuotaVencida==1?true:props.element&&props.element.cuotaVencida==1?true:false} onInput={(e) => setCuotaVencida(c=>c==1?0:1)} disabled={props.action!='Registrar'?true:false}/>
                            <label className="form-check-label" htmlFor="flexSwitchCuotaVencida">
                            Cuota vencida
                            </label>
                        </div>
                        {cuotaVencida==1?
                        <div className="d-flex py-2">
                            <label className="label">Fecha de vencimiento primer cuota:</label>
                            <DatePicker className="form-control"
                                selected={vtoInicial} disabled={props.action=="Ver detalle"?true:false}
                                onChange={(d)=>setVtoInicial(d)}
                                locale="es"
                                dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                            />
                        </div>:''}
                        {cuotaVencida==1&&props.action=="Registrar"?
                        <div className="d-flex py-2">
                            <label className="label">Porcentaje adicional:</label>
                            <div className="input-group">
                                <input className="form-control" name="porcentajePorCuotaVencida" defaultValue={porcentajePorCuotaVencida} onInput={(e) =>setPorcentajePorCuotaVencida(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                                <span class="input-group-text">%</span>
                            </div>
                        </div>:''}
                    </div>
                </div>
                <div className="p-2 border rounded">
                    {props.action=="Registrar"?
                    <div className="d-flex border-bottom justify-content-center gap-3">
                        <div className="d-flex py-2">
                            <label className="label">Agregar item: </label>
                            <select className="form-select" aria-label="Default select example" onInput={(e) => setIDProducto(e.target.value)} defaultValue={productos.length>0?productos[0].idProducto:null}>
                                <option value='' disabled={!props.element}>Seleccionar producto</option>
                                {productos?productos.map((item,i)=>
                                    <option value={item.idProducto} key={'option'+i}>{item.descripcion}</option>
                                ):''}
                            </select>
                        </div>
                        <div className="py-2 d-flex always-flex">
                            <button type="button" className="btn btn-outline-warning" onClick={(e)=>{document.getElementById('cantidad').value=cantidad>1?cantidad-1:1;setCantidad(c=>c>1?c-1:1)}}>-</button>
                            <input className="form-control" name="cantidad" id="cantidad" defaultValue={cantidad} onInput={(e) => setCantidad(e.target.value)}></input>
                            <button type="button" className="btn btn-outline-warning" onClick={(e)=>{document.getElementById('cantidad').value=cantidad+1;setCantidad(c=>c+1)}}>+</button>
                        </div>  
                        <div className="py-2">
                            <button type="button" className="btn btn-outline-success" onClick={(e) => agregarDetalleVenta()}>Agregar</button>
                        </div> 
                    </div>
                    :''}
                    {esVentaBateria==1&&props.action=='Registrar'?
                    <div className="d-flex justify-content-between gap-3">
                        <div className="d-flex py-2">
                            <label className="label">Mecánico</label>
                            {props.action=="Ver detalle"?
                            <input className="form-control" name="mecanico" value={props.element&&props.element.idMecanico?props.element.mecanico.descripcion:''} disabled></input>
                            :
                            <select className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idMecanico:''} onInput={(e) => setIDMecanico(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                                <option value='' disabled={!props.element}>Seleccionar mecánico</option>
                                {mecanicos?mecanicos.map((item,i)=>
                                    <option value={item.idMecanico} key={'option'+i}>{item.descripcion}</option>
                                ):''}
                            </select>
                            }
                            
                        </div>
                        <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" defaultChecked={entregaBateria==1?true:false} onInput={(e) => setEntregaBateria(c=>c==1?0:1)} disabled={props.action!='Registrar'?true:false}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">
                           Entrega Batería Usada
                        </label>
                        </div>
                    </div>
                    :''}
                    {detalleVenta.length>0?
                    <div className="border rounded mt-3 p-2">
                        <div className="d-flex border-bottom">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">ID producto</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Precio Unitario</th>
                                        <th scope="col">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detalleVenta.map((item,i)=>
                                        <tr key={"tr-"+i}>
                                            <th scope="row" key={"th-"+i}>{item.idProducto}</th>
                                            <td key={"d-"+i}>{item.producto.descripcion}</td>
                                            <td key={"c-"+i}>{item.cantidad}</td>
                                            <td key={"co-"+i}>$ {item.precio.toLocaleString()}</td>
                                            <td key={"s-"+i}>$ {item.subtotal.toLocaleString()}</td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    
                    </div>
                    :''}
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <div className="col-12 col-md-6 border rounded p-2">
                        <div className="d-flex py-2">
                            <label className="label">Precio Base</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="precioBase" disabled value={props.action=='Registrar'?precioBase:props.element&&props.element.precioBase?props.element.precioBase:0} ></input>
                            </div>
                        </div>
                        {/*
                        {entregaBateria==0&&props.action!='Registrar'&&esVentaBateria==1?
                        <div className="d-flex py-2">
                            <label className="label">Extra por no entregar bateria usada</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="extraBateriaUsada" disabled value={props.action!='Registrar'&&props.element&&props.element.producto.precioContado?props.element.producto.precioContado*porcentajeBateriaUsada/100:''} ></input>
                            </div>
                        </div>:''
                        }*/}
                        {cuotaVencida==1?
                        <div className="d-flex py-2">
                            <label className="label">Interés por cuota vencida</label>
                            <div className="input-group">
                            <span class="input-group-text">$</span>
                            <input className="form-control" name="interesPorCuotaVencida" value={props.action=='Registrar'?interesPorCuotaVencida:props.element?props.element.interesPorCuotaVencida:''}  disabled></input>
                            </div>
                        </div>:''}
                        <div className="d-flex py-2">
                            <label className="label">Interés por plan</label>
                            <div className="input-group">
                            <input className="form-control" name="interes" disabled value={props.action=='Registrar'?interes:props.element?props.element.plan.porcentaje:''} ></input>
                            <span class="input-group-text">%</span>
                            </div>
                        </div>
                        {props.action=='Registrar'&&bonificacionAcumulada>0?
                        <div className="d-flex py-2">
                            <label className="label">Bonificación acumulada</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="bonificacionAcumulada" disabled value={bonificacionAcumulada} ></input>
                            </div>
                        </div>:''}
                        <div className="d-flex py-2">
                            <label className="label">Anticipo</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="anticipo" defaultValue={props.action=='Registrar'?0:props.element?props.element.anticipo:0} onInput={(e) => setAnticipo(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                            </div>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">PFF</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="precioFinal" value={props.action=='Registrar'?precioFinal:props.element?props.element.precioFinal:''} disabled></input>
                            </div>
                        </div>
                        {props.action!='Ver detalle'&&tienePagoInicial==1?
                        <div className="d-flex py-2">
                            <label className="label">Pago Inicial Esperado</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="valorCuotaInicial" value={props.action=='Registrar'?valorCuotaInicial:props.element&&props.element.plan.tienePagoInicial==1?props.element.valorCuotaInicial:null} disabled></input>
                            </div>
                        </div>:''
                        }
                        
                        <div className="d-flex py-2">
                            <label className="label">Cantidad de cuotas</label>
                            <input className="form-control" name="cantidadCuotas" disabled value={props.action=='Registrar'?cantidadCuotas:props.element?props.element.plan.cantidadCuotas:''} ></input>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">Valor de cada cuota</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="valorCuota" disabled value={props.action=='Registrar'?valorCuota:props.element?props.element.valorCuota:''} ></input>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 border rounded p-2">
                        <div className="d-flex py-2">
                            <label className="label">Primer pago</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="primerPago" defaultValue={props.action=='Registrar'?0:props.element?props.element.primerPago:0} onInput={(e) =>setPrimerPago(e.target.value)} disabled={props.action=="Ver detalle"?true:false}></input>
                            </div>
                        </div>
                        <div className="d-flex py-2">
                            <label className="label">Monto Pagado</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="montoPagado" value={props.action=='Registrar'?montoPagado:props.element?props.element.montoPagado:''}  disabled></input>
                            </div>
                        </div>
                        {props.action!='Registrar'?
                        <div className="d-flex py-2">
                            <label className="label">Interes acumulado</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="montoPagado" value={props.element?props.element.interesAcumulado:''}  disabled></input>
                            </div>
                        </div>
                        :''}
                        <div className="d-flex py-2">
                            <label className="label">Saldo</label>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input className="form-control" name="saldo" value={props.action=='Registrar'?saldo:props.element?props.element.saldo:''} disabled></input>
                            </div>
                        </div>
                        <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={interesActivado==1?true:false} onInput={(e) => setInteresActivado(e.target.value==true?1:0)} disabled={props.action!='Registrar'?true:false}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                           Aplicar intereses automáticamente
                        </label>
                        </div>
                    </div>
                </div>
                
                
                {props.action=="Ver detalle"?
                <DetalleCuotas venta = {props.element}/>:''}
                {props.action=="Ver detalle"?
                <DetallePagosPorVenta venta = {props.element} setActualizar={props.setActualizar}/>:''}
                {props.element||presupuestoOK?
                <ImpresionComponent item={props.action!='Registrar'?props.element:itemParaPresupuesto} cliente={clienteParaPresupuesto} productos={productosParaPresupuesto} plan={planParaPresupuesto} cantidades={cantidadesParaPresupuesto} operacion={props.action=='Registrar'?'presupuesto':'venta'}/>
                :''}
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
            
        </div>
        
        </>
  );
}

export default DetalleVenta;