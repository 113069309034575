import '../stylesheets/App.css';
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faSearch,faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
//import PDFComponent from './PDFComponent';
import PDFContainer from './pdfs/PDFContainer';
import 'react-datepicker/dist/react-datepicker.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Swal from 'sweetalert2';
import GraficoCobros from './subcomponents/GraficoCobros';
import { getHeaders } from '../helpers/apiCallConfiguration';
import es from 'date-fns/locale/es';
registerLocale('es', es)
library.add(faPlus, faSearch, faPrint); // Agrega los iconos que deseas utilizar



function BalanceDeCobros() {
    let [list, setList] = useState([]);
    let [total, setTotal] = useState(0);

    let [fechaDesde, setFechaDesde] = useState(new Date());
    let [fechaHasta, setFechaHasta] = useState(new Date());

    let [mostrarPDF, setMostrarPDF] = useState(false);


    const imprimir = () => {
      setMostrarPDF(true);
    }

    const cargarDatos = async () => {
      let item = {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta
      }
      console.log(item);
      let result = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/reportes/balance-de-cobros",
        {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(item),
        }
      );
      let data = await result.json();

      setList(data.data);
      let tot = 0;
      for(let item of data.data){
        tot+=item.importeAbonado;
      }
      setTotal(Math.round(tot));
      setMostrarPDF(true);
      console.log(data.data)
    }


    return (
        <>
            <div className="container my-5 margin-mobile-5vw">

                <h1 className="general-subtitle text-center my-4">Balance de cobros</h1>
                <div className="col-12 m-auto border rounded border-dark-blue p-4">
                    <div className="border rounded p-3 mt-3">
                        <h5>Opciones de filtrado</h5>
                        <div className="d-flex flex-wrap">
                            <div className="d-flex py-2">
                                <label className="label">Desde</label>
                                <DatePicker className="form-control"
                                    selected={fechaDesde} 
                                    onChange={(d)=>setFechaDesde(d)}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                />
                            </div>
                            <div className="d-flex py-2">
                                <label className="label">Hasta</label>
                                <DatePicker className="form-control"
                                    selected={fechaHasta} 
                                    onChange={(d)=>setFechaHasta(d)}
                                    locale="es"
                                    dateFormat="dd/MM/yyyy" // Personaliza el formato de la fecha
                                />
                            </div>
                        </div>
                        <div className="row my-3">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <button className="btn rounded-pill btn-dark col-12" onClick={cargarDatos}>
                                <FontAwesomeIcon icon={faSearch} /> Buscar
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="table-responsive">
                    <table className="table mt-5">
                        <thead>
                            <tr>
                                <th scope="col">Fecha Pago</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">ID Venta</th>
                                <th scope="col">Importe cuota</th>
                                <th scope="col">Interés</th>
                                <th scope="col">Importe abonado</th>
                            </tr>
                        </thead>
                        
                            {list?list.map((item,i)=>
                            <tbody>
                                <td key={'fecha-'+i}>{item.fechaPago}</td>
                                <td key={'cliente-'+i}>{item.cliente}</td>
                                <td key={'idVenta-'+i}>{item.idVenta}</td>
                                <td key={'importecuota-'+i}>$ {item.importeCuota.toLocaleString()}</td>
                                <td key={'interes-'+i}>$ {item.interes.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                <td key={'importeabonado-'+i}>$ {item.importeAbonado.toLocaleString()}</td>
                            </tbody>
                                ):
                            <tbody></tbody>}
                        <tfoot>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Total cobrado:</th>
                                <th scope="col">$ {total.toLocaleString()}</th>
                        </tfoot>   

                        
                    </table>
                    </div>
                    {/*
                    {mostrarPDF?
                    <div id="graph-container">
                        <GraficoCobros list = {list} />   
                            </div>:''
                    }*/}
                    {mostrarPDF?
                    <div className="row my-3">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <button className="btn rounded-pill btn-dark col-12 text-white">
                                <FontAwesomeIcon icon={faPrint} /> <PDFContainer pdfType='balance-cobros' title={'Balance de cobros'} subtitle={'Periodo desde '+fechaDesde.toLocaleDateString()+' hasta '+fechaHasta.toLocaleDateString()} encabezados={['Fecha', 'Cliente', 'ID Venta', 'Importe cuota', 'Interés', 'Abonado']} list={list} footer={"Total: $"+total.toLocaleString()}/>
                            </button>
                        </div>
                    </div>
                    :''}
                    
                </div>
            </div>
        </>
    );
}

export default BalanceDeCobros;