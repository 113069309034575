import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { validateDate, validateInputNum, validateInputMoney } from '../../helpers/validators';
import { apiCall, getHeaders } from '../../helpers/apiCallConfiguration';

function DetalleMargen(props) {

    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [valorMinimo, setValorMinimo] = useState(props.action=='Registrar'?null:props.element&&props.element.valorMinimo?props.element.valorMinimo:null);
    let [valorMaximo, setValorMaximo] = useState(props.action=='Registrar'?null:props.element&&props.element.valorMaximo?props.element.valorMaximo:null);
    let [porcentaje, setPorcentaje] = useState(props.action=='Registrar'?null:props.element&&props.element.porcentaje?props.element.porcentaje:null);
    let [descuentoContado, setDescuentoContado] = useState(props.action=='Registrar'?null:props.element&&props.element.descuentoContado?props.element.descuentoContado:null);



    const saveChanges=async ()=>{
        //validar
        let valorMinimoV = validateInputMoney(valorMinimo, 'valorMinimo', 'margen-valorminimo-container')
        let valorMaximoV = validateInputMoney(valorMaximo, 'valorMaximo', 'margen-valormaximo-container')
        let porcentajeV = validateInputMoney(porcentaje, 'porcentaje', 'margen-porcentaje-container')
        let descuentoContadoV = validateInputMoney(descuentoContado, 'descuentoContado', 'margen-descuento-container')
        if(valorMinimoV && valorMaximoV && porcentajeV && descuentoContadoV){
          let item = {
            idMargen: props.element?props.element.idMargen:null,
            valorMinimo:valorMinimo?valorMinimo:props.element&&props.element.valorMinimo?props.element.valorMinimo:null,
            valorMaximo:valorMaximo?valorMaximo:props.element&&props.element.valorMaximo?props.element.valorMaximo:null,
            porcentaje:porcentaje?porcentaje:props.element&&props.element.porcentaje?props.element.porcentaje:null,
            descuentoContado:descuentoContado?descuentoContado:props.element&&props.element.descuentoContado?props.element.descuentoContado:null,
          };
          console.log(item);
          let result;
          if(props.action=='Registrar'){
              result = await fetch(
                  process.env.REACT_APP_API_BASE_URL + "/margenes/register",
                  {
                    method: "POST",
                    headers: getHeaders(),
                    body: JSON.stringify(item),
                  }
                );
          }else{
              
              result = await fetch(
                  process.env.REACT_APP_API_BASE_URL + "/margenes/update",
                  {
                    method: "PUT",
                    headers: getHeaders(),
                    body: JSON.stringify(item),
                  }
                );
          }
          
          result = await result.json();
          let mensaje = props.action=="Registrar"?"registrado":"modificado";
          if(result.status==200){
                Swal.fire('Margen '+mensaje, 'El Margen ha sido '+mensaje+' con éxito.', 'success');
                props.setActualizar((val)=>val+1);
                setComponentKey((prevKey) => prevKey + 1);
                 //ocultar popup
                document.querySelector('#exampleModal .btn-close').click();
          }else{
                Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
            }
          console.log(result);
        }
        
          
          
    }

      useEffect(()=>{
        //setear estados de form
        setValorMinimo(props.element?props.element.valorMinimo:null);
        setValorMaximo(props.element&&props.element.valorMaximo?props.element.valorMaximo:null);
        setPorcentaje(props.element&&props.element.porcentaje?props.element.porcentaje:null);
        setDescuentoContado(props.element&&props.element.descuentoContado?props.element.descuentoContado:null);

       
        }, [props.action, props.element]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el Rubro seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2 position-relative">
                    <label className="label">Id Margen</label>
                    <input className="form-control" name="idMargen" value={props.element?props.element.idMargen:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Valor Mínimo</label>
                    <div className="input-group position-relative" id="margen-valorminimo-container">
                    <span className="input-group-text">$</span>
                    <input className="form-control" name="valorMinimo" defaultValue={props.action=='Registrar'?null:props.element&&props.element.valorMinimo?props.element.valorMinimo:null} onInput={(e) => {setValorMinimo(e.target.value);validateInputMoney(e.target.value, 'valorMinimo', 'margen-valorminimo-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un valor.
                    </div>
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Valor Máximo</label>
                    <div className="input-group position-relative" id="margen-valormaximo-container">
                    <span className="input-group-text">$</span>
                    <input className="form-control" name="valorMaximo" defaultValue={props.action=='Registrar'?null:props.element&&props.element.valorMaximo?props.element.valorMaximo:null} onInput={(e) => {setValorMaximo(e.target.value);validateInputMoney(e.target.value, 'valorMaximo', 'margen-valormaximo-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un valor.
                    </div>
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Porcentaje</label>
                    <div className="input-group position-relative" id="margen-porcentaje-container">
                    <input className="form-control" name="porcentaje" defaultValue={props.action=='Registrar'?null:props.element?props.element.porcentaje:null} onInput={(e) => {setPorcentaje(e.target.value);validateInputMoney(e.target.value, 'porcentaje', 'margen-porcentaje-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <span className="input-group-text">%</span>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un valor.
                    </div>
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descuento Contado</label>
                    <div className="input-group position-relative" id="margen-descuento-container">
                    <input className="form-control" name="descuentoContado" defaultValue={props.action=='Registrar'?null:props.element?props.element.descuentoContado:null} onInput={(e) => {setDescuentoContado(e.target.value);validateInputMoney(e.target.value, 'descuentoContado', 'margen-descuento-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <span className="input-group-text">%</span>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un valor.
                    </div>
                    </div>
                </div>
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetalleMargen;