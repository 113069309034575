import '../../stylesheets/App.css';
//import '../../stylesheets/PopupRegister.css';
import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import DetallePago from '../popups/DetallePago';
import DetalleRefPago from '../popups/DetalleRefPago';

function DetallePagosPorVenta(props) {
    
    let [cargarNuevoPago, setCargarNuevoPago] = useState(false);
    return (
        <>
            <div className="accordion mt-3" id="accordionFlushPagos">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePagos" aria-expanded="false" aria-controls="flush-collapsePagos">
                        Ver pagos
                    </button>
                    </h2>
                    <div id="flush-collapsePagos" className="accordion-collapse collapse" data-bs-parent="#accordionFlushPagos">
                    <div className="accordion-body">
                    <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.venta.pagos?props.venta.pagos.map((item,i)=>
                            {
                                /*
                                let date = new Date(item.fecha)
                                let date2 = new Date(date.setDate(date.getDate() + 1));*/
                                console.log(item.fecha)
                                return <tr key={'tr-'+i}>
                                <th scope="row"  key={'d-'+i}>{item.idPago?item.idPago:item.idRefPago?item.idRefPago:''}</th>
                                <td  key={'f-'+i}>{new Date(item.fecha+'T00:00:00').toLocaleDateString()}</td>
                                <td  key={'dp-'+i}>{item.descripcion?item.descripcion:''}</td>
                                <td  key={'m-'+i}>$ {item.monto.toLocaleString()}</td>
                                </tr>
                            }
                            
                            ):''}
                        </tbody>
                        </table>
                        </div>
                        <button type="button" className="btn btn-outline-primary" onClick={()=>setCargarNuevoPago(true)}>Registrar nuevo pago</button>
                        {cargarNuevoPago?
                        <div className="border rounded mt-3 p-2">
                            {props.venta.idRefinanciamiento?
                            <DetalleRefPago action="Registrar" settedCliente={props.venta.idCliente} settedRefinanciamiento={props.venta.idRefinanciamiento} setActualizar={props.setActualizar}/>:
                            <DetallePago action="Registrar" settedCliente={props.venta.idCliente} settedVenta={props.venta.idVenta} setActualizar={props.setActualizar}/>}
                        </div>:''}
                    </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DetallePagosPorVenta;