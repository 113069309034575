import '../../stylesheets/App.css';
//import '../stylesheets/Error404.css';
import React, {useState, useEffect, useKey} from 'react';
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import { validateDate, validateInput, validateInputNum, validateInputMoney } from '../../helpers/validators';
import { apiCall,getHeaders } from '../../helpers/apiCallConfiguration';

function DetallePlan(props) {

    let [modalidades, setModalidades] = useState([]);
    const [componentKey, setComponentKey] = useState(0);

    //campos del form
    let [descripcion, setDescripcion] = useState(props.action=='Registrar'?'':props.element?props.element.descripcion:'');
    let [cantidadCuotas, setCantidadCuotas] = useState(props.action=='Registrar'?'':props.element?props.element.cantidadCuotas:'');
    let [porcentaje, setPorcentaje] = useState(props.action=='Registrar'?'':props.element?props.element.porcentaje:'');
    let [diasDeGracia, setDiasDeGracia] = useState(props.action=='Registrar'?'':props.element?props.element.diasDeGracia:'');
    let [tienePagoInicial, setTienePagoInicial] = useState(props.action=='Registrar'?0:props.element?props.element.tienePagoInicial:0);
    let [plazo, setPlazo] = useState(props.action=='Registrar'?'':props.element?props.element.plazo:'');
     let [idModalidad, setIDModalidad] = useState(props.action=='Registrar'?'':props.element?props.element.idModalidad:'');
     let [mostrarOpcionPagoInicial, setMostrarOpcionPagoInicial] = useState(props.action=='Registrar'?false:props.element&&(props.element.idModalidad==1||props.element.idModalidad==2)?true:false)


      const updateModalidades=(data)=>{
        console.log(data.data);
        setModalidades(data.data);

    }

    const saveChanges=async ()=>{
        //validar
        let descripcionV = validateInput(descripcion, 'descripcion')
        let cantidadCuotasV = validateInputNum(cantidadCuotas, 'cantidadCuotas')
        let porcentajeV = validateInputMoney(porcentaje, 'porcentaje', 'plan-porcentaje-container')
        let diasDeGraciaV = validateInputNum(diasDeGracia, 'diasDeGracia')
        let modalidadV = validateInput(idModalidad, 'modalidad')
        let plazoV = validateInput(plazo, 'plazo')
        if(descripcionV, cantidadCuotasV, porcentajeV, diasDeGraciaV, modalidadV, plazoV){
            let item = {
                idPlan: props.element?props.element.idPlan:null,
                descripcion:descripcion!=''?descripcion:props.element.descripcion,
                cantidadCuotas:cantidadCuotas!=''?cantidadCuotas:props.element.cantidadCuotas,
                porcentaje:porcentaje!=''?porcentaje:props.element.porcentaje,
                diasDeGracia:diasDeGracia!=''?diasDeGracia:props.element.diasDeGracia,
                tienePagoInicial:tienePagoInicial?tienePagoInicial:0,
                idModalidad:idModalidad!=''?idModalidad:props.element.idModalidad,
                plazo: plazo,
              };
            console.log(item);
            let result;
            if(props.action=='Registrar'){
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/planes/register",
                    {
                      method: "POST",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }else{
                
                result = await fetch(
                    process.env.REACT_APP_API_BASE_URL + "/planes/update",
                    {
                      method: "PUT",
                      headers: getHeaders(),
                      body: JSON.stringify(item),
                    }
                  );
            }
              
              result = await result.json();
              let mensaje = props.action=="Registrar"?"registrado":"modificado";
              if(result.status==200){
                    Swal.fire('Plan '+mensaje, 'El Plan ha sido '+mensaje+' con éxito.', 'success');
                    props.setActualizar((val)=>val+1);
                    setComponentKey((prevKey) => prevKey + 1);
                     //ocultar popup
                    document.querySelector('#exampleModal .btn-close').click();
              }else{
                    Swal.fire('Algo salio mal', 'Ha habido un error, intente nuevamente.', 'warning');
                }
              console.log(result);
        }
        
          
          
    }

      useEffect(()=>{
        //setear estados de form
        setDescripcion(props.element?props.element.descripcion:'');
        setCantidadCuotas(props.element?props.element.cantidadCuotas:'');
        setPorcentaje(props.element?props.element.porcentaje:'');
        setDiasDeGracia(props.element?props.element.diasDeGracia:'');
        setPlazo(props.element?props.element.plazo:'');
        setTienePagoInicial(props.element?props.element.tienePagoInicial:0);
        setIDModalidad(props.element?props.element.idModalidad:'');
        setMostrarOpcionPagoInicial(props.element&&(props.element.idModalidad==1||props.element.idModalidad==2)?true:false)

           apiCall(process.env.REACT_APP_API_BASE_URL+"/planes/modalidades/list/",updateModalidades);

       
        }, [props.action, props.element]);

        useEffect(()=>{
            if(idModalidad==3 || idModalidad==4){
                setTienePagoInicial(0);
                setMostrarOpcionPagoInicial(false);
            }else{
                setMostrarOpcionPagoInicial(true);
            }
    
           
            }, [idModalidad]);

        useEffect(()=>{
            //con esto lo que hago es forzar que se recree el componente cuando cambia el Plan seleccionado o la accion
            setComponentKey((prevKey) => prevKey + 1);
           
            }, [props.action, props.element]);

  //document.getElementById("html-ref").style.overflowY = "hidden";
   return (
    <>
        <div className="modal-body" key={componentKey}>
            <div className="p-3">
            {props.action!="Registrar"?
                <div className="d-flex py-2">
                    <label className="label">Id Plan</label>
                    <input className="form-control" name="idPlan" value={props.element?props.element.idPlan:''} disabled></input>
                </div>
            :''}
                <div className="d-flex py-2 position-relative">
                    <label className="label">Descripción</label>
                    <input className="form-control" name="descripcion" defaultValue={props.action=='Registrar'?'':props.element?props.element.descripcion:''} onInput={(e) => {setDescripcion(e.target.value);validateInput(e.target.value, 'descripcion')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese una descripción.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Cantidad de cuotas</label>
                    <input className="form-control" name="cantidadCuotas" defaultValue={props.action=='Registrar'?'':props.element?props.element.cantidadCuotas:''} onInput={(e) => {setCantidadCuotas(e.target.value);validateInputNum(e.target.value, 'cantidadCuotas')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese la cantidad de cuotas.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Porcentaje</label>
                    <div className="input-group position-relative" id="plan-porcentaje-container">
                    <input className="form-control" name="porcentaje" defaultValue={props.action=='Registrar'?'':props.element?props.element.porcentaje:''} onInput={(e) => {setPorcentaje(e.target.value);validateInputMoney(e.target.value, 'porcentaje', 'plan-porcentaje-container')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <span className="input-group-text">%</span>
                    <div className="invalid-feedback input-money-feedback">
                      Por favor ingrese un porcentaje.
                    </div>
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Modalidad</label>
                    <select name="modalidad" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?'':props.element?props.element.idModalidad:''} onInput={(e) => {setIDModalidad(e.target.value);validateInput(e.target.value, 'modalidad')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar modalidad</option>
                        {modalidades?modalidades.map((item,i)=>
                            <option value={item.idModalidad} key={'option'+i}>{item.descripcion}</option>
                        ):''}
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione una modalidad.
                    </div>
                </div>
                {mostrarOpcionPagoInicial?
                <div className="d-flex py-2">
                    <label className="label">Tiene pago inicial?</label>
                    <select name="tienePagoInicial" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?0:props.element?props.element.tienePagoInicial:0} onInput={(e) => setTienePagoInicial(e.target.value)} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar una opcion</option>
                        <option value='0'>No</option>
                        <option value='1'>Si</option>
                    </select>
                </div>:''
                }
                <div className="d-flex py-2 position-relative">
                    <label className="label">Plazo del plan</label>
                    <select name="plazo" className="form-select" aria-label="Default select example" defaultValue={props.action=='Registrar'?0:props.element?props.element.plazo:0} onInput={(e) => {setPlazo(e.target.value);validateInput(e.target.value, 'plazo')}} disabled={props.action=="Ver detalle"?true:false}>
                        <option value='' disabled={!props.element}>Seleccionar una opcion</option>
                        <option value='1'>1 mes</option>
                        <option value='2'>2 meses</option>
                        <option value='3'>3 meses</option>
                        <option value='4'>4 meses</option>
                        <option value='5'>5 meses</option>
                        <option value='6'>6 meses</option>
                    </select>
                    <div className="invalid-feedback">
                      Por favor seleccione un plazo.
                    </div>
                </div>
                <div className="d-flex py-2 position-relative">
                    <label className="label">Días de gracia</label>
                    <input className="form-control" name="diasDeGracia" defaultValue={props.action=='Registrar'?'':props.element?props.element.diasDeGracia:''} onInput={(e) => {setDiasDeGracia(e.target.value);validateInputNum(e.target.value, 'diasDeGracia')}} disabled={props.action=="Ver detalle"?true:false}></input>
                    <div className="invalid-feedback">
                      Por favor ingrese un valor.
                    </div>
                </div>
            </div>   
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            {props.action!="Ver detalle"?
            <button type="button" className="btn btn-primary" onClick={()=>saveChanges()}>Guardar</button>
            :''}
        </div>
        
      </>
  );
}

export default DetallePlan;